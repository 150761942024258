/*
Product item
**/
#list_item {
    max-width: 1822px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: clamp(1px, fs-vw(20, 1920), 20px);
}

.item,
.push {
    position: relative;
    max-width: 594px;
    width: 32.60%;
    box-sizing: border-box;
    margin-bottom: 35px;

    &:hover {

        .rollover,
        .productSizeFieldset:has(.size_list),
        .ill_img.imgLoaded:has(.rollover) {
            z-index: 1;
            opacity: 1;
        }

        .productSizeFieldset:has(.size_list) {
            opacity: 0.8;
        }

        .ill_img.imgLoaded:has(.rollover) {
            display: block;
            width: 100%;
            height: 0;
            padding-top: calc(891 / 594 * 100%);
        }

        .rollover_rayonsPrev {
            left: 9px;
            opacity: 1;
        }

        .rollover_rayonsNext {
            right: 9px;
            opacity: 1;
        }
    }

    .eclat1,
    .eclat2 {
        position: absolute;
        top: fs-vw(15, 1920);
        z-index: 2;

        img {
            width: auto !important;
            max-height: 25px;
        }
    }

    .eclat2 {
        left: fs-vw(15, 1920);
    }

    .eclat1 {
        right: fs-vw(15, 1920);
    }

    &:has(.swiper-initialized):hover {

        .rollover_rayonsPrev,
        .rollover_rayonsNext {
            opacity: 1;

            &.swiper-button-disabled {
                opacity: 0.5;
            }
        }
    }

    .push_wrapper_img,
    .swiper-lazy-preloader,
    .block_lnk:not(:has(.swiper-slide)),
    .block_lnk:has(.swiper-slide) .swiper-slide,
    .block_video {
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        padding-top: calc(891 / 594 * 100%);

        .imgPush,
        .imgProd,
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        iframe {
            pointer-events: none;
        }
    }

    .swiper-lazy-preloader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0;
        padding-top: calc(891 / 594 * 100%);
        margin-left: 0;
        margin-top: 0;
        z-index: 1;
        transform-origin: center;
        transform: none;
        animation: none;
    }

    .ill_img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 0;
        padding-top: calc(891 / 594 * 100%);
        opacity: 0;
        transition: opacity .3s ease-in-out;

        .imgProd {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            vertical-align: top;
        }

        &.imgLoaded {
            opacity: 1;

            &:has(.rollover) {
                opacity: 0;
            }
        }

        &:not(.imgLoaded) {
            background: none;
        }
    }

    .title_content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .item_title {
            @include fontStyle(14px, 26px, 700);
        }

        .item_subtitle {
            @include fontStyle(14px, 26px, 300);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .separation {
            @include fontStyle(14px, 26px, 300);
            padding: 0 5px;
        }
    }

    .title_colors_container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: fit-content;
        padding-right: 6px;

        @media screen and (max-width: 1024px) {
            width: 70%;
        }
    }

    .item_price {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;

        .pricetag {
            @include fontStyle(14px, normal, 500);

            +.pricetag {
                @include fontStyle(14px, normal, 500, #bbb);
            }
        }
    }

    .wrap_rolloverproduit {
        display: flex;
        justify-content: center;
        padding: 5px 0 0 10px;
    }

    .rollover_left {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
        width: 100%;
    }

    .titre_choices_list {
        @include fontStyle(14px, 1, 300);
        text-transform: uppercase;
        text-align: center;
        margin: 12px 0 15px;
    }

    .choices_list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        gap: 28px;
        width: fit-content;
        margin: 0 auto;
    }

    .productSizeFieldset:has(.size_list) {
        position: absolute;
        top: calc(100% - 133px);
        left: 0;
        display: flex;
        justify-content: center;
        height: 80px;
        width: 100%;
        opacity: 0;
        z-index: 0;
        background-color: $white;
        transition: all .3s ease-in-out;

        .form_itm.size input.disabled+label span {
            opacity: 0.5;
        }

        input[type=radio]:checked+label:before {
            display: none;
        }

        input[type=radio]:not(:checked)+label {
            margin: 0;

            &:before {
                display: none;
            }
        }
    }

    .bloc_add_color {
        display: none;
    }

    .btnAddBasketWrapper {
        position: absolute;
        left: 0;
        bottom: 53px;
        max-width: 594px;
        width: 100%;
        background-color: rgba($white, 0.8);
        z-index: 1;

        >*:not(.bloc_add_alert_form) {
            opacity: 0.8;
        }

        .bloc_add_alert_form {
            opacity: 1; // Ensure full opacity for this element
        }

        .alert_stock {
            @include fontStyle(14px, 26px, 300);
            text-align: center;
            text-transform: uppercase;
            margin: 6px auto;
        }

        .bloc_add_alert_form {
            max-width: 300px;
            width: 100%;
            margin: 0 auto 14px;

            span {
                @include fontStyle(11px, normal, 400, $black);
            }
        }

        .bloc_add_alert_confirmation p,
        .bloc_add_alert_erreur p {
            @include fontStyle(14px, 19px, 300);
            text-align: center;
            padding: 2%;
        }

        &.noSizeAvailable {
            padding: 6%;
            @include fontStyle(14px, 26px, 400);
            color: $black;
            text-align: center;
            text-transform: uppercase;
        }

        .w-submit-button {
            margin: fs-vw(10, 1920) auto;
        }

        .alert_return {
            position: absolute;
            top: 8px;
            right: 8px;
            cursor: pointer;

            @media screen and (max-width: 1024px) {
                top: 2px;
                right: 2px;
            }

            &::before {
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                @include bgIconPath("../svg/spritesheet.svg");
                @include bgIconCoord(7, 6, 6);
            }

            span {
                font-size: 0;
            }
        }

        .form_submit {
            position: absolute;
            top: 1px;
            right: 1px;
            height: 46px;
            border-radius: 5px;

            button {
                width: 56px;
                height: 100%;
                text-transform: uppercase;
                background-color: $white;
                border-radius: 0 5px 5px 0;
                cursor: pointer;
            }
        }

        .w-input-element {
            border-color: transparent;
            background-color: $white;
            border-radius: 5px;

            &:focus {
                border: 0.5px solid $grey;
            }

            &.inputErr {
                box-shadow: 0 0 0 2px $red;
            }

            &::placeholder {
                @include fontStyle(14px, 18px, 400, $grey);
            }
        }

        .w-form-line {
            background-color: $white;
            border-radius: 5px;
        }

        .w-input-container {
            height: 48px;
            border-radius: 5px;
        }
    }
}

.push_text {
    @include fontStyle(25px, 26px, 700, $white);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.productVisualMulti,
.linkImgWrapper {
    position: relative;
    display: block;
}

.rollover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .3s ease-in-out;
}

.rollover_rayonsPrev,
.rollover_rayonsNext {
    position: absolute;
    top: 44.2%;
    transform: translate(15%, -10%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    z-index: 3;
    transition: all .3s ease-in-out;

    &::before {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        @include maskIconPath('../svg/spritesheet.svg');
        @include maskIconCoord(6, $initialColSize, $initialRowSize);
        background-color: $black;
    }
}

.rollover_rayonsPrev {
    left: 0;
    opacity: 0;
    transform: rotate(180deg);
}

.rollover_rayonsNext {
    right: 0;
    opacity: 0;
}

.product_title_colors {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 7px;
}

.productColorFieldset {
    .right_element_prod {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .titre_choices_list {
        display: none;
    }

    .choices_list {
        gap: 12px;

        input[type=radio] {
            &+label {
                position: relative;
                width: 12px;
                height: 12px;
                border-radius: 50%;
            }

            &:not(:checked)+label {
                &.primary_color_1 {
                    border: 0.5px solid $grey;
                }
            }
        }

        input[type=radio]:checked+label {
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 16px;
                height: 16px;
                display: block;
                background-color: transparent;
                border: 1px solid $grey;
                border-radius: 50%;
                transition: all 0.3s ease-in-out;
            }
        }
    }
}

.productSizeFieldset,
.productColorFieldset {
    input[type=radio] {
        &+label {
            margin: 0;

            &::before,
            &::after {
                display: none;
            }
        }
    }
}