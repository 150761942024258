/* SATELLITE PAGES : SITEMAP, HISTOIRE, MARQUE, ENGAGEMENTS */

.page_satellite {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    font-family: $primaryFont;
    color: $black;

    .satellite_content.siteMap {
        display: flex;
        gap: 50px;
        justify-content: center;
    }

    .sitemap_title {
        font-size: $title1;
        font-weight: $bold;
        line-height: $lh_title1;
        text-transform: uppercase;
        text-align: center;
        margin: 5rem 0 3rem;
    }

    .sitemap_section {
        h3 {
            font-size: $title2;
            font-weight: $bold;
            line-height: $lh_title2;
            margin: 35px 0 10px;
            text-transform: uppercase;
        }

        h4 {
            font-size: $subtitle;
            font-weight: $bold;
            line-height: $lh_subtitle;
            margin: 15px 0 5px;
            text-transform: uppercase;
        }

        .plan_titre_objet {
            font-size: $normaltext;
            font-weight: $light;
            line-height: 30px;
        }
    }
    
    &.qui_sommes_nous {
        width: 100%;
        max-width: 1440px;
        margin: 2rem auto 0 auto;
    
        img {
            width: 100%;
            height: auto;
        }
    
        .ctn {
            display: flex;
            flex-wrap: wrap;
        }
    
        .text-align-right {
            text-align: right;
        }
    
        @media only screen and (max-width: 1280px) {
            .text-align-center-1280 {
                text-align: right;
            }
        }
    
        .text-align-left {
            text-align: left;
        }
    
        h1,
        h2 {
            font-family: $secondaryFont;
            font-weight: $bold;
            margin-bottom: 1.15rem;
            font-size: $title2;
            color: $txtcolor;
        }
    
        p {
            font-family: $primaryFont;
            font-weight: $light;
            font-size: $normaltext;
            color: $txtcolor;
            line-height: $lh_normaltext;
        }
    
        .histoire {
            margin-bottom: 4.7rem;
            position: relative;
    
            img {
                width: 73%;
                height: auto;
            }
    
            .img_ctn {
                text-align: right;
                width: 48.6%;
            }
    
            .txt_ctn {
                width: 37.5%;
                padding-left: 7.75vw;
                min-width: 370px;
                align-self: center;
    
                @media only screen and (max-width: 1366px) {
                    padding-right: 20px;
                }
    
                @media only screen and (max-width: 1280px) {
                    padding-left: 5vw;
                }
    
                @media only screen and (max-width: 1080px) {
                    padding-left: 4vw;
                }
            }
        }
    
        .propos_stella {
            padding-top: 40px;
            position: relative;
    
            .txt_ctn {
                width: 51.25%;
                align-self: center;
    
                @media only screen and (max-width: 1225px) {
                    margin-top: 8vh;
                }
    
                @media only screen and (max-width: 1220px) {
                    margin-top: 5vh;
                }
    
                @media only screen and (max-width: 1130px) {
                    margin-top: 2vh;
                }
    
                @media only screen and (max-width: 1080px) {
                    margin-top: 1.85vh;
                }
            }

            p,
            .h5_style,
            h2 {
                padding-left: 9.75vw;
                padding-right: 8vw;

                @media only screen and (max-width: 1225px) {
                    padding-right: 7.5vw;
                }

                @media only screen and (max-width: 1130px) {
                    padding-right: 7vw;
                }

                @media only screen and (max-width: 1080px) {
                    padding-right: 6vw;
                }
            }

            .h5_style {
                font-size: $subtitle;
                font-weight: $bold;
                margin-top: 1.5rem;

                @media only screen and (max-width: 1280px) {
                    margin-top: 1.2rem;
                }
            }

            p {
                margin-top: 8px;
            }
    
            .img_ctn {
                width: 48.75%;
            }

            img {
                width: 72.75%;
            }
        }
    
        .femme_suncoo {
            justify-content: flex-end;
            padding-top: 40px;
            margin-top: 7.9vh;
    
            .img_ctn {
                width: 45.325%;
            }

            img {
                width: 78.5%;
            }
    
            .txt_ctn {
                width: 41%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }

            p {
                padding-right: 10vw;

                @media only screen and (max-width: 1080px) {
                    padding-top: 0vh;
                }
            }
        }
    }

    &.nos_engagements {
        max-width: unset;
        font-family: $primaryFont;
        overflow-x: hidden;

        img {
            width: 100%;
            height: auto;
        }

        p .subtitle {
            font-weight: $bold;
            display: block;
            text-transform: uppercase;
        }

        .nos_engagements_top {
            background-color: $primaryColor;
            padding-top: $headerFull;
            padding-bottom: 4rem;
            position: relative;
            display: block;
            text-align: center;

            .title {
                display: block;
                text-align: center;
                font-size: $title1;
                margin-bottom: 2rem;
                line-height: $lh_title1;
                font-weight: $bold;
                margin-top: 4rem;
            }

            .subtitle {
                font-size: $subtitle;
                font-weight: $light;
            }
        }

        .nos_engagements_container {
            max-width: 100%;
            margin: auto;
            position: relative;
            padding-top: 5.9375rem;
            overflow-x: hidden;

            @media only screen and (max-width: 1180px) {
                padding-right: 20px;
                padding-left: 20px;
            }

            @media only screen and (max-width: 1024px) {
                padding-top: 9.75rem;
            }
        }

        .bloc_1 {
            width: 1060px;
            max-width: 100%;
            margin: auto;
            display: flex;
            align-items: center;

            .img_bloc {
                width: 49.905%;
                max-width: 529px;
                margin-right: 11.5%;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .txt1 {
            width: 33.9%;
            max-width: 22rem;
            text-align: left;

            p {
                font-size: $normaltext;
                margin-bottom: 1.5rem;
                font-weight: $light;
                line-height: $lh_normaltext;
            }
        }

        .bloc_2 {
            width: 100%;
            margin: auto;
            text-align: center;
            padding: 2.5rem 0;

            .txt_intro {
                text-transform: uppercase;
                font-size: $normaltext;
                font-weight: $bold;
                line-height: $lh_normaltext;
                margin-bottom: 25px;
            }

            .anchor_bloc {
                a {
                    text-decoration: none;
                    font-size: $normaltext;
                    font-weight: $bold;
                    display: block;
                    color: $grey;
                    margin-bottom: 0.75rem;
                    text-transform: uppercase;
                    display: inline-block;
                    padding-bottom: 2px;
                    border-bottom: 1px solid transparent;

                    &.active,
                    &:hover {
                        border-bottom: 1px solid $grey;
                    }
                }
            }
        }

        .anchor_bloc_contents {
            width: 100%;
            max-width: 1282px;
            margin: auto;

            .anchor_title {
                font-size: $title2;
                font-weight: $bold;
                text-align: center;
                margin-bottom: 2.25rem;
            }

            .anchor_txt {
                font-size: $normaltext;
                font-weight: $light;
                line-height: $lh_normaltext;
            }
        }

        .bloc_3_img_hover {
            display: flex;
            justify-content: space-evenly;
            width: 100%;

            .bloc_img {
                width: 100%;
                max-width: 350px;
                position: relative;

                @media only screen and (max-width: 1160px) {
                    max-width: 32.5%;
                }

                &:before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.47);
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                    opacity: 0;
                    transition: opacity 0.5s ease-in-out;
                }

                img {
                    width: 100%;
                    height: 100%;
                }

                &:hover {
                    &:before {
                        opacity: 1;
                    }

                    .bloc_title {
                        opacity: 0;
                        filter: blur(5px);
                    }

                    .bloc_hover {
                        opacity: 1;
                        filter: blur(0);
                    }
                }
            }

            .bloc_img_1 {
                .bloc_title {
                    height: 5.25rem;
                }

                .bloc_hover {
                    height: 21rem;
                }
            }

            .bloc_img_3 {
                .bloc_title {
                    height: 8.125rem;
                }

                .bloc_hover {
                    height: 16.25rem;
                }
            }

            .bloc_txt {
                display: block;
                position: absolute;
                color: $white;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                width: 84.473%;
                padding-left: 15.89%;
                text-align: left;
                z-index: 1;
            }

            .bloc_title {
                font-size: $title1;
                font-weight: $bold;
                line-height: 1;
                display: inline-block;
                width: auto;
                letter-spacing: 0.05em;
                filter: blur(0);
                transition: all 0.5s ease-in-out;
            }

            .bloc_hover {
                font-size: $normaltext;
                letter-spacing: 0;
                line-height: $lh_normaltext;
                opacity: 0;
                filter: blur(2px);
                transition: all 0.5s ease-in-out;

                a {
                    color: $white;
                }
            }
        }

        .anchor_link_1 {
            padding: 3.75rem 5.7% 4rem;
            background: $primaryColor;
            margin-bottom: 60px;

            .content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
            }

            .txt {
                font-size: $normaltext;
                line-height: $lh_normaltext;
                width: 27.93%;
                max-width: 290px;

                p {
                    margin-bottom: 1.15rem;
                }
            }

            .img_bloc {
                width: 598px;

                @media only screen and (max-width: 1040px) {
                    width: 70%;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .txt_2 {
                display: block;
                width: 100%;
                max-width: inherit;
                margin-right: 0;
                margin-top: 4.375rem;
                margin-bottom: 2rem;
                text-transform: uppercase;
                font-weight: $bold;
                text-align: center;
                letter-spacing: 0.2em;
                line-height: 1;
            }
        }



        .anchor_link_2 {
            max-width: 1100px;
            margin: 0 auto 5.625rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .bloc_txt {
                width: 44.54%;
                max-width: 490px;
                text-align: left;
            }

            .anchor_title {
                text-align: left;
            }

            p {
                margin-bottom: 0.75rem;
            }

            .bloc_img {
                width: 43.9%;
                max-width: 483px;
            }
        }

        .anchor_link_3 {
            max-width: 620px;
            margin-bottom: 3.875rem;

            .anchor_txt {
                text-align: center;
            }

            p {
                margin-bottom: 0.75rem;
            }

            .subtitle {
                margin-bottom: 0.5rem;
            }
        }

        .anchor_link_4 {
            max-width: 1140px;
            margin-bottom: 4.3rem;
            background: $primaryColor;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .bloc_img {
                width: 53.42%;
                max-width: 609px;
                margin-right: 7.894%;
            }

            .bloc_txt {
                width: 28.94%;
                max-width: 330px;
            }

            .anchor_title {
                text-align: left;
                margin-bottom: 1.5rem;
            }

            p {
                margin-bottom: 0.75rem;
            }
        }

        .anchor_link_5 {
            max-width: 100%;

            .anchor_txt {
                text-align: center;
            }

            p {
                margin-bottom: 0.75rem;
            }

            .subtitle {
                margin-bottom: 0.5rem;
            }

            .logo_un_rien_tout {
                width: 114px;
                display: block;
                margin: 2rem auto 2.5rem;
            }

            .bloc_x {
                margin: auto;
            }

            .bloc_1 {
                max-width: 670px;
                margin-bottom: 1rem;
            }

            .bloc_2 {
                max-width: 730px;
                padding: 0;
                margin-bottom: 40px;
            }

            .bloc_3 {
                max-width: 730px;
                margin-top: 2rem;
                margin-bottom: 5rem;
            }

            .bloc_4 {
                max-width: 920px;

                .bloc_4_title {
                    font-size: $title1;
                    font-weight: $bold;
                    margin-bottom: 2rem;
                }

                .img_bloc {
                    max-width: 150px;
                    margin: 2rem auto 3rem;
                }
            }

            .logo_groups_4 {
                margin-top: 2rem;
                margin-bottom: 2.5rem;
                width: 100%;
                display: flex;
                justify-content: space-around;
            }

            .logo_groups_img {
                display: block;
                min-width: 150px;

                img {
                    width: 80px;
                    height: auto;
                }

                span {
                    display: block;
                    text-align: center;
                    margin-top: 0.75rem;
                    font-size: $normaltext;
                    font-weight: $bold;
                    color: #d9003c;
                }
            }
        }

        .anchor_link_6 {
            max-width: 1140px;
            margin: 0 auto 3rem;
            background: $primaryColor;
            padding: 4.375rem;

            .bloc_6_title {
                font-size: $title1;
                font-weight: $bold;
                margin-bottom: 2rem;
                text-align: center;
            }

            .anchor_link_6_txt {
                text-align: center;
            }

            p {
                margin-bottom: 1rem;
            }

            .subtitle {
                display: inline-block;
                margin-bottom: 0.5rem;
                border-bottom: 1px solid #c1b9b7;
                line-height: 1;
            }
        }
    }
}

.satellite_breadcrumbs_wrapper {
    display: none;
}

.satellite_histoire {
    .page_wrapper {
        overflow-x: hidden;
    }
}

// PUBLICATIONS

.publication_page {
    width: 100%;
    margin: 0 auto;

    #mes_publications {
        width: 100%;
        max-width: 1062px;
        min-width: 990px;
        margin: 0 auto;
    }

    .wrapper_bandeau {
        margin-bottom: 4.65rem;

        h1{
            color: $txtcolor;
            font-family: $secondaryFont;
            text-align: center;
            font-weight: $bold;
            font-size: $title1;
            margin: 65px 0 20px;
        }

        .txt_bandeau {
            text-align: center;
            font-family: $primaryFont;
            color: $txtcolor;
            font-size: $normaltext;
            font-weight: $light;
            width: 100%;
            max-width: 620px;
            margin: auto;
            line-height: $lh_normaltext;
        }
    }

    .mes_publications.edito {
        padding: 0 16px;

        .wrapper_satellite_main {
            display: flex;
            justify-content: space-evenly;
            align-content: center;
            flex-wrap: wrap;

            a {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                text-decoration: none;
                width: 100%;
                &:nth-child(1), &:nth-child(4), &:nth-child(5), &:nth-child(7), &:nth-child(10),
                &:nth-child(11), &:nth-child(14), &:nth-child(15), &:nth-child(18), &:nth-child(19),
                &:nth-child(22),  &:nth-child(23), &:nth-child(26), &:nth-child(27), &:nth-child(30) {
                    max-width: 59%;
                }

                &:nth-child(2), &:nth-child(3), &:nth-child(6), &:nth-child(8), &:nth-child(9), &:nth-child(12),
                &:nth-child(13), &:nth-child(16), &:nth-child(17), &:nth-child(20), &:nth-child(21),
                &:nth-child(24), &:nth-child(25), &:nth-child(28), &:nth-child(29) {
                    max-width: 39%;
                }

                &:nth-child(odd) {
                margin-right: 1%;

                }

                &:nth-child(even) {
                 margin-left: 1%;

                }

                img {
                    display: block;
                    float: right;
                    margin-bottom: 2rem;
                    align-self: center;
                }
            }

            .txt_publication {
                margin: 0 0 2rem;

                h2.article_title {
                    color: $txtcolor;
                    font-family: $secondaryFont;
                    text-align: left;
                    font-weight: $bold;
                    font-size: $title2;
                    margin-bottom: 0.675rem;
                }

                p.article_subtitle {
                    font-family: $primaryFont;
                    color: $txtcolor;
                    font-size: $normaltext;
                    font-weight: $light;
                    line-height: $lh_normaltext;
                }
            }
        }
    }

    .categoryNav , .wrapper_menu_satellite, .article_categ, .txt_publication span {
        display: none;
    }
}

/* Page EDITO */
.publication_details {
    .publication_page {
        text-align: left;
        font-family: $primaryFont;
        color: $txtcolor;
    }

    .publication {
        .title {
            @include title40;
            margin: 4rem auto 2rem;
            text-align: center;
            width: 100%;
            max-width: 620px;
        }

        .subtitle {
            width: 100%;
            max-width: 620px;
            font-size: $subtitle;
            line-height: $lh_subtitle;
            margin: 0 auto 2rem auto;
            text-align: center;
        }

        .image img {
            display: block;
            height: 474px;
            max-width: 851px;
            margin: 4rem auto;
        }
        
        .txt_ctn {
            width: 100%;
            max-width: 620px;
            margin: 0 auto;

            div,
            p {
                font-size: $normaltext;
                font-weight: $light;
                line-height: $lh_normaltext;
            }

            strong {
                font-weight: $bold;
            }
        }

        a {
            text-decoration: underline;
        }
    }

    .social {
        margin: 18px 0 0;
        position: relative;
        text-align: center;
    }

    .sm_title {
        font-size: $smalltext;
        font-weight: $light;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 15px;
        margin-top: 4.69rem;
    }

    .social_media {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .social_btn {
        margin: 0 13px 0 0;
        cursor: pointer;

        span:after {
            content: '';
            display: block;
            width: 31px;
            height: 31px;
            @include bgIconPath('../svg/spritesheet.svg');
            background-repeat: no-repeat;
        }

        &.facebook span:after {
            @include bgIconCoord(1, $initialColSize, $initialRowSize);
        }

        &.instagram span:after {
            @include bgIconCoord(2, $initialColSize, $initialRowSize);
        }
        
        &:last-child {
            margin: 0;
        }
    }

    .link_publication_article {
        margin: 0 auto 6.75rem;
        max-width: 851px;

        h3 {
            font-size: $title1;
            margin-bottom: 10px;
            text-align: center;
            margin: 3.1rem 0 2rem;
            color: $txtcolor;
        }

        p {
            margin: 5px 0 10px;
            font-size: 1.25rem;
            text-align: center;
        }
    }

    .wrap_pics {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: center;
        margin: auto;

        a {
            width: 49%;
            max-width: 400px;
            text-decoration: none;
            color: $txtcolor;
            display: block;
            overflow: hidden;

            &:nth-child(odd) {
                margin-right: 1%;
            }

            &:nth-child(even) {
                margin-left: 1%;
            }

            &:only-child {
                margin-right: inherit;
                margin-left: inherit;
                margin: auto;
            }
        }

        img {
            display: block;
            float: right;
            margin-bottom: 2rem;
        }

        span {
            font-weight: $light;
            display: block;
            color: $txtcolor;
        }

        .title {
            font-size: $title2;
            font-weight: $bold;
            margin-top: 2.15rem;
            margin-bottom: 0.8rem;
        }

        .descrip {
            font-size: $normaltext;
            line-height: $lh_normaltext;
            font-weight: $light;
            margin-bottom: 20px;
        }
    }

    footer#copyright {
        border-top: 0;
        margin-top: 60px;
    }

    .wrap_reassurance{
        padding: 0 0 55px;
    }
}

/* TEXT PAGES : CGV, COOKIES, LEGAL NOTICE */

.wrapper_content_sat.page_text {
    width: 840px;
    max-width: 100%;
    margin: auto;
    font-family: $primaryFont;
    color: $black;

    /*---- Title ----*/
    h1.title {
        font-weight: $bold;
        font-size: $title1;
        line-height: $lh_title1;
        text-align: center;
        margin: 5rem 0 3rem;
    }

    /*---- Divider ----*/
    .divider {
        margin: 2rem 0;
        width: 100%;
        height: 0.1px;
    }

    /*---- Content ----*/
    .content {
        font-size: $normaltext;
        font-weight: $light;
        line-height: 2;
        margin-bottom: 2.5rem;

        h2 {
            font-size: $title2;
            line-height: $lh_title2;
            font-weight: $bold;
            margin: 0.8rem 0;
        }

        h3 {
            font-size: $subtitle;
            line-height: $lh_subtitle;
            font-weight: $bold;
            margin-bottom: 5px;
        }

        a {
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        ul {
            margin: 1rem 0 1rem 0.9rem;

            &+ul {
                margin-top: -1rem;
            }

            li {
                list-style: disc;

                &::marker {
                    font-size: $smalltext;
                }
            }
        }

        strong {
            font-weight: $bold;
        }

        img {
            margin: 15px 0;
        }
    }

    /*---- Table ----*/
    .wrapperCgvTable {
        margin: 0.7rem auto 2rem;
    }

    table.tarif_transport {
        background-color: rgba(0, 0, 0, 0.015);

        td {
            text-align: center;
            font-size: $normaltext;
            font-weight: $light;
            line-height: $lh_normaltext;
            vertical-align: middle;
            padding: 5px;
            border: 1px solid rgba(0, 0, 0, 0.045);
            height: 40px;
        }

        th {
            text-align: center;
            font-size: $normaltext;
            line-height: $lh_normaltext;
            padding: 10px 5px;
            border: 1px solid rgba(0, 0, 0, 0.05);

            // 3,5,7
            &:nth-child(2n + 3) {
                min-width: 70px;
            }

            // 2,4,6
            &:nth-child(2n + 2) {
                min-width: 160px;
            }
        }
    }
}

.wrapperCgvTable {
    display: none;
}
.order_cgv .wrapperCgvTable {
    display: block;
}