/**
/// Mixin for flex rows implementation, mostly used in forms
/// @param  $column [class used for each column]
/// @param  $gutter [desired gutter size]
/// Exemple :
.gridContaier {
    @include flexGrid('.gridItm', 8px);
}
*/
@mixin flexGrid($column, $gutter) {
    display: flex;
    align-items: stretch;

    @content;

    #{$column} {
        flex: 1;

        + #{$column} {
            margin-left: $gutter;
        }
    }
}

input[type='text'],
input[type='password'],
input[type='email'],
textarea,
select {
    width: 100%;
    height: 100%;
    font-size: .9rem;
    padding: 0 .5rem;
    font-family: $primaryFont;
}

input[type='text'],
input[type='password'],
input[type='email'],
textarea {
    border: 1px solid $fadeGreyline;

    &.inputErr {
        border-color: $red;
    }
}

button,
input,
label,
textarea {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
}

button,
input,
textarea {
    background-color: inherit;
    border-radius: 0;
    box-sizing: border-box;

    &:active,
    &:focus {
        outline: none;
    }
}

input,
select,
textarea {
    font-family: Arial, sans-serif;
}

textarea {
    padding: .5rem;
}

input:-webkit-autofill{
    -webkit-box-shadow: 0 0 0 1000px $white inset;
}

select::-ms-expand {
    display: none;
}
/******************************************************************************/
/* INPUT CHECKBOXES */
/******************************************************************************/
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;

    + label {
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        line-height: 1;

        &:before {
            content: '';
            display: block;
            width: 14px;
            height: 14px;
            border: 1px solid $fadeGreyline;
            background: $white;
            margin: 0 0.5rem 0 0;
            border-radius: 0;
        }

        &:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            background: $black;
            width: 12px;
            height: 12px;
        }
    }
}

#choix_recherche [type="checkbox"]:checked,
#choix_recherche [type="checkbox"]:not(:checked) {
    + label {
         &:before {
            display: none;
        }

        &:after {
            display: none;
        }
    }
}

[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
}

[type="checkbox"]:checked + label:after {
    opacity: 1;
    width: 12px;
    height: 12px;
}
/******************************************************************************/
/* INPUT RADIOS */
/******************************************************************************/
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;

    + label {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        line-height: 1;
        cursor: pointer;
        margin: 0 1rem 0 0;

        &:before {
            content: '';
            display: block;
            width: 14px;
            height: 14px;
            border: 1px solid #e4e4e4;
            background: $white;
            margin: 0 0.5rem 0 0;
            border-radius:50%;
        }

        &:after {
            content: '';
            position: absolute;
            top: 3px;
            left: 3px;
            background: $black;
            border-radius:50%;
        }
    }

    &.inputErr{
        + label {
            &:before {
                border: 1px solid $red;
            }
        }
    }
}

[type="radio"]:not(:checked) + label:after {
    opacity: 0;
}

[type="radio"]:checked + label:after {
    opacity: 1;
    width: 10px;
    height: 10px;
    background: $black;
}
/******************************************************************************/
/* SELECTS */
/******************************************************************************/
.date_select {
    position: relative;
    width: 80px;
    min-height: 3rem;
    display: inline-block;
    margin: 0 .4rem 0 0;

    select {
        appearance: none;
        border: 1px solid $fadeGreyline;
        font-family: $primaryFont;
        font-size: $normaltext;
        background: $white url(../img/compte-arrow.jpg) no-repeat 92% center;

        &::-ms-expand {
            display: none;
        }

        &.inputErr {
            border-color: $red;
        }

        
    }

    &.country {
        width: 100%;
    }
}
/******************************************************************************/
/* PHONE DROPDOWN */
/******************************************************************************/
.intl-tel-input {
    height: 100%;
}
/******************************************************************************/
/* INPUT PLACEHOLDERS */
/******************************************************************************/
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: sans-serif;
    font-size: 12px;
    color: #999999;
    font-style: italic;
}

::-moz-placeholder {
    /* Firefox 19+ */
    font-family: sans-serif;
    font-size: 12px;
    color: #999999;
    font-style: italic;
}

:-ms-input-placeholder {
    /* IE 10+ */
    font-family: sans-serif;
    font-size: 12px;
    color: #999999;
    font-style: italic;
}

:-moz-placeholder {
    /* Firefox 18- */
    font-family: sans-serif;
    font-size: 12px;
    color: #999999;
    font-style: italic;
}

#form_recherche {
    ::-webkit-input-placeholder {
        font-size: $input;
    }

    ::-moz-placeholder {
        font-size: $input;
    }

    :-ms-input-placeholder {
        font-size: $input;
    }

    :-moz-placeholder {
        font-size: $input;
    }
}

.img_error,
.valid_form {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
}

.checkbox_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 .5rem 0;

    label {
        font-size: $normaltext;
        color: $secondaryColor;
    }

    #adresseCivilite {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &.inputErr {
            label {
                color: $red;
            }
        }
    }
}

.formRow {
    margin: 0 0 .5rem 0;
    @include flexGrid('.input_container', 1%);

    &.deliveryDate {
        .input_container {
            flex: initial;
            width: 49.5%;
        }
    }

    .noaddr {
        display: none;
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
        color: $red;
        font-size: $input;
        font-weight: $regular;
        font-family: $primaryFont;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }
}

/******************************************************************************/
/* Captcha Google */
/******************************************************************************/
.g-recaptcha {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
}