.faq_section {
    width: 100%;
    max-width: 1440px;
    min-width: 990px;
    margin: 0 auto;
    color: $black;

    .faq_page_title {
        text-align: center;
        font-size: $title1;
        font-weight: $bold;
        margin: 80px 0 20px;
    }

    .title_detail_sub {
        text-align: center;
        font-size: $subtitle;
        font-weight: $light;
        margin-bottom: 50px;
    }

    .site_search {
        width: 40%;
        margin: 2rem auto;
        position: relative;

        .w-input-container:after {
            content: '';
            display: block;
            width: 19px;
            height: 19px;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(5, $initialColSize, $initialRowSize);
            background-color: $grey;
            background-repeat: no-repeat;
            position: absolute;
            right: 16px;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        .w-input-element {
            font-family: $primaryFont;
            font-size: $input;
        
            &::placeholder {
                font-size: $input;
                color: $grey;
                font-style: normal;
            }
        }
    
        .search_results {
            display: none;
            border: 1px solid $black;
            border-top: none;
            background: #fff;
            position: absolute;
            top: 95%;
            width: 100%;
            z-index: 2;
            padding: 0 1em;
            font-size: .7rem;
            letter-spacing: .07rem;
            transition: all 0.2s ease-out;
            max-height: 30rem;
            overflow-y: auto;
    
            li {
                min-height: 2.5rem;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-top: 1px solid #aaa;
                cursor: pointer;
    
                &:first-of-type {
                    border: none;
                }
            }
        }
    }


    .theme_global {
        display: none;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        max-width: 820px;
        margin: 0 auto 70px;
        &.actif {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .theme {
            cursor: pointer;
            display: flex;
            flex-wrap: wrap;
            width: 270px;
            height: 270px;
            text-align: center;
            background-color: #f3eae5;
            justify-content: center;
            background-size: 100% 100%;
            .content {
                padding-top: 65%;
                display: block;
                .intitule {
                    font-size: $subtitle;
                    font-weight: $light;
                    letter-spacing: 0.1em;
                    font-family: $primaryFont;
                    color: $txtcolor;
                    text-transform: uppercase;
                    padding-top: 18px;
                }
            }
        }
    }
    .theme_page {
        display: flex;
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.5s ease-in;
        width: 100%;
        max-width: 1060px;
        margin: auto;
        &.actif {
            height: auto;
            opacity: 1;
        }
        .theme_menu {
        margin: 40px 100px 0 20px;
        width: 220px;
            .theme {
                cursor: pointer;
                font-family: $primaryFont;
                font-size: $subtitle;
                line-height: $lh_subtitle;
                color: $grey;
                margin-bottom: 18px;
                text-transform: uppercase;

                &.active {
                color: $txtcolor;
                }
            }
        }
        .theme_detail .faq_title,
        .theme_detail .faq_question {
            cursor: pointer;
        }

        .theme_detail {
        width: 100%;
        max-width: 730px;
        margin: auto;
        margin-right: 20px;
        
            .faq_title {
                margin: 0;
                h2 {
                    font-size: $title2;
                    font-family: $secondaryFont;
                    font-weight: $bold;
                    color: $txtcolor;
                    padding: 30px 0;
                    border-bottom: 1px solid $fadeGreyline;
                    text-align: left;
                }
            }

            .faq_questions {
            border-bottom: 1px solid $fadeGreyline;

                h3 {
                    font-family: $primaryFont;
                    font-size: $title3;
                    font-weight: $bold;
                    padding: 20px 0;
                    border-top: 1px solid $fadeGreyline;
                    position: relative;

                    &::after,
                    &::before {
                        content: '';
                        display: block;
                        width: 10px;
                        height: 1px;
                        position: absolute;
                        background: $black;
                        font-size: 17px;
                        top: 50%;
                        right: 12px;
                        transition: opacity 0.3s ease-in-out;
                    }

                    &::before {
                        transform: rotate(90deg);
                    }

                    &.active::before {
                        opacity: 0;
                    }
                    
                    & + .faq_reponse {
                        display: none;
                    }

                    &.active + .faq_reponse {
                        display: block;
                    }
                }

                .faq_reponse {
                    font-family: $primaryFont;
                    font-size: $normaltext;
                    font-weight: $regular;
                    padding-bottom: 20px;
                    line-height: $lh_normaltext;

                    br {
                        line-height: $lh_title3;
                    }
                }
            }
        }


    }
}