#site_head_wrap {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    z-index: 7;
    background: transparent;
    transition: background-color 0.3s ease-in-out;

    &.sticky {
        height: unset;
        padding: 0 45px;

        @media screen and (max-width: 1300px) {
            padding: 0 20px;
        }

        .headerTop {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            @media screen and (max-width: 1300px) {
                gap: 10px;
            }
        }

        .block_logo {
            display: none;
            width: 0;
        }

        .sticky_logo {
            display: flex;
            width: auto;
            margin: unset;
            padding-right: 20px;
        }

        .block_top_item.switchLang {
            display: none;
        }

        .block_top_links.menu {

            .categ_link {
                padding: 27px fs-vw(24,1920);
                transition: all .3s ease-in-out, padding 0s;

                @media screen and (max-width: 1300px) {
                    padding: 27px fs-vw(18,1920);
                }
            }
        }

        .block_top_item.leftSide {
            position: initial;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .block_top_links.rightSide {
            position: initial;

            // Hover menu on sticky on small screens
            @media screen and (max-width: 1300px) {
                width: unset;
                gap: 10px;

            }

            // Hover menu on sticky on big screens
            @media screen and (min-width: 1920px) {
                width: unset;
                gap: 27px;
            }
        }

        .wrap_search_field {
            top: $headerHeightSticky;
        }

        .promo_bar {
            display: none;
        }

        #topLogin,
        #show_top_wish,
        #show_top_cart {
            top: $headerHeightSticky;

            @media screen and (min-width: 1920px) {
                &:after {
                    top: $headerHeightSticky;
                }
            }
        }

        #topLogin {
            max-height: calc(100vh - #{$headerHeightSticky});
        }

        #show_top_cart,
        #show_top_wish {
            #cart_list {
                max-height: calc(100vh - #{$headerFull} - 127px);
            }
        } 

        #cart_top:hover::before,
        .my_account.is_logged:hover::before {
            width: 50px;
            height: 45px;
        }
    }

    &.overflowing {
        height: 70px;

        #burgerMenu {
            display: block;
            position: relative;
            height: 30px;
            width: 30px;
            cursor: pointer;
            margin-right: 10px;
            z-index: 2;

            &.open {
                .burgerIcon {
                    opacity: 0;
                }

                .closeIcon {
                    opacity: 1;
                }
            }

            &> span {
                position: absolute;
                background-color: $black;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: opacity .3s ease-in-out;
                @include maskIconPath("../svg/mobile_webapp/vector/vector_spritesheet.svg");
            }

            .burgerIcon {
                @include maskIconCoord(1, 5, 5);
                opacity: 1;
            }

            .closeIcon {
                @include maskIconCoord(6, 5, 5);
            }
        }

        .block_logo {
            transition: opacity 0.3s ease-in-out;
            display: initial;
            width: auto;
            margin: auto;
            
            .logo {
                padding-top: 4px;
                padding-right: 17px;
            }

            h1 {
                padding: 0;
            }
        }

        .sticky_logo {
            display: none;
            width: 0;
        }

        .switchLang {
            transition: opacity 0.3s ease-in-out;
        }

        .rightSide {
            transition: opacity 0.3s ease-in-out;
        }

        .menu {
            position: absolute;
            left: -100%;
            width: auto;
            transition: none;
            opacity: 0;
            pointer-events: none;
        }

        &.stickyburger {
            .headerTop {
                justify-content: flex-start;
            }
            
            #burgerMenu {
                background: $white;

                &:after {
                    content: '';
                    background: $white;
                    position: absolute;
                    height: 100%;
                    width: 20px;
                    left: -20px;
                    pointer-events: none;
                }
            }

            .block_logo,
            .switchLang.sticky,
            .rightSide {
                opacity: 0;
                pointer-events: none;
            }

            .block_logo {
                width: 125px;
            }

            .menu {
                left: 50%;
                transform: translateX(-50%);
                transition: all 0.3s ease-in-out;
                opacity: 1;
                pointer-events: all;
            }
        }
        
    }
    

    &.sticky,
    &.search_open,
    &:hover {
        background-color: $white;

        .rightSide {

            .block_top_item.search .search_txt::before,
            .block_top_item.my_account .rolloverTxt::before,
            #cart_top .cart::before,
            #wishlist_top .cart::before {
                background-color: $black;
            }

            #cart_top .cart .nbProd {
                color: $black;
            }
        }

        .block_logo img {
            filter: invert(1);
        }

        .categ_name,
        .subcat_name {
            h2,
            h3 {
                color: var(--hovercolor, $black);
            }
        }

        .object_name {
            a {
                color: var(--hovercolor, $black);
            }
        }

        .block_top_item.switchLang span {
            color: $black;
            border: 1px solid $black;
        }
    }

    #burgerMenu {
        display: none;
        width: 0;

        & > span {
            opacity: 0;
        }
    }
}

.headerTop {
    position: relative;
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;

     @media screen and(min-width: 1920px) {
        padding: 0 45px;
     }

    // Language Switch
    .block_top_item.switchLang {

        &.sticky {
            display: none;
            
            .switchLangBtn {
                width: 31px;
                max-width: 31px;
                margin-right: 24px;
                justify-content: flex-start;

                .switchLangWrapper {
                    margin-left: 0;
                }

                .currentLang {
                    right: 7px;
                    left: unset;
                }

                &:hover {
                    padding-left: 0;
                    padding-right: 7px;

                    .currentLang {
                        right: -24px;
                        left: unset;
                    }
                }
            }
        }

        .switchLangBtn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            width: 24px;
            height: 24px;
            transition: all .3s ease-in-out;

            .currentLang {
                position: absolute;
                left: 0px;
            }

            .switchLangWrapper {
                position: relative;
            }

            &:hover {
                padding-left: 7px;
                width: 55px;

                .switchLangWrapper {
                    opacity: 1;
                    pointer-events: all;
                }

                .currentLang {
                    left: 0px;
                }
            }
        }

        .switchLangBtn .currentLang:hover,
        .switchLangWrapper:hover span {
            background-color: $black;
            color: $white !important;
            transition: all 0.3s ease-in-out;
        }

        .switchLangWrapper {
            transition: all 0.3s ease-in-out;
            opacity: 0;
            pointer-events: none;
            margin-left: 7px;
        }

        span {
            font-family: $primaryFont;
            font-size: 11px;
            font-style: normal;
            font-weight: 300;
            text-align: center;
            line-height: 0;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            color: $white;
            border: 1px solid $white;
            border-radius: 50%;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
        }
    }
}

.promo_bar {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: $preheaderHeight;

    .promo_swiper {
        height: 100%;
    }
}

.close_promo {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $preheaderHeight;
    padding-bottom: 4px;
    z-index: 2;
    cursor: pointer;
    color: $secondaryColor;
}

.promo_bar_content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    p {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $preheaderHeight;

        span {
            display: block;

            span {
                display: inline;
            }
        }
    }
}

.block_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto 0;
    transition: all 0.3s ease-in-out;

    @media screen and (max-width: 1024px) {
        margin: 33px auto 0;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h1 {
        margin: 0;
    }

    img {
        width: 125px;
        transition: filter 0.3s ease-in-out;
        // Fix chrome/firefox inconsistency in image size
        display: block;
        padding-bottom: 3px;
    }
}

body.pheader .wrap_search_field {
    top: $headerFull;

    @media screen and (max-width: 1024px) {
        top: calc(#{$headerFull} + 13px);
    }
}

.wrap_search_field {
    position: fixed;
    top: $headerHeight;
    left: 0;
    width: 100%;
    height: 120px;
    background-color: $white;

    @media screen and (max-width: 1024px) {
        top: calc(#{$headerHeight} + 13px);
    }

    .wrap_field {
        position: relative;
        max-width: 1440px;
        width: 94%;
        margin: 0 auto;

        &.searching {
            +#search_results {
                display: none;
            }
        }
    }

    .results_list {
        display: none;
    }

    #search_home {
        font-family: $primaryFont;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        color: $black;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 26px;
        padding-bottom: 9px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $black;

        &:focus {
            box-shadow: 0 0 0 0px transparent;
            outline: 0;
        }

        &::placeholder {
            font-family: $primaryFont;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            color: $black;
        }
    }

    .close_search {
        position: absolute;
        right: 0;
        top: 46%;
        transform: translate(0, -50%);
        cursor: pointer;

        &::before {
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            @include bgIconPath("../svg/spritesheet.svg");
            @include bgIconCoord(7, 6, 6);
        }
    }

    .search_results_number {
        position: absolute;
        right: 40px;
        top: 44%;
        transform: translate(0, -50%);
        font-size: 11px;
        color: $black;
    }
}

.block_top_links {
    display: flex;
    justify-content: center;
    align-items: center;

    &.rightSide {
        position: absolute;
        top: 23px;
        right: 45px;
        gap: 26px;
        justify-content: space-between;

        @media screen and (max-width: 1024px) {
            top: 32px;
            right: 30px;
            gap: 27px;
        }
    }

    &.menu .categ_link {
        padding: 23px fs-vw(24,1920) 32px;
        transition: all 0.3s ease-in-out, padding-top 0s, padding-bottom 0s;
        white-space: nowrap;

        @media screen and (max-width: 1024px) {
            padding: 23px fs-vw(20,1920) 32px;
        }

        @media screen and (min-width: 1025px) and (max-width: 1440px) {
            padding: 23px fs-vw(32,1920) 32px;
        }
    }
}

#cart_top,
#wishlist_top {
    position: relative;

    &:hover,
    &.temp_hover {
        // Expand hover zone so the rollover doesn't close on the way there
        &::before {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            width: 70px;
            height: 102px;
            opacity: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .cart {
            &::before {
                @include maskIconCoord(9, 6, 6);
            }

            &.wish_head::before {
                @include maskIconCoord(14, 6, 6);
            }

            .nbProd {
                color: $white !important;
            }
        }
    }
    
    .cart {
        position: relative;
        display: block;

        &::before {
            content: "";
            display: block;
            width: 24px;
            height: 22px;
            mask-image: url(../svg/spritesheet.svg);
            @include maskIconCoord(8, 6, 6);
            background-color: $white;
            transition: background-color 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
        }

        &.wish_head::before {
            @include maskIconCoord(13, 6, 6);
        }

        .nbProd {
            position: absolute;
            top: 50%;
            left: 50%;
            display: flex;
            transform: translate(-50%, -30%);
            @include fontStyle(10px, normal, 500, $white);
            transition: color 0.3s ease-in-out;
        }
    }
}

.block_top_item {

    // Search
    &.search {
        position: relative;
        left: -2px;

        .search_txt {
            &::before {
                content: "";
                display: block;
                width: 20px;
                height: 19px;
                mask-image: url(../svg/spritesheet.svg);
                @include maskIconCoord(5, 6, 6);
                background-color: $white;
                transition: background-color 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
                cursor: pointer;
            }

            &:hover::before {
                @include maskIconCoord(23, 6, 6);
            }
        }
    }

    // Account
    &.my_account {
        position: relative;

        &.is_logged:hover::before {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            width: 70px;
            height: 102px;
            opacity: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &:hover .rolloverTxt::before {
            @include maskIconCoord(11, 6, 6);
        }

        .rolloverTxt {
            &::before {
                content: "";
                display: block;
                width: 24px;
                height: 22px;
                mask-image: url(../svg/spritesheet.svg);
                @include maskIconCoord(10, 6, 6);
                background-color: $white;
                transition: background-color 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
            }
        }
    }
}

body.pheader #topLogin {
    max-height: calc(100vh - #{$headerFull});
    
}

#topLogin {
    max-height: calc(100vh - #{$headerHeight});

    .account_menu_title {
        @include title20;
        display: block;
        margin-bottom: 10px;
        line-height: 1;
    }

    .menu_link {
        @include text14;
        line-height: $lh_menu;
    }

    .btn_container {
        position: absolute;
        bottom: 25px;
        width: 250px;
        height: 39px;

        .button {
            max-width: 250px;
            width: 100%;
        }

        &.loading .loader {
            opacity: 1;
            z-index: 5;
        }
    }

    .wrapper_menu_compte {
        .menu_item_links {
            @include fontStyle(14px, 30px, 300, $black);
        }
    }
}

#show_top_cart,
#show_top_wish,
#topLogin {
    position: fixed;
    top: $headerHeight;
    left: 100%;
    bottom: 0;
    font-family: $primaryFont;
    width: 300px;
    height: 100%;
    padding: 30px 25px;
    background-color: $white;
    border-top: 1px solid $black;
    transition: all .5s ease-in-out;

    // For resolutions < 1024
    @media screen and (max-width: 1024px) {
        top: calc(#{$headerHeight} + 13px);
    }

    &.hover {
        left: calc(100% - 300px);
        transition: all 0.3s ease-in-out;
        z-index: 150;

        // For resolutions > 1920
        @media screen and(min-width: 1920px) {
            // initial position - rest of total available space
            left: calc((100% - 300px) - calc((100% - 1920px) / 2));

            &:after {
                content: '';
                position: fixed;
                top: $headerHeight;
                right: 0;
                height: 100%;
                width: calc((100vw - 1920px) / 2);
                background: $white;
                border-top: 1px solid $black;
            }
        }
    }

}

#show_top_wish {
    padding: 30px 5px 30px 25px;
}

body.pheader {
    #show_top_cart,
    #show_top_wish,
    #topLogin {
        top: $headerFull;

        @media screen and (max-width: 1024px) {
            top: calc(#{$headerFull} + 13px);
        }

        @media screen and (min-width: 1920px) {
            &:after {
                top: $headerFull;
            }
        }

        #cart_list {
            max-height: calc((100vh - #{$headerFull}) - 210px);
        }
    }
}

#show_top_cart,
#show_top_wish {
    .rollover_title {
        position: relative;
        top: -5px;
    }

    .title {
        @include fontStyle(20px, 26px, 700);
        display: block;
        margin-bottom: 10px;
    }

    .no_product {
        @include fontStyle(14px, 26px, 300);
    }

    #cart_list {
        width: calc(100% + 17px);
        height: 100%;
        max-height: calc((100vh - #{$headerHeight}) - 210px);
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 17px;
        box-sizing: content-box;
        scrollbar-color: $greyBorder transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 6px;
            background-color: transparent;
        }

        .product-link {
            position: relative;
            display: flex;
            margin-bottom: 20px;

            &:has(.report.error) {
                .cart_thumb_holder,
                .cart_detail_box .cart_product a,
                .cart_detail_box .cart_product .sub_quantity,
                .cart_detail_box .cart_product .sub_couleur,
                .cart_detail_box .cart_product .sub_taille, {
                    opacity: 0.5;
                }

                .no_stock {
                    position: relative;
                    display: inline-flex;
                    align-items: center;
                    font-family: $primaryFont;
                    font-size: 12px;
                    padding-bottom: 2px;
                    letter-spacing: -0.6px;
                    text-transform: uppercase;
                }
            }
        }

        img {
            width: 100%;
            height: auto;
        }

        .cart_thumb_holder {
            display: block;
            max-width: 80px;
            margin-right: 20px;
        }

        .cart_detail_box {
            width: 60%;

            .title {
                @include fontStyle(14px, 26px);
                margin-bottom: 0;
                margin-right: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .sub_quantity,
            .sub_taille,
            .sub_couleur {
                @include fontStyle(11px, 18px, 300);
                text-transform: uppercase;
            }

            .sub_couleur {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                label {
                    text-transform: initial;
                }
            }

            #topBasketToWish {
                display: none;
            }

            .remove_prod,
            .remove_top_wishlist {
                position: relative;
                top: 1px;
                display: block;
                width: 15px;
                height: 15px;
                cursor: pointer;
                z-index: 10;

                &::before {
                    content: "";
                    display: block;
                    width: 15px;
                    height: 15px;
                    @include bgIconPath("../svg/spritesheet.svg");
                    @include bgIconCoord(7, 6, 6);
                }

                span {
                    font-size: 0;
                }

            }

            .remove_top_wishlist {
                position: absolute;
                right: 30px;
            }

            .topWishTobasket {
                border-bottom: 1px solid $black;
                font-size: $normaltext;
                padding-bottom: 3px;
                text-transform: uppercase;
                width: fit-content;
                cursor: pointer;
            }

            .pricetag {
                font-size: 14px;
            }

            .product_wishlist {
                .btnAlertStock {
                    position: relative;
                    display: inline-flex;
                    align-items: center;
                    height: inherit;
                    min-height: inherit;
                    font-family: $primaryFont;
                    font-size: 12px;
                    line-height: 1;
                    color: $black;
                    background: transparent;
                    padding: 0 0 2px 0;
                    letter-spacing: -0.6px;
                    text-transform: uppercase;
                    border: none;
                    border-bottom: 1px solid $black;
                }
            }
        }

        .wrapper_title_price {
            display: flex;
            align-items: center;
            justify-content: stretch;

            +.color {
                display: none;
            }
        }

        .report.error {
            position: relative;
        }

        .btnAddBasketWrapper.expressAlertStock {
            width: 100%;
            padding-right: 30px;
            margin-top: 10px;

            .bloc_add_alert_form {
                position: relative;

                .w-form-line {
                    position: relative;
                    width: 100%;
                    max-width: 256px;

                    .w-input-container {
                        input[type=text].w-input-element {
                            width: 100%;
                            height: 48px;
                            border-radius: 5px;
                            padding-right: 48px;

                            &.inputErr {
                                border-color: $borderError;
                            }

                            &::placeholder {
                                font-family: $primaryFont;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 1;
                                color: $grey;
                            }
                        }
                    }

                    .form_submit {
                        position: absolute;
                        top: 2px;
                        right: 2px;
                        height: 44px;
                        width: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: $white;

                        button {
                            display: block;
                            width: 100%;
                            height: 100%;
                            cursor: pointer;
                        }

                        span {
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: uppercase;
                        }
                    }
                }
            }

            .alert_return {
                visibility: hidden;
                position: absolute;
                right: 12px;
                top: 15px;
                display: block;
                width: 15px;
                height: 15px;
                font-size: 0;
                @include bgIconPath('../svg/spritesheet.svg');
                @include bgIconCoord(7, 6, 6);
                background-color: $white;
                z-index: 5;
                opacity: 0.7;
            }

            .bloc_add_alert_confirmation {
                font-family: $primaryFont;
                font-size: 11px;
                width: 100%;
                padding-right: 10px;
                white-space: initial;

                p {
                    text-transform: none;
                }
            }

            & + .validEmailTxt {
                font-family: $primaryFont;
                font-size: 11px;
                text-transform: initial;
                color: $borderError;
            }

            
            .alert_return {
                visibility: visible;
                display: block !important;
            }

            &:before {
                content: '';
                position: absolute;
                left: -20px;
                width: 20px;
                height: 48px;
            }
        }
    }

    .wrap_tot_panier {
        .form_submit,
        a {
            max-width: 250px;
            width: 100%;

            .button,
            .loader {
                width: 100%;
            }
        }

        a.button:not(.primary) {
            background-color: $white;

            span {
                color: $black;
            }

            &:hover {
                background-color: $black;

                span {
                    color: $white;
                }
            }
        }

        .cart_panier_total {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding-right: 20px;
            margin-bottom: 15px;

            .titre_tot {
                @include fontStyle(14px, 17px, 300);
                text-transform: uppercase;
            }

            .pricetag {
                @include fontStyle(15px, unset, 500);
            }
        }

        .form_submit {
            margin-bottom: 20px;
        }

        .upselling {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;

            p {
                @include fontStyle(11px, 18px, 300);
                margin-bottom: 10px;
            }

            progress {
                -webkit-appearance: none;
                width: 100%;
                height: 5px;
                margin-bottom: 5px;
                border-radius: 10px;
                overflow: hidden;
            }

            progress::-webkit-progress-bar {
                background-color: $upsellBgColor;
            }

            progress::-webkit-progress-value {
                background-color: $upsellColor;
            }
        }
    }
}

#show_top_cart .bask_action {
    position: absolute;
    top: -4px;
    right: 15px;
}

#show_top_wish .cart_product {
    display: flex;
    flex-direction: column;
    height: 100%;

    .bask_action {
        margin-top: auto;
    }
}

#wishlist_top .cart.wish_head .nbProd {
    display: none;
}