/**
 * Calculate font size value in vw based on the px value on the mock up
 *
 * $fSize => font-size in px used on PSD or Figma
 * $viewport => Viewport width used on PSD or Figma mock-up
 */
@function fs-vw($fSize, $viewport) {

    @return ($fSize / $viewport) * 100vw;
}

/**
 * Exemple :
 *
 * font-size: fs-vw(16,640);
 */