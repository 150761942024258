/*
GLOBAL STYLES 
**/
body {
    font-family: $primaryFont;
    padding-top: 0;

    &:not(.homepage) {
        padding-top: $headerHeight;
    }

    //Preheader visible
    &.pheader {
        &.homepage {
            padding-top: $preheaderHeight;

            &:not(.search):has(.dynasearchwrapper) {
                padding-top: $headerFull;
            }

            &.search {
                padding-top: calc((#{$headerFull} + 80px));
            }
        }

        &.category {
            @media screen and (max-width: 1024px) {
                padding-top: calc(#{$headerFull} + 10px);
            }

            &:not(.search):has(.no_results_txt_container) {
                padding-top: calc(#{$headerFull} + 35px);
            }
        }

        &.search {
            &:not(.homepage) {
                padding-top: calc(#{$headerFull} + 66px);
                transition: all 0.3s ease-in-out;
            }

            #site_head_wrap:not(.sticky) {
                #show_top_cart,
                #show_top_wish,
                #topLogin {
                    top: $headerFull;
                }
            }
        }

        &:not(.homepage):not(.in_tunnel) {
            padding-top: $headerFull;
        }
    }

    &.in_tunnel {
        padding-top: 55px;

        #site_head_wrap {
            display: none;
        }

        footer {
            margin: 43px auto 0;
        }
    }

    &.homepage {
        padding-top: 0;
        opacity: 0;
        transition: opacity 0.3s ease-in;

        &:not(.search) {
            .no_results_txt_container {
                margin: fs-vw(200, 1920) auto 50px;
            }

            &:has(.dynasearchwrapper) {
                padding-top: $headerHeight;

                #trigger_filtre {
                    margin: 10px auto 30px;
                }

                .no_results_txt_container {
                    margin: 20px auto 50px;
                }
            }
        }

        &.search {
            padding-top: calc((#{$headerHeight} + 80px));

            .dynasearchwrapper .no_results_txt_container {
                margin: 60px auto 50px;
            }

            #list_item {
                margin: 0 auto 50px;
            }

            #trigger_filtre {
                margin: 70px auto 30px;
            }
        }
    }

    &.category {
        @media screen and (max-width: 1024px) {
            padding-top: calc(#{$headerHeight} + 10px);
        }

        &:not(.search) {
            &:has(.no_results_txt_container) {
                padding-top: calc(#{$headerHeight} + 35px);
            }
        }

        &.search {
            .dynasearchwrapper .no_results_txt_container {
                margin: 60px auto 50px;
            }

            #trigger_filtre {
                margin: 70px auto 30px;
                transition: all 0.3s ease;
            }

            .wrapper_bandeau.noImg {
                margin: 45px auto 0;
            }
        }
    }

    &.search {
        &:not(.homepage) {
            padding-top: calc(#{$headerHeight} + 66px);
            transition: all 0.3s ease-in-out;
        }

        #site_head_wrap {
            &.sticky {
                &.overflowing .headerTop .block_logo:not(.sticky_logo) {
                    display: flex;
                }

                .wrap_search_field {
                    top: $headerHeightSticky;
                }

                #show_top_cart,
                #show_top_wish,
                #topLogin {
                    top: $headerHeightSticky;
                }
            }

            &:not(.sticky) {
                background-color: $white;

                .headerTop .block_logo img {
                    filter: invert(1);
                }

                #show_top_cart,
                #show_top_wish,
                #topLogin {
                    top: $headerHeight;
                }
            }

            #show_top_cart,
            #show_top_wish,
            #topLogin {
                border-top: none;
            }
        }
    }

    // Pages with white background need background and text color to be set
    &.product_page,
    &.homepage:has(.dynasearchwrapper),
    &.category,
    &.faq,
    &.misc_cookiesinfo,
    &.store_locator,
    &.contact_faq,
    &.cart,
    &.order_cgv,
    &.nos_engagements,
    &.body_login,
    &.customer,
    &.wishlist,
    &.order,
    &.newsletter,
    &.satellite_histoire,
    &.publication_index,
    &.publication_details,
    &.footer_legalmentions,
    &.footer_sitemap,
    &.satellite_store_details {

        #site_head_wrap {
            background: $white;

            #cart_top .cart:hover .nbProd {
                color: $white !important;
            }

            .leftPart {
                .categ_name h2 {
                    color: $black !important;
                }
            }

            .block_logo img {
                filter: invert(1) !important;
            }

            .headerTop .block_top_links.rightSide {
                .block_top_item {

                    &.search .search_txt::before,
                    &.my_account .rolloverTxt::before {
                        background-color: $black;
                    }
                }
            }

            .headerTop .block_top_item.switchLang span {
                color: $black;
                border: 1px solid $black;
            }

            .headerTop .block_top_links.rightSide {
                #cart_top,
                #wishlist_top {
                    .cart {
                        .nbProd {
                            color: $black;
                        }
    
                        &::before {
                            background-color: $black;
                        }
                    }
                }
            }

            .headerTop .block_top_item.switchLang {

                .switchLangBtn .currentLang:hover,
                .switchLangWrapper:hover span {
                    background-color: $black;
                    color: $white;
                }
            }
        }
    }

    &.nos_engagements {
        //Padding is applied on .nos_engagements_top instead, to maintain background colour during scroll
        padding-top: 0 !important;

        #site_head_wrap {
            background-color: $primaryColor;
        }
    }

    // Hover effect on top links icons in header when connected as customer or in cart page
    &.customer {
        .block_top_item.my_account .rolloverTxt::before {
            @include maskIconCoord(11, 6, 6);
        }
    }

    &.cart {
        #cart_top .cart {
            &::before {
                @include maskIconCoord(9, 6, 6);
            }

            .nbProd {
                color: $white !important;
            }
        }
    }

    &.wishlist {
        #wishlist_top .cart {
            &::before {
                @include maskIconCoord(14, 6, 6);
            }
        }
    }

    /*
    Breadcrumbs
    **/
    .breadcrumbs {
        width: 98%;
        max-width: 1822px;
        margin: 4px auto 0;

        .breadcrumb {
            @include fontStyle(11px, 18px, 300);
            display: inline-block;

            a {
                text-decoration: none;
                color: $black;

                &::after {
                    content: '-';
                    padding-left: 3px;
                }
            }

            &.current {
                font-weight: 600;

                a::after {
                    display: none !important;
                }
            }

            &:last-of-type {

                a,
                span {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

}

.cache,
[v-cloak] {
    display: none;
}

/* 
SHADE 
**/
.shad {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .2);
    overflow-y: auto;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s linear;

    &.actif {
        opacity: 1;
        pointer-events: all;
        cursor: pointer;
    }
}

#shad_menu {
    z-index: 2;

    &.sticky {
        margin-top: 0;
    }

    &.actif {
        opacity: 1;
        transition: opacity .3s linear 0.2s, z-index 0s linear;
    }
}

#shad_abox {
    opacity: 0;
}

// Swipers
.rollover_rayonsNext.swiper-button-disabled.swiper-button-lock,
.rollover_rayonsPrev.swiper-button-disabled.swiper-button-lock {
    display: none !important;
}

a,
.link,
.btn .button {
    cursor: pointer;
}

// Hide native scrollbar to avoid content jumping on page load
body::-webkit-scrollbar {
    display: none;
}

.body {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

// Social module on pages other than home
body.contact_faq .social-module {
    margin: 0 auto 113px;
}

body.satellite_histoire .social-module {
    margin-top: 80px;
}

body.publication_details .social-module {
    margin-top: -40px;
    margin-bottom: 80px;
}

body.publication_index .social-module {
    margin-top: 40px;
    margin-bottom: 80px;
}

#cookiesLightbox ul.cookiesInfos {
    #cookiePref02,
    #cookiePref03 {
        .icon {
            display: inline-block;
        }
    }
}