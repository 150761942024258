/* MON COMPTE */

// Global styles
.wrapper_moncompte {
    margin: 2rem auto 0;
    padding: 0 10px;
    width: 100%;
    max-width: 1440px;
    min-width: 990px;
    box-sizing: border-box;


    @media only screen and (max-width: 1025px) {
        padding: 0 20px;
    }

    .flexAccount {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    }

    // Account menu
    .wrapper_menu_compte {
        font-family: $primaryFont;
        font-size: $subtitle;
        text-transform: uppercase;
        max-width: 220px;
        width: 100%;

        &>div {
            padding-bottom: 1rem;

            a {
                color: $grey;
                text-decoration: none;
                font-weight: $light;
                letter-spacing: 1.5px
            }

            &.actif a {
                color: $black;
                font-weight: $bold;
            }
        }
    }
}

// Account wrapper & form styles
.wrapper_moncompte_main {
    width: 100%;
    margin-right: 220px;

    @media only screen and (max-width: 1300px) {
        margin-right: 110px;
    }

    @media only screen and (max-width: 1025px) {
        margin-right: 0;
    }

    .account_header {
        font-family: $secondaryFont;
        font-size: $title2;
        font-weight: $bold;
        color: $black;
        padding: 0 0 30px;
    }

    p:not(.w-input-label) {
        font-family: $primaryFont;
        font-size: $normaltext;
        color: $black;
        font-weight: $light;
    }

    .form_submit.light_underline {
        height: 15px;
        margin-top: 6px;
        margin-bottom: 6px;

        @include queriesWshop (1150px, min) {
            padding-left: 20px;
        }

        .light_underline_btn {
            background-color: transparent;
            border: none;
            border-bottom: 1px solid $grey;
            padding: 0 0 3px 0;
            min-height: 15px;

            span {
                color: $txtcolor;
                font-weight: $medium;
                font-family: $primaryFont;
                font-size: 13px;
                letter-spacing: 0px;
                white-space: nowrap;
            }
        }
    }

    .info_champs {
        font-family: $primaryFont;
        font-size: $smalltext;
        font-weight: $regular;
        color: $grey;
    }

    .formRow {
        margin: 0 0 1.2rem 0;

        .input_container {
            +.input_container {
                margin-left: 3.9%;
            }

            input {
                min-height: 3rem;
                font-family: $primaryFont;
                font-size: $normaltext;
                font-weight: $regular;
                font-style: normal;
                color: $secondaryColor;

                &::placeholder {
                    font-family: $primaryFont;
                    font-size: $normaltext;
                    font-weight: $regular;
                    font-style: normal;
                    color: $secondaryColor;
                }
            }

            .date_select {
                width: 100%;
                max-width: 9.82rem;
                min-height: 3rem;
                margin: 0 3% 0 0;

                select {
                    min-height: 3rem;

                    /* This will work for firefox */
                    @-moz-document url-prefix() {
                        padding: 14px 6px;
                    }
                }
            }

            .date_select:last-child {
                margin: 0;
            }

            .intl-tel-input.allow-dropdown .flag-container,
            .intl-tel-input.separate-dial-code .flag-container {
                right: 0;
                left: auto;
            }

            .intl-tel-input.allow-dropdown input[type=text] {
                padding-left: .5rem;
            }
        }
    }
}

/* INFORMATIONS */

.wrapper_content_mes_infos {

    // Override from app
    .wrapper_form form.w-form.w-info-form.w-login-form {
        .w-has-tel .w-input-container .errormsgadd {
            font-size: $normaltext;
            color: $grey;
        }

        .w-form-line.w-submit {
            max-width: fit-content;
        }
    }

    .wrapper_form .update_success {
        font-family: $primaryFont;
        font-size: $normaltext;
        font-weight: $light;
        font-style: normal;
        color: $green;
        margin-bottom: 1rem;
    }
}

/* ADRESSES */
.wrapper_moncompte_main.wrapper_mes_adresses {
    display: flex;
    flex-direction: column;

    &.list_addresses {
        margin-right: 120px;

        @include queriesWshop (1300px, max) {
            margin-right: 0;
        }
    }

    #adresseForm {
        .w-input-group .w-group-label {
            font-family: $primaryFont;
            font-weight: $light;
            font-size: $normaltext;
            color: $black;
        }
    
        .w-has-tel .w-input-container .errormsgadd {
            color: $grey;
            font-size: $normaltext;
        }

        .addrSubmit {
            width: fit-content;
        }
    }

    .add_new_address {
        margin-top: 2.5rem;
    }
}

.conteneur_adresse {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0px clamp(50px, 5vw, 100px);
    width: 100%;

    .adresse {
        width: 240px;
        margin-bottom: 3rem;
    
        p {
            color: $black;
        }
        .addr_type {
            text-transform: capitalize;
            font-weight: $bold;
        }
    
        .full_name {
            text-transform: capitalize;
        }
    
        .addr_line,
        .country {
            margin-top: 8px;
        }
    
        .zipcity {
            display: flex;
            flex-direction: column;
        }
    
        .modlinks_wrapper {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
    
            a {
                text-decoration: underline;
                font-size: $normaltext;
                font-weight: $regular;
                margin-bottom: 12px;
            }
        }
    }
}

/* NEWSLETTER */

.wrapper_moncompte_main.content_newsletter {
    .update_success,
    .update_failed {
        margin-bottom: 20px;
    }

    .update_success {
        color: $green;
    }

    .w-newspreferences-form {
        .w-form-line:not(.w-submit) {
            margin-bottom: 28px;
        }
    }  

    .w-input-label .multiline {
        font-size: $normaltext;
        font-weight: $light;

        strong {
            font-weight: $bold;
            margin-bottom: 4px;
        }
    }
}

/* WISHLIST */

.wrapper_moncompte_main.wishListWrapper {
    margin-right: 60px;

    .account_header {
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        padding: 0 0 9px;
        letter-spacing: 0 !important;
    }

    @include queriesWshop (1150px, max) {
        margin-right: 30px;
    }
}

.wishlist_container.main_bloc {
    .wish_liste_product {
        width: 100%;
    
        .f_left.product_ctn {
            width: 100%;
            margin: 20px 0;
            border-bottom: 1px solid #000;

            &:last-of-type {
                border-bottom: none;
            }

            &.no_stock {
                .photo_product,
                .desc_and_price > .product_desc {
                    opacity: 0.5;
                }
            }
        }
    
        .wrapper_product {
            display: flex;
            padding-bottom: 20px;
        }
    
        .photo_product {
            img {
                display: block;
                width: 80px;
                height: auto;
            }
        }
    
        .desc_and_price {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            padding: 0 0 0 20px;
            position: relative;

            & > .product_desc {
                display: flex;
                flex-direction: column;
                height: 100%;

                & > .bottom_content_price {
                    margin-bottom: 0;
                }

                .addWishlistToBasket {
                    margin-top: auto;
                    margin-bottom: 0;

                    .ctaAddWishlistToBasket {
                        span {
                            display: inline-block;
                            font-family: $primaryFont;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 1;
                            text-transform: uppercase;
                            padding-bottom: 3px;
                            border-bottom: 1px solid $black;
                        }
                    }
                }
            }
    
            .title span {
                font-family: $primaryFont;
                font-weight: $bold;
                font-size: $subtitle;
                color: $txtcolor;
                line-height: $lh_subtitle;
            }

            .wrapper_details {
                display: flex;
                flex-direction: column-reverse;
                margin-top: 5px;
            }
    
            .feats,
            .feats label {
                font-family: $primaryFont;
                font-weight: $light;
                font-size: $smalltext;
                color: $txtcolor;
                line-height: $lh_normaltext;
            }

            .feats label {
                text-transform: uppercase;
            }
    
            .report {
                position: relative;
                display: flex;
                justify-content: flex-end;
                max-width: 256px;
                font-family: $primaryFont;
                font-weight: $light;
                font-size: $normaltext;
                color: $txtcolor;
                line-height: $lh_normaltext;
                text-transform: uppercase;

                .button {
                    display: inline-block;
                    height: auto;
                    width: auto;
                    color: $black;
                    background: transparent;
                    line-height: 1;
                    border: none;
                    padding: 0;
                    margin-right: 0;
                    border-bottom: 1px solid $black;
                }
            }
    
            .product_prix .pricetag {
                font-size: $price;
                font-weight: $medium;
                letter-spacing: 0px;
            }
        }

        .select_delete {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: auto;
            max-width: 342px;
            height: 100%;
            white-space: nowrap;
        
            .product_desc {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                padding-left: 15px;

                .product_wishlist {
                    width: 100%;
                    max-width: 256px;

                    @media screen and (max-width: 1024px) {
                        width: 89%;
                    }
                }
            }
    
            .product_check,
            .bottom_content_price {
                display: inline-block;
        
                a {
                    text-decoration: none;
                    position: relative;
                    display: flex;
        
                    &:before {
                        content: '';
                        display: block;
                        @include bgIconPath('../svg/spritesheet.svg');
                        @include bgIconCoord(7, $initialColSize, $initialRowSize);
                        height: 15px;
                        width: 15px;
                        position: relative;
                        left: 3px;
                        top: 0;
                    }
        
                    span {
                        display: none;
                    }

                    &.remove {
                        display: block;
                        width: 15px;
                    }
                }
        
                label {
                    align-items: center;
        
                    &:before {
                        margin-top: 2px;
                        margin-right: 2px;
                        border-color: #000;
                    }
        
                    &:after {
                        background: $black;
                        transition: opacity .2s ease-in-out;
                        top: 5px;
                        left: 3px;
                        width: 10px;
                        height: 10px;
                    }
        
                    span {
                        display: none;
                    }
                }
            }

            .bottom_content_price {
                display: flex;
                width: 70px;

                @media screen and (max-width: 1024px) {
                    width: 0;
                }

                &:before {
                    content: '';
                    position: relative;
                    top: 3px;
                    display: inline-block;
                    width: 1px;
                    height: 15px;
                    background: #000;
                    margin: 0 24px 0 30px;

                    @media screen and (max-width: 1024px) {
                        margin: 0 1px 0 1px;
                    }
                }
            }
        }
    }
}

.wishlist_container.bloc_right {
    width: 250px;
    margin-right: 15px;

    #nrArticlesSelected {
        padding: 10px 0 15px;
        text-align: center;

        .txt {
            font-family: $primaryFont;
            font-size: $normaltext;
            font-weight: $light;
            color: $txtcolor;
        }

        .pricetag {
            font-weight: $semibold;
            letter-spacing: 1px;
        }
    }

    .form_submit {
        width: 250px;

        #btn_add_wishlist {
            width: 100%;

            span {
                white-space: nowrap;
            }
        }

    }

    .wrapper_totaux {
        margin-bottom: 30px;
    }
}

.share_wl {
    text-align: center;
    font-family: $primaryFont;
    font-weight: $light;
    font-size: $normaltext;
    color: $txtcolor;
    text-align: left;

    .social_media {
        position: relative;
        left: -3px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .social_btn {
            margin: 10px 14px 0 0;
            cursor: pointer;

            span {
                &:after {
                    content: '';
                    display: block;
                    width: 26px;
                    height: 26px;
                    @include bgIconPath('../svg/spritesheet.svg');
                }
            }

            &.fb_share {
                span {
                    &:after {
                        @include bgIconCoord(1, $initialColSize, $initialRowSize);
                    }
                }
            }

            &.mail {
                margin-right: 0;

                span {
                    &:after {
                        @include bgIconCoord(20, $initialColSize, $initialRowSize);
                    }
                }
            }
        }
    }

    a {
        text-decoration: none;
    }
}

body.wishlist {
    .wrapper_moncompte {
        width: 100%;
        max-width: 1490px;
        min-width: 990px;
        padding: 0 7.72% 0 1.25%;
        margin: 3rem auto 0;
        box-sizing: border-box;

        @media only screen and (max-width: 1520px) {
            padding-left: 10px; 
        }

        @media only screen and (max-width: 1100px) {
            padding-right: 3.5%;
        }

        .wrapper_menu_compte {
            max-width: 190px;
            margin-top: 0.5rem;

            & > div a {
                font-size: 17px;
                letter-spacing: 0;
            }
        }

        .btnAddBasketWrapper.expressAlertStock {
            width: 100%;
            max-width: 256px;

            .bloc_add_alert_form {
                position: relative;

                .w-form-line {
                    position: relative;
                    width: 100%;
                    max-width: 256px;

                    .w-input-container {
                        input[type=text].w-input-element {
                            width: 100%;
                            height: 48px;
                            border-radius: 5px;
                            padding-right: 48px;

                            &.inputErr {
                                border-color: $borderError;
                            }

                            &::placeholder {
                                font-family: $primaryFont;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 1;
                                color: $grey;
                            }
                        }
                    }

                    .form_submit {
                        position: absolute;
                        top: 2px;
                        right: 2px;
                        height: 45px;
                        width: 45px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #FFF;

                        button {
                            display: block;
                            width: 100%;
                            height: 100%;
                            cursor: pointer;
                        }

                        span {
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: uppercase;
                        }
                    }
                }
            }

            .alert_return {
                visibility: hidden;
                position: absolute;
                right: -73px;
                top: 19px;
                display: block;
                width: 15px;
                height: 15px;
                font-size: 0;
                @include bgIconPath('../svg/spritesheet.svg');
                @include bgIconCoord(7, 6, 6);
                background-color: $white;
                z-index: 5;

                @media only screen and (max-width: 1024px) {
                    top: 0;
                    bottom: 0;
                    right: -19px;
                    margin: auto;
                }
            }

            .bloc_add_alert_confirmation {
                width: 100%;
                white-space: initial;

                p {
                    text-transform: none;
                }
            }

            & + .validEmailTxt {
                position: absolute;
                bottom: -20px;
                left: 0;
                text-transform: initial;
                color: $borderError;
            }

            
            .alert_return {
                visibility: visible;
                display: block !important;
            }

            &:before {
                content: '';
                position: absolute;
                left: -20px;
                width: 20px;
                height: 48px;
            }
        }

        .wrap_txt {
            display: flex;
            align-items: center;
            padding-top: 7px;
            margin-bottom: 0;

            .title {
                margin-right: 20px;
            }
        }
    }
}

// Lightbox
.wishlist .lightbox#abox .wrap_btn_abox {
    display: none;
}

.intro_and_totaux {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include queriesWshop (1150px, max) {
        flex-direction: column;
        align-items: flex-start;
    }

    .intro.wishlist_intro {
        font-family: $primaryFont;
        font-size: $normaltext;
        line-height: $lh_normaltext;
        font-weight: $light;
        color: $txtcolor;
    }

    .wrapper_totaux {
        .button {
            height: 100%;
        }

        .form_submit  {
            margin-top: 10px;
        }
    }
}

// Wishlist disconnected
.wrapper_moncompte.deconnecter {
    width: 100%;
    max-width: 1440px;
    margin: 2rem auto 7rem;
    padding-left: clamp(20px, fs-vw(130, 1920), 130px);

    .wrapper_moncompte_main.wishListWrapper {
        width: calc(100% - 510px);
        margin-right: 60px;
    }

    .account_header {
        font-family: $secondaryFont;
        font-size: $title2;
        letter-spacing: 1px;
        font-weight: $bold;
        color: $black;
        padding: 0 0 9px;
        text-align: left;
    }

    p {
        font-family: $primaryFont;
        font-size: $normaltext;
        color: $black;
        font-weight: $light;
    }

    .wrapper_menu_compte {
        .menu_item_links.wishlist {
            font-family: $primaryFont;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            color: $black;
        }
    }

    .wrapper_wishlist {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: center;
        width: 100%;

        .intro_and_totaux {
            width: 100%;

            .wrapper_btn_add {
                padding-top: 7px;
                
                @include queriesWshop (1150px, min) {
                    padding-left: 20px;
                }

                .button {
                    background-color: transparent;
                    border: none;
                    border-bottom: 1px solid $grey;
                    padding: 0 0 3px 0;
                    min-height: 15px;
                    text-decoration: none;
                    text-transform: uppercase;
                    line-height: 1;
                    margin: 6px 0;

                    span {
                        color: $txtcolor;
                        font-weight: $medium;
                        font-family: $primaryFont;
                        font-size: 13px;
                        white-space: nowrap;
                    }
                }
            }
        }

        .wrap_block_wishlist {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
        }
    }

    .wishlist_container.main_bloc {
        width: 100%;
        margin-right: 60px;

        .wish_liste_product {
            width: 100%;
        }

        @media only screen and (max-width: 1110px) {
            margin-right: 25px;
        }
    }

    .wishlist_container.bloc_right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 250px;
        margin-right: 0;

        .wrapper_totaux {
            width: 100%;

            .wrapper_btn_add {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            #nrArticlesSelected {
                padding: 0 0 15px 0;
            }
        }
    }
}

// Order list

.wrapper_moncompte_main.wrapper_mes_commandes {
    max-width: 100%;

    .account_header {
        padding: 0 0 9px;
    }

    .ordersIntro {
        font-family: $primaryFont;
        font-size: $normaltext;
        color: $black;
        font-weight: $light;
        line-height: $lh_normaltext;
        padding-bottom: 20px;
    }

    .wrapper_menu_tab {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .date_select {
            width: 18rem;
            height: 3rem;
            
            select {
                color: $black;
                font-weight: $light;
            }
        }
    }

    #com_onglet {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        li {
            padding-left: 2.2rem;

            @media only screen and (max-width: 1025px) {
                padding-left: 2.7vw;
            }

            a {
                text-decoration: underline;
                white-space: nowrap;
            }

            &.actif a {
                text-decoration: none;
            }

            span {
                font-family: $primaryFont;
                font-size: $normaltext;
                color: $black;
                font-weight: $light;
                white-space: nowrap;
                text-transform: capitalize;
            }
        }
    }
}

.table_mon_compte {
    margin-top: 2rem;

    .header {
        border-bottom: 1px solid $grey;
        line-height: 28px;
    }

    th {
        font-family: $primaryFont;
        font-size: $subtitle;
        color: $black;
        font-weight: $light;
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    td {
        font-family: $primaryFont;
        font-size: $normaltext;
        color: $black;
        font-weight: $light;
        line-height: $lh_normaltext;
        padding-top: 1.2rem;
        padding-right: 1.5rem;
        vertical-align: middle;

        a {
            text-decoration: none;
        }

        &.link_detail a,
        &.facture a,
        &.cmdDetailLink a {
            border-bottom: 1px solid $grey;
        }
    }
}

// Messages

.wrapper_moncompte_main.content_messages {
    width: 100%;
    max-width: 1045px;

    .account_header {
        padding: 0 0 12px;
    }

    .wrapper_msg_bounce {
        padding-bottom: 1.5rem;
    }

    .newMsg {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3rem;

        p {
            font-size: $normaltext;
            line-height: $lh_normaltext;
        }

        .button {
            margin: 0 0 0 1rem;

            span {
                white-space: nowrap;
            }
        }
    }

    .btn_ouvrir_message {
        position: relative;
        cursor: pointer;

        &:after {
            content: "";
            background: $grey;
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }


    .appear form#data {
        border-top: 1px solid $fadeGreyline;
        padding-top: 1.55rem;

        p {
            font-size: $smalltext;
            text-align: left;
            text-transform: uppercase;
        }

        textarea {
            border: 0;
            padding: 0;
            padding-top: .825rem;
            min-width: 100%;
            max-width: 1187px;
            min-height: 100px;
            font-size: $smalltext;
            font-weight: $light;
            font-style: normal;
        }
    }                

    .wrapper_message_detail {
        padding: 0;
    }

    .details_msg_content {
        border-bottom: 1px solid $black;
        padding: .87% 1rem 1.6rem;
        text-align: left;
        font-family: $primaryFont;
        font-size: $normaltext;
        font-weight: $light;
        color: $black;

        p {
            color: $black;
        }

        label {
            text-transform: uppercase;
            font-size: .8rem;
            width: 72px;
            display: inline-block;
            line-height: 2;
        }

        .detail_msg_label {
            display: none;
        }

        .message_details_holder {
            margin-top: 1.7rem;
            font-size: $normaltext;
            text-align: justify;
            width: 100%;
            line-height: $lh_normaltext;
        }
    }
}

// Order detail

.wrapper_content_cmd_detail {
    max-width: 100%;
    margin-right: 110px;

    @media only screen and (max-width: 1300px) {
        margin-right: 40px;
    }

    .account_header {
        padding: 0 0 12px;
    }

    .order_detail_head {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 2rem;

        .box_infos {
            margin-right: 5rem;
        }

        p {
            padding-bottom: 0.8rem;

            &.num_suivi {
                a {
                    text-decoration: none;

                    span {
                        font-weight: $light;
                        font-size: $normaltext;
                        border-bottom: 1px solid $grey;
                    }
                }
            }
        }

        .wrapper_delivery_addr p {
            padding-bottom: 0.6rem;
        }
    }

    .cmd_items {
        .product-link {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            background-color: $primaryColor;
            padding: 20px;
            margin-bottom: 1rem;

            .cart_thumb_holder {
                img {
                    width: 90px;
                    height: auto;
                }
            }
        }
    }

    .cmd_detail {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 88%;

        @media only screen and (max-width: 1300px) {
            width: 100%;
        }
    }

    .left_cmd_items_wrapper {
        width: 100%;
        margin-right: 2.5rem;
        color: $black;
        font-size: $normaltext;
        font-weight: $light;

        #comment_retourner_cmd {
            font-family: $primaryFont;
            font-size: $normaltext;
            font-weight: $light;
            color: $black;
        }

        p {
            line-height: 18px;
        }

        #choixRetour {
            width: 50%;
            border: 1px solid $grey;
            padding: 0.5rem;
            outline: none;
            color: $black;
            font-family: $primaryFont;
            font-size: $normaltext;
            font-weight: $light;
            margin-top: 1rem;
        }
    }

    .content {
        width: 100%;
        margin: 1rem 0;

        a {
            text-decoration: none;
            color: $black;
            font-family: $primaryFont;
            font-size: $normaltext;
            font-weight: $light;
            position: relative;

            span {
                &:after {
                    content: "";
                    background: $grey;
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }
        }
    }

    .right_cmd_items_wrapper {
        max-width: 25rem;
        width: 100%;

        .detailRecapTop {
            background: $primaryColor;
            padding: 25px 30px;
            position: relative;
        }

        .recap_title {
            font-size: $title3;
            font-weight: $bold;
            margin-bottom: 35px;
        }

        .pricetag {
            font-weight: $medium;
            font-size: $price;
            letter-spacing: normal;
        }

        .formRow {
            justify-content: space-between;
            margin: 0 0 0.6rem 0;

            label {
                font-family: $primaryFont;
                font-size: $normaltext;
                line-height: $lh_normaltext;
                font-weight: $light;
                color: $black;
            }

            &:last-of-type {
                margin-top: 25px;
            }
        }

        label.montant_total {
            text-transform: uppercase;
        }

        .button {
            margin-top: 20px;
        }
    }

    #pdfDIV {
        margin-top: 15px;
    }

    #motif_retour_etape {
        margin-top: 15px;

        .btn_container {
            margin-bottom: 15px;
        }
    }

    .texte_num_retour {
        display: none;
    }

    .commande_retour_info {
        display: none;
    }
}

.cmd_detail,
.popup_numero_retour {
    .cart_detail_box {
        width: 100%;
    
        .cart_product {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            padding: 0 20px;
            font-family: $primaryFont;
            font-weight: $light;
            font-size: $normaltext;
            color: $black;
            line-height: 24px;
            margin-bottom: 5px;
    
            .title {
                font-size: $subtitle;
                font-weight: $bold;
            }
    
            .wrapper_price {
                .pricetag {
                    font-family: $primaryFont;
                    font-weight: $medium;
                    font-size: $price;
                    letter-spacing: 0px;
                }
            }
        }
    
        .itemFeat {
            padding: 0 20px;
            font-size: $smalltext;
            font-weight: $light;
            line-height: $lh_normaltext;
            color: $black;
    
            label {
                text-transform: uppercase;
            }
        }
    }
}

.errormsgadd {
    font-size: 13px !important; // _app override 😡
    font-weight: 300 !important; // _app override 😡
    color: $grey !important; // _app override 😡
}