.contact_faq,
.footer_contact {
	#wrapper_contacteznous{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		width: 100%;
		max-width: 1060px;
		margin: 80px auto;

		@media only screen and (max-width: 1800px) {
            padding: 0 20px;
        }

		.titre_contact{
			font-family: $secondaryFont;
			font-size: $title1;
			color: $black;
			display: block;
			margin-bottom: 60px;
			text-align: center;
			width: 100%;
			font-weight: $bold;
		}
	}

	.wrapperContentFaqForm{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		width: 100%;

		.block_img{
			margin-right: 40px;

			img{
				display: block;
				width: 100%;
			}
		}

		.block_img,
		#contact_form{
			display: block;
			width: 50%;
			max-width: 510px;
		}

		.mention_cgv{
			font-family: $primaryFont;
			font-size: $normaltext;
			line-height: $lh_normaltext;
			color: $black;
			letter-spacing: -0.25px;
			display: block;
			margin-bottom: 52px;
			font-weight: $light;
		}

		.taunt_msg{
			font-family: $primaryFont;
			font-weight: $bold;
			font-size: $normaltext;
			line-height: $lh_normaltext;
			color: $black;
			display: block;
			margin-bottom: 40px;
		}

		.info_sup{
			font-family: $primaryFont;
			font-size: $normaltext;
			line-height: $lh_normaltext;
			color: $black;
			font-weight: $light;

			strong{
				font-weight: $bold;
			}
		}

		a {
			text-decoration: underline;
		}
	}

	#contact_form{

		.w-contact-form {
			padding-bottom: 1rem;
		}

		.wrapperErrors,
		#message_mail{
			font-family: $primaryFont;
			font-size: $normaltext;
			line-height: $lh_normaltext;
			margin-bottom: 10px;
			font-weight: $medium;

			strong{
				font-weight: $bold;
			}

			#mes_err_fieldset{
				display: none;
			}

			span{
				&.error{
					color: $red;
				}

				&.valid{
					color: $green;
				}
			}
		}

		.w-submit {
			width: auto;
			display: inline-block;
		}

		.wrapperErrors{
			color: $red;
			margin-bottom: 0;
		}
	}
}