.body_login {
    .login_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $primaryFont;
        color: $black;
        width: 100%;
        max-width: 650px;
    }

    .newlogin_page {
        .login_bloc:first-of-type {
            border-bottom: none;
            padding-bottom: 0;
        }

        .login_bloc:last-of-type {
            padding-top: 27px;
        }
    }

    .login_bloc {
        width: 100%;
        align-items: flex-start;

        .w-client-form {
            align-items: flex-start;
        }

        .title {
            text-align: left;

            .acc_login_title,
            h2,
            h3 {
                font-family: $primaryFont;
                text-transform: none;
                font-weight: $bold;
                letter-spacing: normal;
            }

            h2,
            .acc_login_title {
                font-size: $title1;
                margin-bottom: 0.3rem;
            }

            h3 {
                font-size: $title3;
                margin-top: 20px;
                margin-bottom: 5px;
            }

            h4 {
                letter-spacing: normal;
                font-family: $primaryFont;
                font-size: $subtitle;
                font-weight: $light;
            }

            &:not(.my_account) {
                margin-bottom: 15px;

                .subtitle {
                    font-size: $normaltext;
                    font-weight: $light;
                    line-height: $lh_normaltext;
                    color: $black;

                    span {
                        display: block;
                    }
                }
            }
        }

        .subtitle {
            display: none;
        }

        .w-fieldset-label {
            font-family: $primaryFont;
            font-size: $normaltext;
            color: $black;
            margin-left: 0;
        }

        .w-newlogin-form .optin_container {
            justify-content: flex-start;

            .knowMoreOptin {
                display: none;
            }
        }

        .w-oneclick-connect {
            flex-direction: row-reverse;

            #googleConnect {
                margin-left: 0;
                margin-right: 28px;
                background: none;

                #googleConnectLabel:before {
                    @include bgIconPath('../svg/spritesheet.svg');
                    @include bgIconCoord(19, $initialColSize, $initialRowSize);
                    width: 30px;
                    height: 30px;
                    padding: 3px;
                    margin: 0;
                    box-sizing: border-box;
                }
            }

            .fb-connect {
                background: none;

                .fb-oneclick {
                    @include bgIconPath('../svg/spritesheet.svg');
                    @include bgIconCoord(25, $initialColSize, $initialRowSize);
                    width: 30px;
                    height: 30px;
                    padding: 3px;
                    margin: 0;
                }
            }

            .pp-connect {
                display: none;
            }
        }

        .mdpSentEmail {
            color: $green;
        }
    }

    .login_form_wrapper {
        #subtitleLogin {
            font-size: $normaltext;
            font-weight: $light;
            color: $black;
        }

        .w-newlogin-form {
            width: 100%;

            .w-pwd-reset {
                font-size: $normaltext;
                font-weight: $light;
                color: $grey;
                margin-bottom: 5px;
                margin-left: 0;
            }
        }

        .w-lostpass-form {
            align-items: flex-start;

            .w-form-line {
                margin-bottom: 20px;
            }

            .mdpSentEmail {
                font-size: $normaltext;
                font-weight: $light;
            }
        }
    }

    .login_protection_donnees_msg {
        font-family: $primaryFont;
        font-size: $smalltext;
        font-weight: $light;
        margin-top: 50px;
    }

    &.in_tunnel {
        .newlogin_page {

            .login_bloc .title h3 {
                font-family: $primaryFont;
                margin-top: 20px;
            }
        }
    }
}

// FORGOTTEN PASSWORD

.passwordForgoten {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-family: $primaryFont;
    color: $black;
    width: 100%;
    max-width: 650px;
    padding-top: 30px;
    margin: auto;

    .title.mdpReset {
        font-size: $title2;
        line-height: $lh_title2;
        font-weight: $bold;
        margin-bottom: 20px;
    }

    #clientForm {
        width: 100%;
    }
}