.store_locator {
    color: $black;

    .breadcrumbs {
        padding: 10px clamp(10px, fs-vw(50, 1920), 50px) 15px;
        width: 100%;
        max-width: 100%;

        a {
            text-transform: lowercase;
            display: block;
            
            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    .wrapper_store {
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;

        .wrapper_retailers_map {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 63px;
            padding: 0 clamp(10px, 2.60417vw, 50px) 15px;
 
            .page-title {
                margin-bottom: 41px;
                font-size: 40px;
                font-weight: $bold;
                color: $black;
            }

            .storeloc_form {
                width: 100%;
                margin: 0 auto 40px;
                order: 1;

                .form_itm {
                    max-width: 54%;
                    margin: auto;

                    &.country_select {
                        display: none;
                    }

                    &.zip_input {
                        div {
                            position: relative;

                            #search_cp {
                                box-sizing: border-box;
                                border: none;
                                border-bottom: 1px solid $black;
                                border-radius: 0;
                                padding: 0;
                                height: 29px;
                                width: 100%;
                                @include wshopFont($primaryFont, 16px, 18px, unset, $light, $black);

                                &::placeholder {
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                    color: $grey;
                                }

                                &:empty{
                                    + label.placeholder {
                                        display: none;
                                    }
                                }
                            }

                            .placeholder {
                                position: absolute;
                                top: -45%;
                                left: -1px;
                                font-size: 12px;
                                font-weight: $light;
                            }

                            p.ou {
                                display: none;
                            }

                            .magnifying_glass {
                                cursor: pointer;
                                width: 20px;
                                height: 20px;
                                border: none;
                                background-color: $white;
                                position: absolute;
                                right: 1px;
                                top: -1px;
                                padding: 0;
                                width: 22px;
                                height: 22px;
                                background-image: url(../svg/spritesheet.svg);
                                @include bgIconCoord(26,6,6);
                            }

                            #btn_geoloc {
                                display: none;
                            }
                        }
                    }
                }
            }

            #retailers_map {
                width: 64.2%!important;
                order: 3;
                margin-left: auto;

                button {
                    width: inherit !important;
                    border-radius: 0 !important;
                }

                .gm-style-iw {
                    width: 309px;
                    min-height: 131px;
                    border-radius: 0;
                    border: 1px solid $black;
                    padding: 20px 71px 20px 20px;

                    &.gm-style-iw-c {
                        padding: 20px !important;
                        display: flex;
                    }

                    a {
                        color: $black;
                    }

                    .info_popup {
                        text-align: left;
                        font-family: $primaryFont;

                        .name,
                        .adresse {
                            padding-bottom: 10px;
                            line-height: 20px;
                        }

                        .name a {
                            font-size: 14px;
                            font-weight: 400;
                        }

                        .savoir_plus,
                        .adresse{
                            font-size: 13px;
                        }

                        .horaire_info,
                        .coord,
                        .horaire_title {
                            display: none;
                        }

                        .name {
                            font-size: 13px;
                            font-weight: $bold;
                            text-transform: uppercase;

                            .dist {
                                display: none;
                            }

                            a {
                                text-decoration: none;
                                outline: none;
                            }
                        }

                        .store_localisation_container {
                            display: flex;
                            font-size: 13px;
                            column-gap: 20px;

                            .btn_voir_magasin {
                                a {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }

                .gm-style-iw-tc {
                    display: none;
                }
            }

            .img-loader-container {
                display: none !important;

                img {
                    width: 50px;
                    height: auto;
                }
            }

            .section-list-store {
                width: 33.9%;
                height: 727px;
                order: 2;
                overflow: hidden;

                .search-result {
                    font-size: 13px;
                    font-weight: $light;
                    padding-bottom: 10px;
                    color: $black;

                    .search-result-text {
                        display: inline;
                    }
                }

                #list-store {
                    max-height: 705px;
                    width: 100% !important;
                    overflow-y: auto;
                    padding: 0;

                    @-moz-document url-prefix() {
                        scrollbar-width: thin;
                        scrollbar-color: $black $primaryColor;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: $primaryColor;
                    }

                    &::-webkit-scrollbar {
                        width: 4px !important;
                        background-color: $primaryColor;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: $black;
                    }

                    li {
                        list-style-type: none;
                    }

                    .liste_distributeur {
                        .no_result {
                            font-size: 0;
                        }
                    }

                    .os-scrollbar-handle {
                        height: 150px !important;
                    }

                    .elem-list-store {
                        width: 100%;
                        font-family: $primaryFont;
                        padding: 12px 0 23px;
                        border-bottom: 1px solid $black;

                        &:first-of-type {
                            padding-top: 0;
                        }

                        &:last-of-type {
                            padding-bottom: 2px;
                            margin-bottom: 0;
                            border-bottom: none;
                        }

                        .nom_store .title {
                            font-size: 20px;
                            color: $black;

                            .distance {
                                display: none;
                            }
                        }

                        .detail_store {
                            color: $black;
                            font-size: 13px;
                            line-height: 22px;

                            .ville_store {
                                display: block;
                            }

                            .horaire_today {
                                position: relative;
                                font-size: 13px;
                                display: inline-block;
                                padding-left: 17px;
                                margin: 0 0 3px;

                                &:before {
                                    display: inline-block;
                                    content: '•';
                                    left: 0;
                                    top: 7px;
                                    position: absolute;
                                    // Let it be arial so as '•' to be round
                                    @include wshopFont('Arial', 30px, 10px, unset, 300, false);
                                }

                                &.puce_store_open {
                                    &:before,
                                    .open {
                                        color: $green;
                                    }
                                }

                                &.puce_store_closed {
                                    color: $red;

                                    &:before,
                                    + .mag_open_status {
                                        color: $red;
                                    }
                                }


                                &.puce_store_open {
                                    &:before,
                                    .mag_open_status {
                                        color: $green;
                                    }
                                }

                                &.puce_store_closed {
                                    &:before,
                                    .mag_open_status,
                                    span.txt {
                                        color: $red;
                                    }
                                }
                            }

                            .pays_store {
                                display: none;
                            }
                        }

                        .tel_store {
                            display: flex;
                            font-size: 13px;
                            line-height: 21px;
                        }

                        .addfavorite,
                        .horaire_info {
                            display: none;
                        }

                        .store_more {
                            a {
                                font-size: 13px;
                                text-decoration: underline;
                                color: $black;
                            }

                            + a {
                                display: none;
                            }
                        }
                    }
                }

                .element_count + br {
                    display: none;
                }
            }
        }
    }
}

.store_locator #retailers_map .gm-style-iw,
.satellite_store_details  .right_bloc .gm-style-iw-a {
    button.gm-ui-hover-effect {
        top: -9px !important;
        right: -9px !important;
        width: 20px !important;
        height: 20px !important;
        background-image: url(../svg/spritesheet.svg) !important;
        @include bgIconCoord(7,6,6);
        background-size: 600% auto !important;
        background-position: 0 20% !important;
        opacity: 1;
        
        span {
            display: none!important;
        }
    }

    #copyright {
        margin: 44px auto 0;
    }
}

.satellite_store_details {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;    

    #copyright {
        margin: 44px auto 0;
    }

    #breadcrumbs {
        width: 100%;
        padding: 15px clamp(10px, 2.60417vw, 50px);

        .breadcrumb.spcovid {
            &::after {
                display: none;
            }
        }   

        .breadcrumbs {
            width: 100%;
        }     
    }

    .innerWrapper {
        display: flex;
        width: 95%;
        max-width: 1020px;
        flex-direction: column;
        margin: 0 auto;

        .left_bloc {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .go_store {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                left: -6px;
                color: $black;
                font-size: 11px;
                margin: 0 0 2rem;
    
                &::before {
                    content: '';
                    position: relative;
                    display: block;
                    width: 10px;
                    height: 10px;
                    left: 3px;
                    transform: rotate(180deg);
                    background-image: url('../svg/spritesheet.svg');
                    @include bgIconCoord(6, 6, 6);
                    margin-right: 6px;
                }
            }

            .wrapper_store_description {
                width: 46%;
            }

            .wrapper_store_detail {
                display: flex;
                justify-content: space-between;
                width: 52%;
                background-color: $borderColor;
                padding: 23px 32px;
            }
            
            .nom_mag {
                font-size: 14px;
                padding-bottom: 5px;

                .title_details {
                    font-size: 25px;
                }

                .sub_title {
                    font-weight: 300;
                    padding: 20px 0 0;
                }
            }

            .content_info  {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: space-between;
                width: 57%;
                padding-bottom: 20px;

                .store_infos {
                    line-height: 21px;
                    width: 100%;
                    margin-bottom: 25px;

                    .storeAddress {
                        font-size: 13px;
                    }
                }

                .storeAddress {
                    padding-bottom: 6px;
                }

                .store_direction  {
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: flex-end;
                    justify-content: center;

                    @media only screen and (max-width: 1366px) {
                        width: 100%;
                        justify-content: flex-start;
                    }

                    #go_to_store {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        width: 250px;
                        height: 41px;
                        margin-bottom: 17px;
                        text-decoration: none;
                        font-family: $primaryFont;
                        font-size: 13px;
                    }

                    .btn_send_info {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 11px;
                        background-color: $white;
                        color: $black;
                        border: none;
                        font-family: $primaryFont;
                        font-size: 13px;
                        line-height: 1.6;
                        background-color: $borderColor;

                        @media only screen and (max-width: 1366px) {
                            width: 100%;
                            justify-content: flex-start;
                        }

                        span {
                            border-bottom: 1px solid $black;
                        }
                    }
                }

                .store_details_schedule {
                    display: inline;
                    padding-top: 16px;
                    margin-bottom: 10px;
                    color: $black !important;
                    font-size: 13px;
                    line-height: 19px;
                    font-weight: 100;

                    .open,
                    .store_close {
                        &::before {
                            position: relative;
                            top: -1px;
                            margin-right: 5px;
                            display: inline-block;
                            content: '•';
                            font-family: sans-serif;
                            font-size: 30px;
                            vertical-align: middle;
                        }
                    }
                
                    .open,
                    .open:before {
                        color: $green;
                    }

                    .store_close,
                    .store_close:before {
                        color: $red;
                    }
                }
            }

            .storeAddress,
            .content_descrip,
            .content_hor {
                padding: 20px 0;
                font-size: 13px;
                line-height: 18px;
                font-weight: $light;

                .bloc_title {
                    font-weight: 400;
                    font-size: 14px;
                    padding-bottom: 10px;
                    text-transform: uppercase;
                }
            }
            
            .content_hor {
                width: 41%;
                .time_table {
                    display: flex;
                    flex-direction: column;
                    font-size: 13px;
    
                    .date_time {
                        display: flex;
                        justify-content: space-between;
    
                        .day {
                            width: 97px;
                        }
                    }
                }
            } 

            .services.bloc_store_det {
                display: none;
            }
        }

        .right_bloc {
            #store_detail_map {
                display: none;
            }

            #store_slider {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-start;
                margin-top: 2rem;

                img {
                    width: 100%;
                    height: auto;
                    margin-bottom: 2rem;

                    &:nth-child(2),
                    &:nth-child(3) {
                        width: 48.5%;
                    }
                }
            }

            .image {
                height: auto;
                margin-top: 20px;

                img {
                    width: 100%;
                }
            }

            .store_img {
                height: auto;
                margin-top: 20px;

                .swiper-slide img {
                    width: 100%;
                }
            }

            .gm-style-iw-a {
                .gm-style-iw-c {
                    width: 309px;
                    min-height: 110px;
                    border-radius: 0;
                    border: 1px solid $black;
                    padding: 20px !important;

                    .gm-style-iw-d {
                        padding-bottom: 0;
                    }
                }

                a {
                    color: $black;
                }

                .poi-info-window.gm-style {
                    text-align: left;
                    font-family: $primaryFont;
                    font-size: 14px;

                    .transit-container .gm-title,
                    .address {
                        font-size: 14px;
                        line-height: 20px;
                    }

                    .transit-container {
                        padding-bottom: 2px;

                        .gm-title {
                            font-weight: $bold;
                        }
                    }

                    .view-link{
                        display: none;
                    }

                    .transit-title {
                        font-size: 14px;
                        font-weight: 700;
                        text-transform: uppercase;

                        a {
                            text-decoration: none;
                        }
                    }
                }
            }

            .gm-style-iw-tc {
                display: none;
            }
        }
    }

    #ligthbox_send_store_information {
        display: flex;
        flex-wrap: wrap;
        padding: 3.65rem 2.5rem 1.5rem;
        width: 500px;
        text-align: left;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
        overflow: hidden;

        .close_pop {
            top: 12px;
            right: 12px;
        }

        .header_lightbox {
            width: 100%;
            @include wshopFont($primaryFont, 25px, 1, unset, $bold, unset);
            padding-bottom: 14px;
        }

        .w-form.w-sendshop-form {
            width: 100%;
            font-size: 13px;

            .w-form-line {
                margin-bottom: 25px;

                &.w-submit {
                    width: 100%;
                    max-width: 100%;
                    display: flex;
                    justify-content: center;
                }

                .w-btn-wrapper {
                    width: auto;
                }
            }

            p {
                margin-bottom: 25px;
                line-height: 1.3;
            }
        }

        &.actif {
            display: flex;
        }
    }

    .home-module-body.image-image {
        margin-top: 3.5rem;
    }
}