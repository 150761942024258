/* ------------------------------ STATE : Normal ------------------------------ */
.btn_container,
.form_submit {
    position: relative;
}

/****** Primary Button  ******/
.button,
.w-submit-button,
button.w-submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    outline: 0;
    height: $ctaHeight;
    width: fit-content;
    padding: 15px 30px;
    font-size: $normaltext;
    font-weight: $light;
    line-height: $lhCta;
    font-family: $primaryFont;
    text-transform: uppercase;
    color: var(--txtcolor, $primaryCtaColor);
    background-color: var(--bgcolor, $primaryCtaBgColor);
    border: 1px solid var(--bgcolor, $primaryCtaBorderColor);
    transition: background-color .3s, border-color .3s, color .3s;
    border-radius: 0 !important;
    cursor: pointer;

    /*** Secondary Button ***/
    &.secondary {
        color: $secondaryCtaColor;
        background-color: $secondaryCtaBgColor;
        border: 1px solid $secondaryCtaBorderColor;
    }
}

/* ------------------------------ STATE : Hover ------------------------------ */
/****** Primary Button *******/
.button,
.w-submit-button,
button.w-submit-button {
    &:not(.loader):hover {
        color: var(--bgcolor, $primaryCtaColorHover);
        background-color: var(--txtcolor, $primaryCtaBgColorHover);
        border-color: var(--bgcolor, $primaryCtaBorderColorHover);
    }

    /*** Secondary Button ***/
    &.secondary:not(.loader):hover {
        color: $secondaryCtaColorHover !important;
        background-color: $secondaryCtaBgColorHover !important;
        border-color: $secondaryCtaBorderColorHover !important;
    }
}

/* ------------------------------ STATE : Loader ------------------------------ */
/****** Primary Button ******/
.button,
.w-submit-button,
button.w-submit-button {

    &.w-loader,
    &+.loader,
    &.loader,
    &.loader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        opacity: 0;
        border: 1px solid $black;
        box-sizing: border-box;
        font-size: 0;
        background: url(../svg/three_dots_w.svg);
        background-color: $black;
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 20%;

        /* Class Loader Activé */
        &.loading {
            opacity: 1;
            z-index: 5;
        }

        span {
            font-size: 0;
        }
    }

    /*** Secondary Button ***/
    &.secondary {

        &.w-loader,
        &+.loader,
        &.loader,
        &.loader {
            background-image: url(../svg/three_dots.svg);
            background-color: $white;
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto 20%;

            /* Class Loader Activé */
            &.loading {
                opacity: 1;
                z-index: 5;
            }
        }
    }
}