body.lookbook {
    .main_ariane {
        display: none;
    }

    .main_wrapper {
        max-width: 1440px;
        width: 100%;
        min-width: 990px;
        margin: 0 auto;
    }

    // Fix page lightbox eclat display
    #lightbox_achat_express .page_produit .top_page_produit .product_main_wrapper .mainSlideFp .eclat2 {
        left: 15px;
    }

    .page_wrapper {
        overflow: hidden;
    }
}

.tag {
    position: absolute;
    background: url("../img/puce.png") center center no-repeat;
    text-decoration: none;
    width: 14px;
    height: 14px;
}

.rayon_lookbook {
    #scroll_items {
        width: 100%;
        margin: 0 auto;
        max-width: 1060px;

        @include queriesWshop(1100px, max){
            padding: 0 20px;
        }

        #list_item {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    .swiper_looks {
        margin-top: 55px;
        width: 100%;
        overflow: visible;

        .swiper-slide {
            display: flex;
            justify-content: space-evenly;

            _:-ms-lang(x) {
                justify-content: space-around;
            }

            .item_look {
                width: auto;

                &.portrait {
                    position: relative;

                    &:first-of-type {
                        right: -0.24%;
                    }

                    &:last-of-type {
                        left: -0.24%;
                    }
                }

                .item_look_contents {
                    position: relative;

                    .tag {
                        text-decoration: none;
                        position: absolute;
                        background: url("../img/puce.png") center center no-repeat;
                        width: 14px;
                        height: 14px;

                        span {
                            font-size: 0;
                        }
                    }
                }

                img {
                    width: 100%;
                    height: auto;
                    transition: width 0.25s ease-in-out;
                    padding: 0 20px;
                }
            }
        }

        .item_look {
            img {
                max-height: 536px;
            }
        }

        .swiper-btn {
            width: 1.8rem;
            height: 1.8rem;
            background: #f3eae4;
            border-radius: 50%;

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 56%;
                transform-origin: 50%;
                width: 4px;
                height: 4px;
                border: 1px solid $black;
            }

            &.swiper-button-prev {
                left: 8%;

                &:after {
                    transform: translate(-25%, -50%) rotate(45deg);
                    border-top: none;
                    border-right: none;
                    left: 50%;
                }
            }

            &.swiper-button-next {
                right: 8%;

                &:after {
                    transform: translate(-75%, -50%) rotate(45deg);
                    border-bottom: none;
                    border-left: none;
                }
            }
        }
    }

    .menubloc {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;

        .menuitem_name {
            position: relative;
            @include fontStyle(fs-vw(40,1920), 1, 700);
            display: block;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        .menuitem {
            &.lvl_one {
                width: 100%;
            }
        }
    }
}

.txt_bandeau {
    &.in_lookbook {
        @include fontStyle(13px,18px,300);
        display: block;
        text-align: center;
        max-width: 620px;
        width: 100%;
        margin: 80px auto 0;

        .title {
            @include fontStyle(25px,26px,700);
            display: block;
            margin-bottom: 40px;
        }
    }
}

.page_produit {
    &.achat_express {
        display: flex;

        // override product page
        .top_page_produit .product_main_wrapper .mainSlideFp .swiper-wrapper {
            row-gap: initial;
            flex-wrap: nowrap;

            .swiper-slide {
                position: relative;
                display: block;
                width: 100%;
                height: 0;
                padding-bottom: calc(598 / 400 * 100%);
                overflow: hidden;
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                cursor: default;
            }
        }

        .top_page_produit {
            max-width: initial;
            width: 100%;
            opacity: 1;
            margin: 0;
            column-gap: initial;
        }

        .product_main_wrapper {
            max-width: 400px;
            width: 100%;
        }

        .product_info_wrapper {
            position: relative;
            width: calc(100% - 400px);
            height: 598px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            padding: 0 40px;

            .product_info {
                width: 100%;
                margin-top: 70px;
            }

            .desc_pannel {
                display: none;
            }
        }        
    }
}