/*
Payment
*/
.checkout {
    .bank_left_side {
        width: 55.65%;
        max-width: 800px;

        &:has(#addAliasListElem) {
            .credit_card {
                display: none;
            }
        }

        &:has(#addAliasListElem.selected) {
            .credit_card {
                display: block;
            }
        }
    }

    .selectPaymentModeTitle {
        @include fontStyle(14px, 17px, 300);
        margin-bottom: 15px;
        width: 100%;
        text-transform: uppercase;
    }

    .tunnel_track_pusher {
        margin-bottom: 40px;
    }

    #outsite_ue_notice {
        max-width: 1440px;
        margin: 0 auto;
        padding-bottom: 10px;
        font-size: 14px;
        font-weight: 300;
        font-family: $primaryFont;
        color: $black;
        width: 90%;
        display: flex;
        align-items: flex-start;

        &::after {
            content: "";
            display: block;
            max-width: 640px;
            width: 72.44%;
            padding-top: 15px;
        }
    }

    .wrapper_payment_mode {
        display: flex;
        flex-wrap: wrap;
    }

    .payment_mode_btn {
        @include fontStyle(13px, 18px, 300);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-right: 10px;
        border: 1px solid $primaryColor;
        border-radius: 5px;
        background-color: $primaryColor;
        padding: 14px 20px;
        width: 100%;
        max-width: 143px;
        height: 44px;
        text-transform: uppercase;
        white-space: nowrap;

        + .payment_mode_btn {
            span {
                display: flex;
                justify-content: center;
                align-items: center;

                &::before {
                    position: relative;
                    left: -6px;
                    width: 25px;
                    height: 19px;
                    @include bgIconCoord(18, 6, 6);
                }
            }

            &[data-payment-mode="bancontact_worldline"] {
                span {
                    gap: 5px;
                    
                    &::before {
                        left: 0;
                        background-image: url("../banque/logo/BANCONTACT.svg");
                        background-position: center;
                        background-size: cover;
                        width: 35px;
                        height: 21px;
                    }
                }
            }
        }

        &.actif {
            background-color: $black;
            color: $white;
        }

        span {
            &::before {
                display: block;
                content: "";
                @include bgIconPath("../svg/spritesheet.svg");
            }
        }
    }

    .wrapper_bloc_banque {
        display: none;

        &.alias_list_wrapper:has(.alias_list) {
            display: block !important;
        }

        &.actif {
            display: block;
            transition: all 0.3s ease;
        }

        &.paypal, &.bancontact {
            .bloc_bank_contents {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }

            .bank_subtitle.loader_paypal, .bank_subtitle.loader_bancontact {
                strong,
                span {
                    display: none;
                }
            }
        }

        .cards-wrapper {
            display: none;
        }

        .title_wrapper_fold {
            margin-bottom: 16px;
        }

        .bank_title {
            @include fontStyle(14px, 17px, 300);
            text-transform: uppercase;
        }

        .bank_subtitle,
        .total_transaction, 
        .bank_confiance {
            @include fontStyle(13px, 18px, 300);
        }

        strong {
            font-weight: normal; // override <strong> tag in App FO view
        }
    }

    .wrapper_basket_content .bank_left_side .wrapper_bloc_banque {
        margin-top: 1rem;
        background-color: $primaryColor;
        padding: 1rem;

        &.credit_card {
            padding: 0 1rem 1rem;
        }

        #flex {
            min-height: 365px;

            #payment-submit,
            #loader-submit {
                height: 39px;
            }
        }

        .title_wrapper {
            margin-bottom: 1rem;
            
            .bank_title {
                @include fontStyle (20px, 26px, 700);
                text-transform: capitalize;
            }

            .bank_subtitle {
                @include fontStyle(13px, 18px, 300);
                // margin-top: 0.5rem;
            }
        }
    }

    #bloc_3x {
        display: none;
    }

    &.bankThanks {
        .tunnel_track_pusher {
            margin-bottom: 35px;
        }
        
        footer {
            margin: 64px auto 0;
        }
    } 
    #tunnel_right_col {
        padding-top: 0;
    }

    #cart_total {
        .wrapper_payment {
            position: relative;
            right: 30px;
            width: 110%;
        }

        .total_produit_dropdown .wrapper_cart_product_desc {
            .cart_product_brand,
            .cart_product_subtitle {
                display: none;
            }
        }   
    }

    .payment_addr_wrapper {
        position: relative;
        @include fontStyle(13px, 18px, 300);
        border-top: 1px solid $black;
        background-color: $white;
        padding: 12px 0 22px;
        width: 106%;

        &.conveyor_infos {
            .name {
                display: block;
            }
        }

        &::before {
            display: block;
            width: 15px;
            height: 15px;
            content: "";
            @include bgIconPath("../svg/spritesheet.svg");
            @include bgIconCoord(6, 6, 6);
            position: absolute;
            top: 42%;
            right: 8px;
        }

        .phone {
            display: none;
        }
    }

    .title {
        font-weight: 700;
    }

    .address_mod {
        display: block;
        padding-top: 5px;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    #div-worldline-hosted-tokenization {
        iframe {
            margin-left: -7vw;
            border: none;
            width: 100%;
            height: 100%;
        }

        @media screen and (max-width: 1600px) {
            iframe {
                margin-left: -11.5vw;
            }
        }

        @media screen and (max-width: 1280px) {
            iframe {
                margin-left: -8.5vw;
            }
        }

        @media screen and (max-width: 1080px) {
            iframe {
                margin-left: -7.5vw;
            }
        }
    }
}

.banque_erreur_txt {
    @include fontStyle(13px, 18px, 300);
    margin: 0 0 2em 0;
    background-color: $primaryColor;
    padding: 2em;

    a {
        color: $black;
        text-decoration: underline;
    }

    +.txt_recommencer_paiement {
        display: none;
    }

    #bloc_erreur_order {
        .rose.displayNone {
            display: none;
        }
    }
}

/*
Bank Thanks Page
*/
.bankThanksTop {
    margin: 0 !important; // _app override 😡
    width: 55.65% !important; // _app override 😡
    max-width: 800px;

    h1 {
        display: none !important; // _app override 😡
    }
}

.thanksRecap {
    width: 36.11% !important; // _app override 😡
    max-width: 520px;
}

.thanksWrapper .thanksRecap .thanksRight p.txt_thanks_transaction {
    text-transform: none; // _app override 😡
}

.thanksWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    width: 98%;
    max-width: 1440px;

    .trader_name,
    .trader_address,
    .authorization_id {
        display: none;
    }

    .seeOrderEvo,
    .thanksTrust,
    .createPwdTxt {
        @include fontStyle(13px, 18px, 300);
    }

    .seeOrderEvo {
        position: relative;
        top: 33px;
        
        .title {
            @include fontStyle(25px, 26px, 700);
            display: block;
        }

        .order {
            position: relative;
            top: -8px;
            @include fontStyle(20px, 26px, 700);
        }
    }

    .createPwdTxt {
        margin-bottom: 10px;
    }

    .bankThanksTop #around_me_map {
        height: 530px; // _app override 😡 FO
    }

    .thanksRecap {
        flex-direction: column;
        max-width: 520px;

        .thanksLeft,
        .thanksRight {
            width: 100%;

            p {
                margin-bottom: 0;
            }
        }

        .thanksLeft .seeOrderEvo {
            margin-bottom: 2.9rem;
        }
    }

    .thanksRight {
        margin-bottom: 22px;
        background-color: $primaryColor;
    }

    .thanksRightWrapper {
        @include fontStyle(13px, 18px, 300);
        padding: 21px 30px;
        text-align: left;

        br {
            display: none;
        }

        .main_amount_transaction b {
            font-weight: normal;
        }

        .number_card {
            margin-bottom: 18px !important;
        }
    }

    .thanksBottom {
        width: 100%;

        #clientForm {
            margin-bottom: 26px;
        }
    }
}

.alias_list_wrapper {
    .total_transaction {
        margin-bottom: 18px;
    }
}

.alias_list li {
    position: relative;
    margin: 0 0 15px 8px;
    margin-top: 0;
    border: 1px solid $grey;
    padding: 10px 19px 10px 40px;
    @include fontStyle(13px, 18px, 300);
    cursor: pointer;
    letter-spacing: 0.07em;
    text-transform: uppercase;

    &:before {
        position: absolute;
        top: 50%;
        left: -8px;
        transform: translateY(-50%);
        box-shadow: inset 0 0 0 10px $white;
        border: 1px solid $grey;
        border-radius: 50%;
        background-color: $black;
        width: 14px;
        height: 14px;
        content: '';
    }

    &:hover,
    &.selected {
        border-color: $black;

        &:before {
            box-shadow: inset 0 0 0 2px $white;
            border-color: $black;
        }
    }

    form {
        justify-content: flex-start;
        gap: 1rem;

        .card_logo {
            margin: 0;
            width: 50px;

            img {
                vertical-align: top;
                width: 100%;
                height: auto;
            }
        }

        .card_numbers {
            padding: 0;
        }

        .card_details {
            display: flex;
            flex: initial;
            justify-content: flex-start;
            align-items: baseline;
            padding: 0 0 0 7%;
            width: 31%;

            @media screen and(max-width: 1200px) {
                padding: 0 0 0 4%;
                width: 34%;
            }

            span:first-child {
                display: block;
                margin-right: 5px;
            }
        }

        .cardholder_name {
            display: block;
            flex: initial;
            width: 26%;
            max-width: 14em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .cvc_card {
            width: 14%;

            input {
                width: 4vw;
                background-color: #f3f3f3;
                
                &.error {
                    border: 1px solid red;
                }
            }
        }

        .btn_container {
            &:first-child{
                display: none;
            }
        }

        .btnAliasWrapper {
            height: auto;

            .use_card {
                display: none;
            }

            .delete {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                appearance: none;
                cursor: pointer;
                background: $white;
                margin-right: 5px;
                width: 15px;
                height: 15px;
                font-size: 0;

                span {
                    @extend .bgSprite;
                    @extend .cross;
                }
            }
        }
    }
} 

.wrapperSelectAlias {
    position: relative;
    width: fit-content;

    &.hide {
        display: none;
    }
}

#selectAliasBtn {
    &.disabled {
        opacity: 0.5;
        cursor: initial;
    }

    &.enabled {
        cursor: pointer;
    }

    &.loading + .loader {
        opacity: 1;
        z-index: 1;
    }
}

#addAliasListElem {
    position: relative;
    @include fontStyle(13px, 18px, 300);
    cursor: pointer;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    text-decoration: underline;
    text-underline-offset: 0.2rem;
    margin-bottom: 2rem;

    &.selected {
        font-weight: 500;
    }
}