// Override from app

.w-input-element:not([type=radio]):not([type=checkbox]),
input[type=email].w-input-element,
input[type=password].w-input-element,
input[type=text].w-input-element,
textarea.w-input-element,
.w-input-label,
.w-dropdown .w-value,
.w-fieldset-label {
    font-family: $primaryFont;
}

.w-fieldset-label {
    color: $black;
    font-size: $normaltext;
    font-weight: $light;

    .colon {
        display: none;
    }
}

.w-form-line.w-has-tel {
    z-index: 1;
}

.w-form .w-checkbox-input,
.w-form .w-radio-input {
    .w-input-element {
        + .w-input-label {
            font-size: $normaltext;
            font-weight: $light;
            color: $black;

            &::before {
                width: 1.4em;
                height: 1.4em;
            }
        }
    }
}

.w-input-container+.w-input-container {
    margin-left: 15px;
}

.w-checkbox-input,
.w-radio-input {
    .w-input-element {
        & + .w-input-label {
            font-size: $input;
            color: $black;
        }
    }
}

/*** SUNCOO SPECIFIC OVERRIDES ***/

// Adapt CTA container to text size
.w-form-line.w-submit {
    width: fit-content;
}

// Handle error note, iso jonak
.w-input-group,
.elem_global {
    margin-bottom: 15px;
}

.w-form-line {
    align-items: flex-start;
    margin-bottom: 15px;
}

.w-input {
    transition: margin-bottom .3s ease-in-out;

    &.w-has-error {
        margin-bottom: 10px;

        &.w-tel-input {
            margin-bottom: 18px;
        }
    }
}

.w-input-note {
    font-family: $primaryFont;
    font-weight: $light;
    line-height: $lh_normaltext;
    color: $black;
    margin: 0;
    top: 48px;
    white-space: nowrap;
}

.w-input.w-textarea + .w-input-note.w-input-error {
    top: unset;
    bottom: -9px;
}

// "Ajouter une ligne" for address forms, iso figma
.add-complementary-line {
    margin-top: -5px;
    margin-bottom: 20px;
}

.w-checkbox-input input[name="otherLine"],
.w-checkbox-input input[name="billotherLine"] {
    & + .w-input-label {
        font-size: $normaltext;
        font-weight: $light;
        text-transform: uppercase;
        padding-left: 12px;
        position: relative;

        &::before {
            content: '+';
            position: absolute;
            left: 0;
            top: 0;
            background-color: transparent;
            box-shadow: none;
            border: none;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $black;
            bottom: -5px;
            left: 0;
        }

        span {
            margin-left: 0;
        }
    }
    &:checked + .w-input-label {
        &::before {
            content: '-';
            margin-left: 2px;
            background-color: transparent;
            box-shadow: none;
        }
    }
}

// Add an address form : "my address does not exist" mention
.w-form .w-address-input .noaddr {
    font-family: $primaryFont;
    font-size: $input;
    font-weight: $light;
}

// Reorganise info form mail/pass row
.w-form-line.mail-pass {
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 30px;

    .w-input-container {
        width: calc(50% - (15px / 2));
        margin-left: 0;
    }
}

// Uncenter error message on login page
.newlogin_page .w-newlogin-form fieldset {
    .optin_container .w-input-note.w-input-error {
        top: 25px;
        text-align: left;
    }

    +.intro {
        @include fontStyle(13px,18px,300,$grey);
    }
} 

// Handle specific cases on contact page
.contact_faq .w-contact-form {
    fieldset .w-input-error {
        top: 18px;
    }

    .file-input-row {
        display: none;
    }

    .w-lines-group {
        margin-bottom: 18px;
    }

    #recaptcha {
        margin-bottom: 15px;
    }
}

// Remove unnecessary icons
.w-form .img_error,
.w-form .valid_form {
    display: none;
}

// Update dropdown arrows
.w-dropdown::after,
.date_select::after {
    content: '';
    display: block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: $white url(../img/compte-arrow.jpg) no-repeat center;
    pointer-events: none;
}

.date_select select {
    background: none !important;
}