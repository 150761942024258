.wrapper_price {
    display: flex;
    flex-direction: row-reverse;

    .bloc_price {
        display: flex;
        flex-direction: row-reverse;
    }

    .pricetag + .pricetag {
        color: $grey;
        margin-right: 5px;
    }
}

.pricetag {
    font-family: $primaryFont;
    color: $black;
    position: relative;
    font-size: $normaltext;
    font-weight: 500;

    + .pricetag {
        color: $grey;
        margin-right: 5px;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: $grey;
            position: absolute;
            margin-top: 1px;
            top: 50%;
            left: 0;
        }
    }
}

.cents.no_cents{
    display: none!important;
}