.leftPart {
    display: flex;
    gap: 0;

    .menu_wrapper {
        &:hover {
            .categ_container {
                opacity: 1;
                display: flex;
                z-index: 100;
                justify-content: space-between;
                height: auto;
                pointer-events: all;
            }
        }

        &#menu_link_lamarque {
            cursor: default;
        }
    }
}

.subcat_name h3 {
    @include fontStyle(14px, 30px, 300, var(--color, $white));
    text-transform: uppercase;
    text-decoration: underline;
    text-underline-offset: 8px;
}

.subcat_wrapper li:last-child {
    margin-bottom: 15px;
}

.object_link {
    position: relative;
    transition: all 0.3s ease-in-out;
    color: var(--color, $black);

    &:before {
        content: " ";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background: var(--color, $black);
        opacity: 0;
        transform: scaleX(0);
        transform-origin: left;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        &:before {
            transform: scaleX(1);
            opacity: 1;
            background: var(--hovercolor, $black);
        }
    }

    span {
        @include fontStyle(14px, 30px, 300, inherit);
    }
}

.categ_container {
    opacity: 0;
    display: flex;
    position: absolute;
    z-index: -1;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    background-color: $white;
    border-top: 1px solid $black;
    overflow: hidden;
    transition: 0.3s opacity ease-in-out;
    pointer-events: none;

    .menu_img_push {
        margin: 31px auto;
        max-width: 200px;
        width: 100%;

        &:not(:last-child) {
            margin: 31px 20px 31px 0;
        }
    }

    .subcat_link {
        pointer-events: none;
    }
}

.categ_push_wrapper {
    display: flex;
    max-width: 1440px;
    width: 98%;
    margin: 0 auto;

    .categ_wrapper {
        display: grid;
        grid-template-columns: repeat(var(--data-cols), auto);
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        height: fit-content;
        margin-left: 0;
        padding-top: 20px;

        &:not(:has(li)) {
            padding-top: 0;
        }

        >li {
            width: max-content;
            box-sizing: border-box;

            &:not(:last-child) {
                margin-right: clamp(20px, fs-vw(105, 1920), 105px);
            }
        }

        .subcat_name {
            margin-bottom: 4px;
        }
    }
}

.categ_name {

    &:hover,
    &.is-clicked,
    &.current,
    &.is_promoted {
        h2 {
            font-weight: 700;
        }
    }

    .categ_link,
    h2 {
        display: block;
        cursor: pointer;
    }

    // Using clamp on categories names cause trouble on menu hover
    h2 {
        @include fontStyle(16px, unset, 400, var(--color, $white));
        text-transform: uppercase;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        transition: all 0.3s ease;

        &::after {
            display: block;
            content: attr(data-title);
            height: 0;
            visibility: hidden;
            overflow: hidden;
            user-select: none;
            pointer-events: none;
            font-weight: 700;
        }
    }
}

.menu_push_link {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: calc(255/200 * 100%);
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .push_text {
        white-space: nowrap;
    }
}
