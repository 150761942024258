footer {
    position: relative;
    padding-bottom: 57px;
    margin: 80px auto 0;
    background-color: $white;

    .footer_container,
    #mention_legales_popup {
        padding-top: 32px;
        border-top: 1px solid $black;
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;

        @media screen and (max-width: 1024px){
            max-width: 960px;
        }
        
        @media screen and (min-width: 1025px) and (max-width: 1439px) {
            width: 98%;
        }
    }

    #mention_legales_popup {
        border-top: none;
    }

    .footer_content_wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin: 0 auto;
    }

    .legalMentions {
        position: absolute;
        right: 0;
        bottom: 0;
        @include text13;
    }

    .wrap_copy {
        position: relative;
        @include fontStyle(13px,18px,300);
        width: fit-content;
        margin: 0 0 0 auto;
    }

    .copyr {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .logo {
            position: relative;
            top: 2px;
            left: 2px;
        }
    }

    .link_popup {
        @include fontStyle(13px, 18px, 300);
    }
}

.footer_content {
    &.left {
        .wrap_links {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-right: 50px;

        @media screen and (min-width: 1025px){
            padding-right: 100px;
        }

        @media screen and (min-width: 1441px){
            padding-right: 120px;
        }

        &:last-child {
            padding-right: 31.5px;

            @media screen and (min-width: 1025px){
                padding-right: 87.5px;
            }

            @media screen and (min-width: 1441px){
                padding-right: 127.5px;
            }
        }
    }

    &.right {
        max-width: 481px;
        width: 100%;
    }
    
    .w-newsletter-form {
        width: 100%;
        margin-left: 3px;
        margin-bottom: 90px;

        @media screen and (max-width: 1440px){
            margin-left: 0;
        }

        .w-form-line {
            + .w-submit {
                width: unset;

                .w-submit-button {
                    width: 60px;
                    border-top-right-radius: 5px !important;
                    border-bottom-right-radius: 5px !important;
                }
            }

            .w-email-input.w-has-error {
                input {
                    box-shadow: 0 0 0 1px $red;
                }
            }

            input[type=email].w-input-element {
                border-radius: 5px;

                &:focus {
                    box-shadow: 0 0 0 1px $red;
                    border-color: $red;
                }

                +.w-input-label span {
                    font-size: 12px;
                    line-height: normal;
                }
            }
        }
    }

    .col_title {
        @include text14;
        line-height: 25px;
        text-transform: uppercase;
        cursor: default
    }

    .contact_links {
        @include text14;
        margin-top: 0.4rem;

        a {
            color: inherit;
        }

        .contact_link_mail {
            display: block;
            margin-bottom: 15px;
        }
    }

    .col_links,
    .col_txt {
        position: relative;
        @include text14;
        line-height: 30px;
        transition: all 0.3s ease-in-out;
        white-space: nowrap;


        &:before {
            content: " ";
            position: absolute;
            bottom: 6px;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background: $black;
            opacity: 0;
            transform: scaleX(0);
            transform-origin: left;
            transition: all 0.3s ease-in-out;
        }
    }

    .col_links {
        &:hover {
            &:before {
                transform: scaleX(1);
                opacity: 1;
            }
        }
    }

    .col_txt {
        margin-bottom: 20px;
        line-height: 1;
        cursor: default;

        .contact_link_sav {
            display: block;
            margin-top: 7px;
        }
    }

    .social_media {
        display: flex;
        align-items: center;
    }

    .social_media_picto {
        margin-right: 13px;
        $socials: facebook 1, instagram 2, pinterest 3;

        @each $social, $index in $socials {
            &.#{$social}::before {
                content: "";
                display: block;
                width: 24px;
                height: 24px;
                mask-image: url('../svg/spritesheet.svg');
                @include maskIconCoord($index, 6, 6);
                background-color: $black;
            }
        }

        // special case for tiktok icon
        &.tiktok::before {
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            @include bgIconPath('../svg/spritesheet.svg');
            @include bgIconCoord(4, 6, 6);
        }
    }

    .title {
        position: relative;
        top: -1px;
        left: 3px;
        @include fontStyle(clamp(20px,fs-vw(25,1920),25px),26px,700);
        margin-bottom: 18px; 

        @media screen and (min-width: 1440px){
               font-size: 25px;
        }
    }
}

.reinsurance {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    max-width: 988px;
    width: 100%;
    margin: 0 auto 33px;

    @media screen and (max-width: 1024px){
        max-width: 848px;
    }

    @media screen and (min-width: 1440px){
        max-width: 1010px;
        padding-left: 20px;
    }
}

.reinsurance_content_title {
    @include fontStyle(15px, 22px, 700);
}

.reinsurance_content_text {
    @include fontStyle(14px, 17px, 300);
    line-height: 22px;
}

.reinsurance_phone {
    color: inherit;
}

#tarification_load {
    margin-right: 15px;

    @media screen and (max-width: 1440px){
        display: none;
    }
}

.custom-select-footer {
    position: relative;

    .select-selected {
        @include fontStyle(13px, 25px, 300);
        text-transform: none;
        letter-spacing: 0;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        &::before {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            @include bgIconPath('../svg/spritesheet.svg');
            @include bgIconCoord(6, 6, 6);
            transform: rotate(90deg);
            margin-left: 7px;
        }
    }

    .select-items {
        position: absolute;
        background-color: #ffffff;
        border: solid 1px #e4e0dd;
        top: auto;
        left: 0;
        right: 0;
        bottom: 20px;
        z-index: 99;
        width: 150px;
        max-height: 200px;
        overflow: auto;

        &>div {
            font-size: 11px;
            line-height: 24px;
            color: $black;
            text-decoration: none;
            font-weight: 400;
            text-transform: none;
            margin-bottom: 0;
            letter-spacing: 0;
            padding-left: 11px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }

            &.same-as-selected {
                background-color: rgba(0, 0, 0, 0.2);
            }
        }
    }
}

/* The container must be positioned relative: */
.custom-select {
    position: relative;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: $black;
}

/*hide original SELECT element: */
.custom-select select {
    display: none;
}

.select-selected {
    background-color: transparent;
}

/* Style the arrow inside the select element: */
.select-selected:after {
    display: none;
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
}

/* Style items (options): */
.select-items {
    position: absolute;
    background-color: #ffffff;
    top: 20px;
    left: 0;
    right: 0;
    z-index: 6;
    width: 115px;
    border: solid 1px #e4e0dd;

    &>div {
        font-size: 11px;
        line-height: 16px;
        color: $black;
        text-decoration: none;
        font-weight: 400;
        text-transform: none;
        margin-bottom: 0;
        letter-spacing: 0;
        padding-left: 11px;
    }
}

/* Hide the items when the select box is closed: */
.select-hide {
    display: none;
}

.select-items div:hover {
    background-color: rgba(0, 0, 0, 0.0275);
}

.same-as-selected {
    background-color: rgba(0, 0, 0, 0.025);
}