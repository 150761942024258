/******************************************************************************/
/* SITE COLORS */
/******************************************************************************/
$white            : #FFFFFF;
$black            : #090E10;

$red              : #ff0000;
$green            : #008000;
$rgba             : rgba(49, 40, 39, 0.5);

$grey             : #BBBBBB;
$greyTxt          : #767676;
$fadeGreyline     : #e4e0dd;

$primaryColor     : #FBFAFA;
$secondaryColor   : #3C3C3C;
$txtcolor         : $black;
$borderColor      : $primaryColor;
$borderError      : $red;
$upsellColor      : #EEEEEEEE;
$upsellBgColor    : $grey;