/**
* General lightbox styles
*/
.lightbox {
    display: none;
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 62px 85px 48px;

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.44);
    }

    // closing cross 
    .close_pop {
        position: absolute;
        z-index: 5;
        top: 15px;
        right: 15px;
        background-color: transparent;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        font-size: 0;
        @include bgIconPath('../svg/spritesheet.svg');
        @include bgIconCoord(7, $initialColSize, $initialRowSize);
        width: 18px;
        height: 18px;
        display: block;

        span {
            display: none;
        }

        &:hover {
            opacity: 0.75;
        }
    }

    // Content
    .lightbox_content {
        padding: 3em 2em;
    }

    &.sidebox {
        width: 300px;
        right: -300px;
        top: 0;
        bottom: 0;
        left: unset;
        padding: 25px;
        max-height: 100vh;
        max-width: 90vw;
        transform: none;

        &.large {
            width: 600px;
            right: -600px;
        }

        .bgSprite.bfr:before {
            display: none;
        }
    }

    // _app override 😡
    &#selection-offer {
        max-width: 300px;
        width: 100%;
        height: 100vh;
        padding-top: 22px;

        .selection-offer-header {
            text-align: start;

            .selection-offer-title {
                @include fontStyle(20px, 26px, 700);
                width: 70%;
                margin: 0 0 2px 0;

                +p {
                    display: none;
                }
            }

            p span#nb_prod_selection_offerte {
                font-size: 13px;
            }
        }

        .selection-offer-submit {
            position: absolute;
            bottom: 30px;
            max-width: 250px;

            .button {
                &.loading {
                    background: $white url(../svg/three_dots.svg) no-repeat center/auto 20%;

                    span {
                        font-size: 0;
                    }
                }
            }
        }

        .priceOfferedProd {
            @include fontStyle(14px, 26px, 500);
            display: block !important; // _app override 😡

            .offeredLabel {
                display: block;
            }

            .crossedPrice {
                display: none;
            }

            .cents {
                display: none;
            }
        }

        .wrapperVisuOfferedProd {
            max-width: 80px;

            span {
                display: none;
            }
        }

        .selection-offer-content {
            margin: 0;
        }

        .selection-offer-content #wrap_lion {
            flex-direction: column;

            .prevColour,
            .nextColour,
            .prevSize,
            .nextSize {
                display: none;
            }

            .titleOfferedProd {
                @include fontStyle(14px, 26px, 700);
                margin: 0 0 0 18px;

                span {
                    display: none;
                }
            }

            .size_list {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .swiper-wrapper {
                    overflow: hidden;
                    overflow-x: auto;
                    width: 75%;
                }
            }

            .form_itm.size {
                position: relative;
                cursor: pointer;

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    opacity: 0;
                }

                &:not(:last-child) {
                    margin-right: 10px;
                }

                &.selected {
                    span {
                        color: $white;
                        background-color: $black;
                    }
                }

                &.disabled {
                    span {
                        color: $grey;
                    }
                }
            }

            .color_list,
            .size_list {
                width: 100%;
                margin-left: 18px;
                padding: 0;

                .swiper-wrapper {
                    &::-webkit-scrollbar {
                        position: absolute;
                        bottom: -10px;
                        height: 4px;
                        background: transparent;
                    }

                    &::-webkit-scrollbar-track {
                        background: transparent;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: $grey;
                        border-radius: 10px;
                    }
                }

                input[type=radio] {
                    &+label {
                        margin: 0;

                        &::before,
                        &::after {
                            display: none;
                        }
                    }
                }

                span {
                    @include fontStyle(15px, 37px, 300);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 25px;
                    height: 25px;
                    background-color: $primaryColor;
                }
            }

            .quatity_info {
                @include fontStyle(13px, 18px, 300);
                width: 100%;
                margin-left: 20px;
            }

            .form_itm.size:not(:last-child),
            .form_itm.color:not(:last-child) {
                margin-right: 6px;
            }

            .js-form-selection-offer {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr 1fr 1fr;
                gap: 0px 0px;
                grid-auto-flow: row;
                grid-template-areas:
                    "wrapperVisuOfferedProd titleOfferedProd priceOfferedProd"
                    "wrapperVisuOfferedProd color_list color_list"
                    "wrapperVisuOfferedProd size_list size_list"
                    "wrapperVisuOfferedProd quatity_info quatity_info";
                grid-area: js-form-selection-offer;

                min-height: unset;
                padding: 0;
                width: 250px;
                border-color: transparent;

                &.actif {
                    border-color: $black;
                }

                &:before {
                    display: none;
                }
            }
        }

        .wrapperVisuOfferedProd {
            grid-area: wrapperVisuOfferedProd;
        }

        .titleOfferedProd {
            grid-area: titleOfferedProd;
        }

        .priceOfferedProd {
            grid-area: priceOfferedProd;
        }

        .color_list {
            grid-area: color_list;
        }

        .size_list {
            grid-area: size_list;
        }

        .quatity_info {
            grid-area: quatity_info;
        }
    }

    &#abox {
        align-items: flex-start;
        justify-content: center;
        text-align: center;
        transform: translate(-50%, -50%);
        z-index: 101;

        .box_wishlist {
            margin-bottom: 35px;
        }

        .light_title {
            font-family: $secondaryFont;
            font-size: $title2;
            line-height: $lh_title2;
            font-weight: $bold;
            color: $black;
            margin-bottom: 10px;
        }

        .txt_alert {
            @include fontStyle(13px, 18px, 300);
        }

        p {
            font-size: $normaltext;
            font-weight: $light;
            line-height: $lh_normaltext;
            color: $black;
            margin: 0;
        }

        .wrap_btn_abox {
            display: flex;
            align-items: center;
            justify-content: center;

            .btn_container {
                width: 100%;
                max-width: 233px;
                height: 57px;

                &.btn_cnx {
                    margin-left: 21px;
                }
            }
        }
    }

    &#sendwishlistbox {
        padding: 60px 82px 48px;
        width: 100%;
        max-width: 650px;

        .popup_title {
            .title {
                font-family: $secondaryFont;
                font-size: $title2;
                color: $black;
                font-weight: $bold;
                margin-bottom: 25px;
            }

            .subtitle {
                display: none;
            }
        }

        #wrapper_sendwishlist {
            #sendfriend_form_to {
                .w-form-line:nth-child(2) {
                    label {
                        margin-bottom: 10px;
                    }
                }
            }

            #sendfriend_form_froms {
                .w-form-line:nth-child(2) {
                    label.w-has-error {
                        margin-bottom: 10px;
                    }
                }
            }

            .popup_title,
            .form_title {
                font-family: $secondaryFont;
                font-size: $title3;
                color: $black;
                text-align: left;
                font-weight: $bold;
                margin-bottom: 10px;
            }

            #sendfriend_form_froms {
                margin: 25px 0 20px;

                .w-form-line:nth-of-type(2) {
                    margin-bottom: 20px;
                }
            }

            .w-btn-wrapper {
                width: auto;
            }
        }

        #sendfriend_alert_mail {
            margin-bottom: 25px;

            * {
                // Override hard styles from APP templates
                font-family: $primaryFont !important;
                font-size: $normaltext !important;
                color: $black !important;
                text-align: left !important;
                line-height: $lh_normaltext;

                &p {
                    font-weight: $light !important;
                }
            }
        }

        .w-form-line.w-submit {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &#modboxpromo {
        width: 500px;
        height: 150px;
        text-align: center;
        padding: 0;

        .texte {
            @include fontStyle(13px, 18px, 300);
            margin: 35px 0 10px;
        }

        #btns_panier {
            max-width: 150px;
            margin: 0 auto;
        }

        #btn_cmd_valid {
            width: 100%;
        }
    }

    &.popup_numero_retour {
        color: $black;

        .texte_num_retour {
            text-align: left;
            font-family: $primaryFont;
            margin-bottom: 20px;
            font-size: $title2;
            font-weight: $bold;
        }

        .recommandation {
            text-align: left;

            p {
                font-size: $normaltext;
                line-height: $lh_normaltext;
                margin-bottom: 20px;
            }
        }

        .holder_form {
            width: 39rem;
            margin: auto;

            .divBtnPrintPdf {
                position: relative;
            }
        }

        .holder_return_product {
            margin-bottom: 2rem;

            &.none {
                opacity: 0.5;
                pointer-events: none;
            }

            .warning-return-not-available {
                font-size: 13px;
                line-height: 18px;
                margin-bottom: 0.5rem;
            }

            .productReturnListWrapper {
                width: 100%;
                display: flex;
                background-color: $borderColor;
                margin-bottom: 20px;
                padding: 1.25rem;

                .imgWrapper {
                    margin-right: 2.5rem;
                    width: 15%;
                }

                .formWrapper {
                    display: flex;
                    flex-direction: column;
                    width: 74%;
                }
            }
        }

        .selectWrapper {
            display: flex;

            .qty_dropdown,
            .motif_dropdown {
                display: flex;
                flex-direction: column;
                font-family: $secondaryFont;
                font-size: 23px;
            }

            .qty_dropdown {
                margin-left: 6rem;
            }

            .motif_dropdown {
                margin-left: 6.5rem;

                .date_select {
                    width: 16rem;
                }
            }

            .drop_label {
                font-size: $title3;
                font-weight: $bold;
                margin-bottom: 15px;
            }

            .date_select {
                width: 5rem;
                font-weight: $light;

                select {
                    padding-right: 2rem;
                    text-overflow: ellipsis;
                    padding-left: 1rem;
                    color: $black;
                }
            }
        }

        .print-return {

            .content {
                font-size: $normaltext;
                text-align: left;
                line-height: 1.15rem;


                .commande_retour_title {
                    font-family: $secondaryFont;
                    margin-top: 3.45rem;
                    margin-bottom: 1.5rem;
                    font-size: 1.4rem;
                }

                .commande_retour_pop_texte_1,
                .commande_retour_pop_texte_2 {
                    font-weight: $bold;
                }

                .commande_retour_pop_texte_1 {
                    margin-top: 1rem;
                }

                .commande_retour_adress {
                    margin-top: 0.75rem;
                    margin-bottom: 0.75rem;
                    line-height: 1.15rem;
                }
            }

            .print-pdf>div {
                width: fit-content;
                margin: 0 auto;
                margin-top: 20px
            }

            .retour_numero {
                text-align: center;
                font-weight: $bold;
                font-size: $normaltext;
                line-height: 1rem;
            }
        }

        .divBtnPrintPdf {
            width: fit-content;
            margin: 0 auto;
        }

        .commande_retour_info {
            font-size: $normaltext;
            line-height: $lh_normaltext;
            color: $black;
            font-weight: $light;

            span {
                font-weight: $bold;
                display: block;
                margin-top: 10px;
            }
        }
    }

    &#cartSizeInfoLightbox {
        top: 380px;
        width: 650px;
        height: 300px;
        padding: 58px 85px 48px;

        .title {
            @include fontStyle(25px, 26px, 700);
            margin-bottom: 14px;
        }

        .doubt_size_txt {
            @include fontStyle(13px, 18px, 300);

            &:last-of-type {
                margin-bottom: 25px;
            }

            a {
                text-decoration: underline;
            }
        }

        .form_submit {
            width: fit-content;
            margin: 0 auto;

            .button,
            .loader {
                max-width: 245px;
                width: 100%;
                height: 39px;

                &:hover {
                    span {
                        color: $black;
                    }
                }
            }

            .button span {
                @include fontStyle(13px, 37px, 300, $white);
            }
        }
    }

    // Relay & Delivery address change lightbox
    &.delivery_lightbox {
        top: 0;
        right: 0;
        left: unset;
        bottom: 0;
        transform: none;
        max-height: 100vh;
        height: 100vh;
        width: 600px;
        max-width: 90vw;
        padding: 25px;
        transform: none;
        cursor: default;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .store_no_result {
            @include fontStyle(13px, 18px, 300);
            margin-top: 20px;
        }

        .close_pop {
            &:after {
                content: '';
                @extend .bgSprite;
                @extend .cross;
            }
        }

        .locationhint {
            display: none;
        }

        .lightbox_delivery_title {
            @include fontStyle(20px, 26px, 700);
            padding-bottom: 16px;

            &.relay {
                padding-bottom: 10px;
            }

            .relay_type {
                display: block;
            }
        }

        .title_choix_relais {
            display: none;
        }

        .wrapper_select_adr {
            width: 100%;
            height: calc(100vh - 90px);
            padding-right: 2%;
            overflow-y: auto;
        }

        #adresseForm {
            .w-form-line.w-submit {
                width: 100% !important;
                max-width: 100%;
            }

            .w-submit-button,
            button.w-submit-button {
                height: 39px;
            }
        }

        .map_canvas {
            width: 100%;
            height: 245px;
            margin: 20px 0;
        }

        .list-right {
            height: calc(100vh - 450px);
            margin-top: 20px;
            overflow-y: auto;

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: transparent;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                border-radius: 10px;
                background-color: rgba(0, 0, 0, 0.44);
            }
        }

        .details {
            width: 96%;

            tr:first-child .kp {
                padding: 7px 0 27px;
                border-top: 0;

                .btn_container {
                    top: 44%;
                }
            }

            .kp {
                position: relative;
                padding: 18px 0 26px;
                border-top: 1px solid $secondaryColor;
                width: 100%;

                .address {
                    @include fontStyle(13px, 18px, 300);
                    text-transform: lowercase;
                }
            }

            .distance {
                display: none;
            }

            .name {
                margin-bottom: 6px;
            }

            .city {
                text-transform: capitalize;
            }

            .kpname {
                @include fontStyle(14px, 18px, 300);
                letter-spacing: .07em;
                text-transform: uppercase;
                display: block;
                width: 60%;
            }

            .address,
            .phone {
                font-size: 13px;
                margin-bottom: 10px;
            }

            .current_schedule {
                font-size: 15px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-top: 5px;

                &:before {
                    content: '●';
                    position: relative;
                    font-family: sans-serif;
                    display: block;
                    margin-right: 3px;
                    width: 14px;
                    height: 15px;
                }

                &.puce_store_open {
                    &:before {
                        color: $green;
                    }

                    .mag_open_status {
                        margin-right: 5px;
                        color: $green;
                    }
                }

                &.puce_store_closed {
                    &:before {
                        color: $red;
                    }
                }
            }

            .relayHours {
                display: none;
            }

            .btn_container {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }

            .relayLink {
                display: flex;
                justify-content: center;
                align-items: center;
                width: fit-content;
                height: 39px;
                width: 100%;
                padding: 0 1.2em;
                border: 1px solid $black;
                font-family: $primaryFont;
                font-size: 13px;
                background-color: $black;
                font-weight: 400;
                color: $white;
                text-align: center;
                letter-spacing: .07em;
                text-transform: uppercase;
                transition: background-color .3s ease-in-out;

                &:hover {
                    background-color: $white;
                    color: $black;
                }
            }
        }

        .infos {
            @include fontStyle(13px, 18px, 300);
        }

        .full_name {
            text-transform: capitalize;
            font-weight: 700;
        }

        .modlink {
            @include fontStyle(13px, 18px, 300);
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        .addresses_recap {
            height: calc(100vh - 90px);

            .address {
                position: relative;

                &:first-of-type {
                    border-top: 1px solid $black;
                }

                &:not(:last-of-type) {
                    border-bottom: 1px solid $black;
                    padding: 14px 22px 23px;

                    .full_address {
                        margin-bottom: 4px;
                    }
                }

                &:hover {
                    background-color: $primaryColor;
                }

                &:last-of-type {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 39px;
                    width: 210px;
                    background-color: $black;
                    color: $white;
                    border: 1px solid $black;
                    margin-top: 15px;

                    &:hover {
                        background-color: $white;

                        .infos {
                            color: $black;
                        }
                    }

                    .infos {
                        @include fontStyle(13px, 18px, 300, $white);
                        letter-spacing: .07em;
                        text-transform: uppercase;
                        padding: 0;
                    }
                }

                &.processing:after {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: 5;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(231, 226, 222, .5);
                    background-image: url(../svg/three_dots.svg);
                    background-repeat: no-repeat;
                    background-size: auto 20%;
                    background-position: center;
                }

                .add_addr {
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;

                    &.loading {
                        background: $black url(../svg/three_dots_w.svg) no-repeat center/auto 20%;
                    }
                }
            }

            .arrow {
                position: absolute;
                right: 0;
                top: 40%;
                height: fit-content;
            }
        }

        .info_popup {
            .name {
                @include fontStyle(14px, 17px, 300);
                text-transform: uppercase;
            }

            table {
                margin-bottom: 10px;
            }

            .details {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }

            .adresse,
            .horaire_title,
            td {
                @include fontStyle(13px, 18px, 300);
            }

            .relayLink {
                font-family: $primaryFont;
                font-size: 0.9em;
                font-weight: 400;
                color: $black;
                text-align: center;
                letter-spacing: .07em;
                text-decoration: underline;
                text-transform: none;
                width: 100%;
                padding: 0;
                justify-content: flex-start;
                background-color: transparent;
                border: none;

                &:hover {
                    text-decoration: none;
                }
            }

            .indication + p {
                @include fontStyle(13px, 18px);
            }
        }
    }

    &#exit_overlay_OVI {
        padding: 0;
        width: 750px;

        .content_newspopup {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .pop_contents {
            display: flex;
            height: 100%;
        }

        .wrapper_img {
            width: 50%;
        }

        img {
            vertical-align: top;
        }

        .wrapper_newspopup_home {
            width: 50%;
            display: flex;
            align-items: flex-end;
        }

        .blockpop {
            flex-direction: column;
            justify-content: flex-end;
            padding: 40px 45px 0;
        }

        .w-adwords-form {
            width: 100%;
        }

        .w-form-line {
            margin-bottom: 0;

            &:first-child {
                margin-bottom: 10px;
            }
        }

        .w-input-label {
            cursor: text;
        }

        #formu_inscription_news .w-form-line.w-submit {
            width: 100%;
            max-width: unset;

            .w-submit-button {
                width: 100%;
            }
        }

        .title_popup_inscription_home {
            font-size: $title2;
            line-height: $lh_title2;
            font-weight: $bold;
            color: $black;
            margin-bottom: 8px;
        }

        .pop_txt_listing {
            font-size: $normaltext;
            line-height: $lh_normaltext;
            font-weight: $light;
            color: $black;
            margin-bottom: 15px;
        }

        .pop_cgv_listing {
            margin-top: 47px;
            text-align: center;
            text-decoration: underline;
        }

        #conf_inscription_news {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
        }
    }

    // Lookbook page achat express
    &#lightbox_achat_express {
        display: block;
        max-width: 840px;
        width: 100%;
        max-height: 598px;
        height: 100%;
        padding: 0;
        transition: all 0.3s;

        #breadcrumbs {
            display: none;
        }

        .disabledBtnTooltip {
            top: 73%;
            @include fontStyle(10px, 10px, 500);
            letter-spacing: 1.5px;
            text-transform: uppercase;
            border: solid 1px $black;

            &:before {
                border: solid $black;
                border-width: 0 1px 1px 0;
                bottom: 78%;
            }
        }

        .go_to_fp {
            position: relative;
            padding-bottom: 27px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            a {
                position: relative;
                @include fontStyle(13px, 26px, 300);
                text-transform: uppercase;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: fit-content;
                height: 100%;
                cursor: pointer;

                &:before {
                    content: " ";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: $black;
                    opacity: 1;
                    transform: scaleX(1);
                    transform-origin: left;
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    &:before {
                        transform: scaleX(0);
                        opacity: 0;
                    }
                }
            }
        }
    }

    // FP SIZE GUIDE + TAKEN FROM OLD SUNCOO + MODIFIED 
    &#sizeguidebox {
        align-items: flex-start;
        justify-content: center;
        text-align: center;
        width: 63%;
        max-width: 1200px;
        min-width: 962px;
        height: 89.845%;
        max-height: 690px;
        padding: 31px 19px 29px 24px;
        overflow-y: hidden;
        top: 53.85%;

        .close_pop {
            top: 13px;
            right: 13px;
        }

        .guide_intro {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            text-align: center;

            .title {
                @include wshopFont($primaryFont, 25px, 26px, unset, $bold, $black);
                margin-bottom: 7px;
            }

            .title_2 {
                text-transform: uppercase;
                margin-bottom: 19px;
                @include wshopFont($primaryFont, 14px, 16px, unset, $light, $black);
            }

            .descrip {
                @include wshopFont($primaryFont, 13px, 18px, unset, $light, $black);
                text-align: center;
                margin-bottom: 26px;
            }
        }

        .tablesize {
            width: 100%;
            text-align: center;
            font-size: 12px;
            line-height: 10px;
            color: $black;

            tr {
                border-bottom: solid 1px $grey;

                &:last-child {
                    border-bottom: 0 none;
                }

                th {
                    padding: 4px 4px 9px;
                    border-right: solid 1px $grey;
                    width: 140.5px;
                    @include wshopFont($primaryFont, 13px, 16px, unset, $bold, $black);

                    sub {
                        @include wshopFont($primaryFont, 13px, 16px, unset, $bold, $black);
                    }

                    &:first-child {
                        max-width: 150.25px;
                        width: 18.845%;
                        position: relative;
                        top: 9px;
                        left: 1px;
                    }

                    &:last-child {
                        border-right: 0 none;
                    }
                }

                td {
                    padding: 4.5px;
                    border-right: solid 1px $grey;
                    @include wshopFont($primaryFont, 14px, 26px, unset, $light, $black);

                    &:last-child {
                        border-right: 0 none;
                    }

                    &.bg_colored {
                        font-weight: 600;

                        .smaller_font {
                            font-size: 11px;
                        }
                    }
                }
            }

            .bg_colored {
                background: $primaryColor;
            }

            .measure {
                width: 48px;
                padding: 0px !important;
                border-bottom: 1px solid $white;
                @include wshopFont($primaryFont, 14px, 26px, unset, $light, $black);
                vertical-align: middle;
            }

            .lang {
                width: 89px;
                padding: 0px !important;
                @include wshopFont($primaryFont, 13px, 26px, unset, $bold, $black);
            }
        }

        .guide_wrapper {
            margin-bottom: 2.2rem;
        }

        .guide_wrapper_2 {
            width: 59%;
            margin: 0 auto;

            .tablesize {
                tr {
                    &:nth-last-child(-n+3) {
                        border: none;
                    }
                }

                td {
                    width: 96px;

                    &:first-child {
                        width: 108px;
                    }
                }

                td.bornone_1 {
                    border-bottom: 1px solid $white;
                }

                td.bornone_2 {
                    border-right: 1px solid $white !important;
                }

                td.regtan {
                    border: 1px solid $grey;
                    line-height: 14px;
                    padding: 10px 6px 12px;
                }
            }
        }
    }
}

/**
Add/Edit Address lightbox
*/
#lightboxCustomerAddress {
    .delivery_section_title {
        font-size: $title3;
        font-weight: $bold;
        margin-bottom: 20px;
        color: $black;
    }

    #adresseForm {
        padding-bottom: 0;

        .w-has-tel .errormsgadd {
            font-size: $normaltext;
            color: $grey;
        }

        .notice {
            color: $black;
            font-size: $normaltext;
            font-weight: $light;
        }
    }
}

/**
Cookies
*/
#cookiesLightbox {
    padding: 13px 0 6px;

    .cookiesLightbox_container {
        position: relative;
    }

    .cookiesLightbox_content {
        @include fontStyle(13px, 18px, 300);

        a {
            text-underline-position: inherit;
        }
    }

    .cookies_title {
        position: relative;
        top: -3px;
        @include fontStyle(20px, 26px, 700);
    }

    .h4_style {
        color: $black;
        font-family: $primaryFont;
    }

    .cookieBlocInfo {
        @include fontStyle(13px, 18px, 300);
    }

    section,
    .cookiesLightbox_actions_wrapper {
        max-width: 1820px;
        width: 98%;
        margin: 0 auto;
    }

    .cookiesLightbox_actions_wrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }

    .cookiesLightbox_actions_left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .cookiesLightbox_actions_right {
        position: relative;
        bottom: 14px;
    }

    .cookiesLightbox_accept_btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    #jsCookieCustom,
    #js_cookie_refuse {
        background-color: $white;
        border: none;
        padding: 0;
        margin: 0;

        &::after {
            display: none;
        }

        span {
            @include fontStyle(13px, 26px, 300);
            text-transform: uppercase;
            text-decoration: underline;
            text-underline-position: under;
        }

        +.w-loader.loading {
            background: $white url(../svg/three_dots.svg) no-repeat center/auto 20%;
            border: none;
        }
    }

    #js_cookie_accept {
        &.w-submit-button,
        + .w-loader {
            width: 170px;
            height: 39px;

            &:hover {
                span {
                    color: $black;
                }
            }
        }

        span {
            @include fontStyle(13px, 26px, 300, $white);
        }
    }

    .w-btn-wrapper:not(.cookiesLightbox_accept_btn) {
        width: fit-content;

        +.w-loader {
            display: none;
        }

        +.w-btn-wrapper {
            margin-left: 2.55rem;
        }
    }
}

/*
* Lightbox for specific product offered
*/
.cart {
    .lightbox {
        &#lightbox_achat_express {
            display: block;
            max-width: 300px;
            width: 100%;
            max-height: unset;
            height: 100vh;
            top: 0;
            left: unset;
            right: -300px;
            transform: none;
            padding: 22px 25px 25px;
            transition: all 0.3s ease-in-out;

            &.actif {
                right: 0;
            }

            // All extra neted elements are due to the factwe are overriding product page styles
            .product_cms_bloc,
            .product_association,
            .page_produit .top_page_produit .product_info_wrapper #addToWishlistButtonContainer,
            .page_produit .top_page_produit .product_info_wrapper .wrapper_name_price .itm_details #itm_subtitle,
            .page_produit .top_page_produit .product_info_wrapper #product_selects .block_fitizzy {
                display: none;
            }

            .page_produit {
                .top_page_produit {
                    padding: 1rem 0;

                    .product_info_wrapper {
                        .wrapper_name_price {
                            margin-bottom: 8px;
                            flex-wrap: nowrap;
                            align-items: center;
                            padding-top: 0;

                            .price_container {
                                margin-left: 18px;

                                .priceOfferedProd,
                                .free_price {
                                    @include fontStyle(14px, 26px, 500);
                                }
                            }

                            .itm_details {
                                width: fit-content;
                                min-width: unset;

                                #itm_name {
                                    @include fontStyle(14px, 26px, 700);
                                }
                            }
                        }

                        #product_selects {
                            .productColorFieldset {
                                margin-bottom: 8px;
                            }

                            .size_qnt {
                                width: 100%;
                            }

                            #ligne_pointure {
                                .form_itm.check.size {
                                    &.disabled {
                                        pointer-events: none;
                                    }

                                    label {
                                        width: 25px;
                                        height: 25px;

                                        span.size_icon {
                                            margin-top: 0;
                                        }
                                    }
                                }

                                .choices_list {
                                    flex-wrap: wrap;
                                    gap: 6px;
                                    width: 100%;
                                }
                            }
                        }

                        #bloc_add_basket {
                            #btn_add_cart,
                            #btn_add_cart+.loader {
                                width: 250px;
                            }
                        }

                        #product_addcart {
                            top: 12px;
                            left: -102px;
                        }
                    }
                }

                &.achat_express {
                    .product_info_wrapper {
                        width: calc(100% - 80px);
                        padding: 0 0 0 22px;

                        .product_info {
                            margin-top: 0;
                        }

                        .product_main_wrapper {
                            max-width: 80px;
                        }
                    }

                    .itm_views {
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

// Fairly made poppin
#popup-fairlymade {
    width: 416px;

    #popup_fairlymade_wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        #fairlymade_header {
            margin-top: 9px;
            margin-bottom: 9px;

            .fairlymade_header_title {
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
            }
        }

        .popup-content {
            display: block;
            flex-grow: 1;

            iframe{
                width: 100%;
                height: 100%;
                border: none;
            }
        }
    }
}