/*
Search
*/
.fullpage-loader {
    width: 100%;
    min-height: 70vh;
    background: $white url(../svg/three_dots.svg) no-repeat center;
    background-size: 5em;
}

.dynasearchwrapper {
    .no_result_title {
        @include fontStyle(20px,unset,700);
        margin-bottom: 13px;

        li {
            list-style: square;
            margin-left: 15px;
        }
    }

    .no_results_txt_container {
        max-width: 1440px;
        width: 98%;
        margin: 5px auto 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 47px 55px;
        background-color: #FBFAFA;

        span:last-child,
        li {
            @include fontStyle(13px, 18px, 300);
        }
    }
    
    .search_tips {
        position: relative;
        left: 9px;
        
        .tips_title,
        .tip {
            list-style: square;
            margin-left: 15px;
        }
    }
    
    .title_search,
    .searchTitle {
        display: none;
    }

    .total_articles {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 22px auto 12px;

        .searchTitle {
            @include fontStyle(40px,40px,700);
            text-transform: uppercase;
        }

        .nbResults {
            display: none;
        }
    }

    .sub_categ_wrapper {
        display: none;
    }
}

#searchComponent:has(.fullpage-loader) {
    padding-top: 150px;
}

#bottom_search_loader {
    width: fit-content;
    opacity: 0;

    img {
        width: 5em;
        height: auto;
    }
}