/*________________________________ Header ____________________________________*/

$preheaderHeight: 35px;
$headerHeight: 125px;
$headerHeightSticky: 70px;
$headerFull: calc(#{$headerHeight} + #{$preheaderHeight});
$headerFullSearch: 280px;


/*________________________________ Text ____________________________________*/
/* fs : font size */
/* lh : line height */
$fsContent: 13px;
$lhContent: 37px;

/*________________________________ Buttons style ____________________________________*/

$fsCta: $fsContent; // Font size
$lhCta: $lhContent; // Line height

$ctaHeight: 39px;
$ctaWidth: 250px;

/*________________________________ Primary ____________________________________*/

$primaryCtaColor                : $white;    // Color
$primaryCtaBgColor              : $black;    // Background color
$primaryCtaBorderColor          : $black;    // Border color

/*________________________________ Hover ____________________________________*/

$primaryCtaColorHover           : $black;    // Color
$primaryCtaBgColorHover         : $white;           // Background color
$primaryCtaBorderColorHover     : $black;    // Border color

/*________________________________ Secondary ____________________________________*/

$secondaryCtaColor              : $black;    // Color
$secondaryCtaBgColor            : $white;           // Background color
$secondaryCtaBorderColor        : $black;    // Border color

/*________________________________ Hover ____________________________________*/

$secondaryCtaColorHover         : $white;    // Color
$secondaryCtaBgColorHover       : $black;           // Background color
$secondaryCtaBorderColorHover   : $black;    // Border color