// Upsell TG
body {
    &.product_page,
    &.cart,
    .search_tg {
        .product_association {
            width: 100%;
            max-width: 1920px;
            margin-bottom: 2.65rem;

            .assocTitle {
                display: block;
                @include wshopFont($primaryFont, 25px, 26px, unset, 700, $black);
                text-align: center;
                margin-bottom: 24.5px;
            }
        }

        #assoc_product_slider {
            opacity: 0;
            width: 100%;
            overflow: hidden;

            .item {
                max-width: 456px !important;
            }

            &:not(.swiper-initialized) {
                .assoc-swiper-wrapper {
                    display: flex;
                    justify-content: center;
                    column-gap: 20px;
                }

                .swiper-slide {
                    width: 100% !important;
                    max-width: 456px !important;
                }
            }

            .description_couleur {
                display: flex;
                justify-content: center;
                padding-top: 6px;

                .wrapper_description {
                    padding-right: 13px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    .pricetag {
                        line-height: 1.3;
                    }
                }

                .wrap_rolloverproduit {
                    padding: 10px 0 0;
                    justify-content: flex-start;

                    input.input_check {
                        display: none;
                    }
                }
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .product_cms_bloc {
            .four-images-module {
                margin-bottom: 38px;
            }

            .home-module.seo-wording {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        .item .productSizeFieldset:has(.size_list),
        .push .productSizeFieldset:has(.size_list) {
            top: calc(100% - 130px);
        }
    }
}

.page_produit {
    display: flex;
    flex-wrap: wrap;

    .top_page_produit {
        display: flex;
        width: 100%;
        max-width: 1820px;
        column-gap: clamp(8.5px, fs-vw(62, 1920), 62px);
        margin: 0 clamp(10px, fs-vw(50, 1920), 50px) 74.5px;
        opacity: 0;    
        transition: opacity 0.15s ease-in-out; 

        // FP images
        .product_main_wrapper {
            width: 100%;
            max-width: 1208px;

            .mainSlideFp {
                position: relative;

                .swiper-wrapper {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    row-gap: clamp(8.5px, fs-vw(20, 1920), 20px);

                    @media only screen and (max-width: 1440px) {
                        row-gap: clamp(8.5px, fs-vw(20, 2400), 20px);
                    }

                    @media only screen and (max-width: 1366px) {
                        row-gap: clamp(8px, fs-vw(20, 2475), 20px);
                    }

                    @media only screen and (max-width: 1280px) {
                        row-gap: clamp(7px, fs-vw(20, 2560), 20px);
                    }

                    @media only screen and (max-width: 1024px) {
                        row-gap: clamp(6px, fs-vw(20, 2850), 20px);
                    }

                    .swiper-slide {
                        width: 49.1722%;
                        max-width: 594px;
                        height: auto;
                        cursor: zoom-in;

                        &.slide_video {
                            display: flex;
                            position: relative;
                            height: 0;
                            padding-bottom: 73.7591%;

                            iframe {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        &.first-position {
                            order: -1;
                        }

                        .fake_img {
                            position: absolute;
                            display: block;
                            width: 100%;
                            height: 100%;
                            z-index: 2;
                            cursor: url('../img/zoom.png'), auto;
                        }
                    }

                    img {
                        width: 100%;
                        height: auto;
                        cursor: url('../img/zoom.png'), auto;
                    }
                }

                .eclat1,
                .eclat2 {
                    position: absolute;
                    top: 15px;
                    z-index: 2;

                    img {
                        display: inline-block;
                        width: auto !important;
                        max-height: 25px;
                        vertical-align: top;
                    }
                }

                .eclat1 {
                    right: 15px;
                }

                .eclat2 {
                    left: calc((100% - 49.1722%) + 15px);
                }
            }

            .thumbsFpContainer {
                display: none;
            }
        }

        // FP with only one image
        &.onlyOneImage {
            justify-content: center;

            .product_main_wrapper {
                max-width: 594px;

                .mainSlideFp {
                    .eclat2 {
                        left: 15px;
                    }
                    
                    .swiper-slide {
                        width: 100%;
                    }
                }
            }
        }

        //FP Descriptions
        .product_info_wrapper {
            width: 100%;
            max-width: 550px;
            padding-top: 10px;

            // Wishlist
            #addToWishlistButtonContainer {
                display: flex;
                justify-content: flex-end;
                width: 100%;

                #addToWishlistButton {
                    display: block;
                    width: 28px;
                    height: 28px;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        &::before {
                            @include bgIconCoord(14, 6, 6);
                        }
                    }

                    &:before {
                        @include bgIconCoord(13, 6, 6);
                        width: 28px;
                        height: 28px;
                        position: relative;
                        top: 7px;
                        left: 3px;
                    }

                    &.existToWishlistButton {
                        &:before {
                            @include bgIconCoord(14, 6, 6);
                        }
                    }
                }
            }

            // Price
            .wrapper_name_price {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 17.5px;

                .itm_details {
                    width: 80%;
                    min-width: 100px;

                    #itm_name {
                        @include wshopFont($primaryFont, 18px, 22px, unset, 700, $black);
                        margin-bottom: 2px;
                    }

                    #itm_subtitle {
                        @include wshopFont($primaryFont, 18px, 1, unset, 300, $black);
                    }
                }

                .price_container {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    width: 20%;
                    @include wshopFont($primaryFont, 16px, 26px, unset, 500, $black);

                    .price {
                        text-align: right;
                        line-height: 1;
                    }

                    &:has(.remise) .price.vente {
                        @include wshopFont($primaryFont, 14px, 1, unset, 500, $grey);
                        text-decoration: underline;
                        text-underline-offset: -5px;
                        text-decoration-skip-ink: none;
                        margin-right: 10px;
                        line-height: 1.1;
                    }

                    .ecoPart:empty {
                        display: none;
                    }
                }
            }

            // Color
            #product_selects {
                display: flex;
                flex-wrap: wrap;

                .productColorFieldset {
                    width: 100%;
                    margin-bottom: 21.5px;

                    .choices_list {
                        display: flex;
                        column-gap: 5px;

                        .form_itm.check.color {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            border: 1px solid $grey;

                            .preview_color {
                                display: block;
                                width: 14px;
                                height: 14px;
                                border-radius: 50%;
                                margin: unset;

                                &:before,
                                &:after {
                                    content: unset;
                                    display: none;
                                }
                            }

                            &:has(.input_check:checked) {
                                border: 1px solid $black;
                                transition: border 0.25s ease-in-out;
                            }
                        }
                    }
                }

                #ligne_pointure {
                    margin-bottom: 20px;

                    .choices_list {
                        display: flex;
                        column-gap: 10px;
                    }

                    .form_itm.check.size {
                        width: 30px;
                        height: 30px;
                        background: $primaryColor;

                        label {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 30px;
                            height: 30px;

                            &:before,
                            &:after {
                                content: unset;
                                display: none;
                            }

                            span.size_icon {
                                text-align: center;
                                @include wshopFont($primaryFont, 15px, 15px, unset, 300, $black);
                                text-transform: uppercase;
                                margin-top: -3px;
                            }
                        }

                        &:has(.input_check:checked) {
                            background: $black;
                            transition: background 0.25s ease-in-out;

                            label span.size_icon {
                                color: $white;
                                transition: color 0.25s ease-in-out;
                            }
                        }

                        &.disabled {
                            &:has(.input_check:checked) {
                                background: $grey;
                            }

                            .size_icon {
                                color: $grey;
                            }
                        }

                        &:hover {
                            transition: background-color 0.3s ease-in-out;
                            background-color: $black;

                            label span {
                                color: $white;
                            }
                        }
                    }
                }

                .size_qnt {
                    width: 68%;
                }

                .block_fitizzy {
                    width: 32%;
                    min-width: 113px;
                    margin-top: 2px;
                    text-align: right;
                    box-sizing: border-box;

                    .trigger_izzy {
                        @include wshopFont($primaryFont, 10px, 1, unset, $light, $black);
                        text-transform: uppercase;
                        position: relative;
                        white-space: nowrap;

                        &:after {
                            content: "";
                            width: 100%;
                            height: 1px;
                            background-color: $black;
                            position: absolute;
                            bottom: -4px;
                            left: 0;
                        }
                    }
                }
            }

            form.product_info {
                position: relative;
            }

            #size_is_not_available,
            .taunt_text {
                display: block;
                width: 185px;
                @include wshopFont($primaryFont, 9px, 1, unset, 600, $grey);
                text-align: center;
                text-transform: uppercase;
                margin-top: -22px;
                margin-bottom: 13px;
            }

            // Btn add to Basket
            #bloc_add_basket {
                &.disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }

                #btn_add_cart {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 185px;
                    height: 39px;
                    padding: unset;
                    background: $black;
                    @include wshopFont($primaryFont, 13px, 1, unset, 300, $white);
                    text-transform: uppercase;

                    &+.loader {
                        width: 185px;
                        height: 39px;
                    }

                    &.noSize {
                        background-color: $grey;
                        border-color: $grey;
                    }
                }
            }

            #bloc_add_alert {
                background-color: $white;
                position: relative;
                z-index: 1;

                #bloc_add_alert_form {
                    display: flex;
                    flex-wrap: wrap;
                    height: 48px;
                    width: 256px;
                    background: $white;
                    position: relative;
                    top: -4px;
                    margin-bottom: -9px;

                    .w-input-element {
                        &:focus {
                            box-shadow: none;
                            border: 1px solid $grey;
                        }

                        &.inputErr {
                            border: 1px solid $red;
                        }
                    }

                    input {
                        padding: 0 0.9rem;

                        &::placeholder {
                            @include wshopFont($primaryFont, 14px, 1, unset, 300, $grey);
                            font-style: normal;
                        }
                    }
                }

                .bloc_add_alert_confirmation,
                .bloc_add_alert_erreur {
                    position: absolute;
                    top: -5px;
                    left: 0;
                    width: 100%;
                    background: $white;

                    .confirmation_msg,
                    p {
                        @include fontStyle(14px, 19px, 300);
                        text-align: left;
                        padding-bottom: 1rem;
                    }
                }

                .bloc_add_alert_confirmation {
                    #closeBtn {
                        position: absolute;
                        top: 0;
                        right: 0;
                        cursor: pointer;

                        &:after {
                            content: " ";
                            display: block;
                            width: 13px;
                            height: 13px;
                            @include maskIconPath('../svg/spritesheet.svg');
                            @include maskIconCoord(7,6,6);
                            background-color: $black;
                        }
                    }
                }

                .form_submit {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 1px;
                    right: 1px;

                    button {
                        width: 47.82px;
                        height: 46px;
                        background-color: transparent;
                        border-radius: 0 4px 4px 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    }

                    span {
                        @include wshopFont(Arial, 11px, normal, unset, 400, $black);
                        text-align: center;
                        text-transform: uppercase;
                    }
                }

                #btn_alert_stock {
                    background-color: $white;
                    color: $black;

                    &:hover {
                        background-color: $black;
                        color: $white;
                    }
                }
            }

            .validEmailTxt {
                @include wshopFont($primaryFont, 11px, 1, unset, 400, $red);
                position: relative;
                top: 5px;
            }

            // Alma
            .avis_alma,
            .paye_3 {
                display: none;
            }

            // Accordion Description Produit
            .desc_pannel {
                .toggleCarac {
                    @include wshopFont($primaryFont, 14px, 1, unset, 400, $black);
                    text-transform: uppercase;
                    cursor: pointer;
                    padding: 17px 0 18px;
                    border-top: 1px solid $black;
                    position: relative;

                    &:last-of-type {
                        border-bottom: 1px solid $black;
                    }

                    &:after {
                        content: " ";
                        display: block;
                        @include maskIconPath('../svg/spritesheet.svg');
                        @include maskIconCoord(6, 6, 6);
                        background-color: $black;
                        width: 13px;
                        height: 13px;
                        position: absolute;
                        top: 42%;
                        right: 0;
                        transform: rotate(90deg);
                    }

                    &.actif {
                        border-bottom: none;
                        font-weight: 700;

                        &:after {
                            transform: rotate(-90deg);
                            transition: rotate 0.25s ease-in-out;
                        }
                    }
                }

                .content {
                    display: none;
                    @include wshopFont($primaryFont, 13px, 18px, unset, 300, $black);
                    margin-bottom: 21px;

                    &.actif {
                        display: block;
                    }
                    
                    &#traceabilityContent {
                        #fairlymade_wording_clickable {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }

                    .product_ref {
                        margin-top: 1.1rem;
                    }

                    #delivered_date {
                        margin-top: 18px;
                    }
                }

                ul {
                    list-style: disc;
                    margin-left: 20px;

                    li {
                        position: relative;

                        &::marker {
                            font-size: 0;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            top: 8px;
                            left: -10px;
                            display: inline-block;
                            width: 3px;
                            height: 3px;
                            background: $black;
                            border-radius: 100%;
                        }
                    }
                }

                #conseilTailleContent {
                    .block_sizeguide {
                        margin-top: 1.1rem;

                        a {
                            text-decoration: underline;
                        }
                    }
                }
            }

            #product_addcart {
                position: relative;
                margin-bottom: 29px;
            }
        }
    }

    .disabledBtnTooltip {
        display: none !important;
    }
}

body.product_page {
    #site_global_wrap {
        width: 100%;
        max-width: 1920px;
        min-width: 1024px;
        margin: 6px auto 0;
    }

    .product_cms_bloc {
        width: 100%;
    }

    #breadcrumbs {
        .breadcrumbs {
            padding: 0 clamp(10px, fs-vw(50, 1920), 50px) 15px;
            width: 100%;
            max-width: 100%;

            a {
                text-transform: lowercase;
                display: block;

                &::first-letter {
                    text-transform: uppercase;
                }
            }
        }
    }

    .footer {
        margin-top: 75px;
    }

    #zoomBox {
        width: 1127px;
        height: 100vh;
        max-height: 100vh;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        padding: 0;

        .close_pop {
            display: none;
        }

        #zoom_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1127px;
            max-width: 1440px;
            height: 100vh;
            margin: 0 auto;
            background: $white;
            transition: opacity 0.3s linear;

            .swiper-slide {
                text-align: center;
                overflow: scroll; /* Enables scrolling */
                scrollbar-width: none; /* For Firefox */
                -ms-overflow-style: none;  /* For Internet Explorer and Edge */

                &::-webkit-scrollbar {
                    display: none; /* For Chrome, Safari, and Opera */
                }

                img {
                    position: relative;
                    width: 100%;
                    max-width: 1127px;
                    cursor: url('../img/zoom_out.png'), auto;
                }

                .fake_img {
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    cursor: url('../img/zoom_out.png'), auto;
                }

                &:has(iframe) {
                    display: flex;
                    width: 100%;
                    height: 0;
                    position: relative;
                    padding-bottom: 100%;

                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .zoomProdNext,
        .zoomProdPrev {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            transform: translateY(-50%) rotate(0deg);
            outline: none;
            cursor: pointer;
            position: absolute;
            right: 4px;
            top: 52.2%;
            z-index: 2;

            &:before {
                content: " ";
                display: block;
                @include maskIconPath('../svg/spritesheet.svg');
                @include maskIconCoord(6, 6, 6);
                background-color: $black;
                width: 12px;
                height: 12px;
            }

            &.swiper-button-disabled {
                opacity: 0.25;
                cursor: auto;
            }
        }

        .zoomProdNext {
            transform: translateY(-50%) rotate(180deg);
            right: unset;
            left: 4px;
        }
    }

    .item,
    .push {
        .btnAddBasketWrapper {
            bottom: 50px;
        }
        
        .productSizeFieldset:has(.size_list) {
            top: calc(100% - 130px);
        }
    }

    .go_to_fp {
        display: none;
    }

    #alertbox1 {
        .txt_alert {
            margin-bottom: 25px;
        }
    }
}
