@import '../../_app/_src/scss/desktop/mixins/typeFace';

$primaryFont: 'acumin-pro', sans-serif;
$secondaryFont: $primaryFont;
$tertiaryFont: $primaryFont;

//FONT WEIGHTS
$thin: 100;
$extralight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;

//FONT SIZES
$title1: 40px;
$title2: 25px;
$title3: 20px;
$price: 14px;
$subtitle: 14px;
$input: 14px;
$normaltext: 13px;
$smalltext: 11px;

//LINE HEIGHTS
$lh_title1: 40px;
$lh_title2: 26px;
$lh_title3: 26px;
$lh_price: 26px;
$lh_subtitle: 17px;
$lh_normaltext: 18px;
$lh_smalltext: 11px;
$lh_menu: 30px;

// Text title 40px
@mixin title40 {
    font-family: $primaryFont;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: $black;
}

// Text title 25px
@mixin title25 {
    font-family: $primaryFont;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    color: $black;
}

// Text title 20px
@mixin title20 {
    font-family: $primaryFont;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    color: $black;
}

// Text title 16px
@mixin title16 {
    font-family: $primaryFont;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    color: $black;
}

// Text title 15px
@mixin title15 {
    font-family: $primaryFont;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: $black;
}

// Text font 14px
@mixin text14 {
    font-family: $primaryFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    color: $black;
}

// Text font 13px
@mixin text13 {
    font-family: $primaryFont;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    color: $black;
}

@mixin fontStyle($fontSize, $lineHeight, $fontWeight: 700, $color: $black) {
    font-family: $primaryFont;
    font-size: $fontSize;
    font-style: normal;
    font-weight: $fontWeight;
    line-height: $lineHeight;
    color: $color;
}

/*
Application on a div with class .test
.test {
    @include fontStyle(14px, 17px, 300, $white);
}
means: font-size: 14px; line-height: 17px; font-weight: 300; color: $white;
*/