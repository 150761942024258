// General styles for all home modules
.home-module {
    max-width: 1920px;
    min-width: 990px;
    width: 100%;
    margin: 0 auto 40px;

    @media screen and (max-width: 1440px){
        margin: 0 auto 50px;
    }

    a {
        color: inherit;
    }

    img {
        vertical-align: top;
    }

    .slide_video,
    .slide_video iframe {
        width: 100%;
        height: 100%;
    }

    .home-module-cta {
        font-size: $normaltext;
        font-weight: $light;
        text-transform: uppercase;
        border-bottom: none !important;
        padding-bottom: 4px;
        white-space: nowrap;
        position: relative;

        &:after {
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: currentColor;
            margin-top: 1px;
            opacity: 0;
            transform: scaleX(0);
            transform-origin: left;
            transition: all .3s ease-in-out;
            content: '';
        }

        &:hover:after {
            transform: scaleX(1);
            opacity: 1;
        }
    }
}

// Cover module with 2 images
.cover-module-2-images {
    display: flex;
    margin-bottom: 20px;

    .home-column {
        position: relative;
        width: 50%;
        height: 50vw;
        max-height: 960px;
        min-height: 495px;

        &:hover .home-module-cta:after {
            transform: scaleX(1);
            opacity: 1;
        }
    }

    img {
        width: 100%;
        height: 100%;
    }

    .home-module-link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .home-module-title {
        font-size: clamp(30px, fs-vw(40,1920), 40px);
        font-weight: $bold;
        margin: 10px auto;
        text-align: center;
    }

    .home-module-text {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        pointer-events: none;

        .home-module-cta {
            padding-bottom: 2px;
            pointer-events: all;
        }
    }
}

// Cover module with 1 image
.cover-module {
    margin-bottom: 20px;

    .home-module-body {
        position: relative;
        font-size: $normaltext;
        font-weight: $light;
    }

    .home-columns-container {
        height: 50vw;
        max-height: 960px;
        min-height: 495px;
    }

    .home-column {
        height: 100%;
    }

    img {
        width: 100%;
        height: 100%;
    }

    .home-module-contents {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &.align-left {
            left: 28%;
        }

        &.align-right {
            left: 72%;
        }
    }

    .home-module-link:hover ~ .home-module-contents .home-module-cta:after {
        transform: scaleX(1);
        opacity: 1;
    }

    .home-module-link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    .home-module-title {
        font-size: clamp(30px, fs-vw(40,1920), 40px);
        font-weight: $bold;
        margin: 10px auto;
        text-align: center;
        white-space: nowrap;
    }

    .home-module-cta {
        margin-top: 6px;
    }
}

// TG module
.push-product-module {
    margin-top: 20px;

    .homeGondoleSwiper {
        padding: 0 19px;

        .item {
            max-width: 456px !important;
        }
    }

    .swiper-homeGondole-button {
        display: none;
    }

    .item {
        margin-bottom: 20px;
    }

    .title_colors_container {
        width: 80%;
    }

    .swiper-button-prev {
        transform: rotate(180deg);
        left: -6px;
    }

    .swiper-button-next {
        right: -6px;
    }

    .home-module-cta {
        display: block;
        width: fit-content;
        margin: 0 auto 5px;
    }
}

// Four images module
.four-images-module {
    margin-bottom: 20px;
    
    .home-module-body {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        width: 100%;
    }

    .home-module-item {
        position: relative;
        width: 100%;
    }

    .img-link:hover ~ .home-module-cta:after {
        transform: scaleX(1);
        opacity: 1;
    }

    .img-link,
    .img-container,
    .slide_video {
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: calc(600/465 * 100%);
        overflow: hidden;
    }

    img,
    .slide_video iframe {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .home-module-title {
        position: absolute;
        top: 49%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: clamp(18px, fs-vw(25,1920), 25px);
        font-weight: $bold;
        pointer-events: none;
        text-align: center;
        white-space: nowrap;
    }

    .home-module-cta {
        position: absolute;
        bottom: 42px;
        left: 50%;
        transform: translateX(-50%);
    }
}

// Store module
.stores-module {
    padding: 40px 30px;

    @media screen and (max-width: 1441px){
        padding: 0 20px;
    }

    @media screen and (max-width: 1024px){
        padding: 0 30px;
    }

    .home-module-body {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .home-module-item-container {
        width: 100%;
        max-width: 400px;
    }

    .home-module-item,
    .slide_video {
        position: relative;
        width: 100%;
        padding-bottom: 125%;
        height: 0;
        overflow: hidden;

        .text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            width: 90%;
        }
    }

    img,
    .slide_video iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }

    .home-module-title {
        margin-bottom: 20px;
        font-size: $title2;
        width: 100%;
    }

    .home-module-cta {
        padding-bottom: 1px;
    }

    .home-module-wrapper-link {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;

        &:hover + .text .home-module-cta:after {
            transform: scaleX(1);
            opacity: 1;
        }
    }
}

// Social module
.social-module {
    .home-module-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 50px;

        @media screen and (min-width: 1025px) and (max-width: 1440px){
            padding: 0 30px;
        }
    }

    .home-module-pretitle {
        font-size: $subtitle;
        font-weight: $light;
        margin-bottom: 25px;
        text-transform: uppercase;
    }

    .social-media-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        width: 100%;

        @media screen and (min-width: 1441px){
            gap: 20px;
        }
    }

    .social-media-post {
        width: 100%;
    }

    .insta-link {
        display: block;
        height: 0;
        overflow: hidden;
        width: 100%;
        padding-bottom: calc(350 / 287 * 100%);
        position: relative;
    }

    img, video {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    @media screen and (max-width: 1300px){
        .social-media-post:nth-child(5),
        .social-media-post:nth-child(6) {
            display: none;
        }
    }
}

// SEO wording module
.seo-wording {
    padding: 40px 0;

    @media screen and (max-width: 1024px){
        padding: 0;
    }

    .home-module-body {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
    }

    .image-column {
        height: 500px;
        width: 400px;
        overflow: hidden;
    }

    .text-column {
        width: 410px;
        padding-right: 60px;
    }

    img {
        width: 100%;
    }

    .home-module-title {
        font-size: $title2;
        margin-bottom: 15px;
    }

    .home-module-text {
        font-size: $normaltext;
        line-height: $lh_normaltext;
        font-weight: $light;
        margin-bottom: 20px;
    }
}

// Pre-existing modules
.image-image {
    width: 100%;
    max-width: 1280px;

    .ill_img {
        display: block;
        position: relative;
        width: 100%;
        padding-top: 100%;

        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 100%;
            height: auto;
            max-height: 100%;
        }
    }

    .home-columns-container {
        display: flex;
        align-items: stretch;
    }

    .home-column {
        width: 100%;

        &:first-of-type {
            img {
                width: 100%;
                height: auto;
            }

            .ill_img {
                width: 100%;
                padding-top: 760 / 640 * 100%;
            }
        } 

        &:last-of-type {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            img {
                max-width: 70%;
                height: auto;
            }

            .ill_img {
                width: 400 / 640 * 100%;
                padding-top: 520 / 400 * (400 / 640 * 100%);
            }
        } 
    }
    
    .home-module-pretitle{
        font-size: $normaltext;
        font-weight: $light;
        margin: auto;
        text-transform: uppercase;
    }

    .home-module-cta {
        margin: auto;
    }
}

.image-full {
    width: 100vw;
    height: 1015 / 1920 * 100vw;
    max-width: 1920px;
    max-height: 1015px;
    position: relative;

    img {
        width: 100%;
        height: auto;
    }

    .home-module-contents {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        &.align-left {
            left: 28%;
        }

        &.align-right {
            left: 72%;
        }
    }

    .home-module-title {
        font-size: clamp(30px, fs-vw(40,1920), 40px);
        font-weight: $bold;
        margin: 10px auto;
        text-align: center;
        white-space: nowrap;
    }

    .home-module-pretitle {
        font-size: $normaltext;
        text-align: center;
        text-transform: uppercase;
    }

    .home-module-cta {
        margin-top: 6px;
    }
}

.text-image {
    width: 100%;
    max-width: 1280px;

    .home-columns-container {
        display: flex;
        align-items: stretch;
    }

    .home-module-title {
        font-size: clamp(30px, fs-vw(40,1920), 40px);
        font-weight: $bold;
        margin-bottom: 20px;
    }

    .home-module-text {
        font-size: $normaltext;
        line-height: $lh_normaltext;
        font-weight: $light;
        margin-bottom: 40px;
    }

    .ill_img {
        display: block;
        position: relative;
        width: 100%;
        padding-top: 100%;

        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 100%;
            height: auto;
            max-height: 100%;
        }
    }

    .home-column:first-of-type {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 60 / 1280 * 100%;
        box-sizing: border-box;
        width: 50%;
    }

    .home-column:last-of-type {
        width: 50%;

        img {
            width: 100%;
            height: auto;
        }
    }
    
    .home-module-pretitle {
        font-size: $smalltext;
        font-weight: $light;
        margin-bottom: auto;
        text-transform: uppercase;
    }
}

.signup-module {
    width: 100%;
    padding: 40px 0;

    .home-module-body {
        padding: 0 10px;
    }

    .home-columns-container {
        display: flex;
        align-items: stretch;
        max-width: 990px;
        margin: auto;
    }

    .home-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 100%;
    }

    .image-column {
        align-items: flex-end;
        padding-right: 25px;
        width: fit-content;

        &+ .text-column {
            align-items: flex-start;
            justify-content: center;
            padding-left: 25px;
        }
    }

    .home-module-title {
        line-height: $lh_title1;
        margin-bottom: 15px;
        font-size: $title1;
        font-weight: $bold;
    }

    .home-module-text {
        font-size: $normaltext;
        font-weight: $light;
        margin-bottom: 20px;
        line-height: $lh_normaltext;
    }

    .w-newsletter-form {
        width: 75%;
        max-width: 480px;

        .w-form-line:first-child {
            margin-bottom: 10px;
        }

        .w-form-line.w-submit {
            width: fit-content;
            position: static;

            .w-submit-button {
                width: 220px;
            }
        }

        input[type=email].w-input-element {
            padding-right: 0;
            border-radius: 5px;
        }
    }

    .w-input.w-has-addon {
        display: flex;

        .w-input-element {
            width: 100%;
            height: 6em;
            padding: 0 2em;
            border: 1px solid $fadeGreyline;
            border-radius: 0;
            font-size: 0.75rem;

            + .w-input-label {
                left: 2em;
                margin-left: 0;
                font-size: 0.75rem;
                color: inherit;
                opacity: 1;
                z-index: 10;
                transition: opacity 0.349s linear 0.001s,
                    z-index 0.001s linear;
            }

            &::placeholder {
                font-family: $primaryFont;
                font-style: normal;
                font-size: 0.75rem;
                color: $txtcolor;
            }

            &:focus {
                border: 1px solid $fadeGreyline;
            }

            &:focus + .w-input-label,
            + .w-input-label.w-floating-label {
                top: 50%;
                font-size: 0.75rem;
                color: inherit;
                opacity: 0;
                z-index: -10;
                transition: opacity 0.349s linear,
                    z-index 0.001s linear 0.349s;
            }

            &:before,
            &:after {
                display: none !important;
            }
        }

        .w-button-addon {
            display: block;
            width: 9.8em;
            height: 7.2em;
            max-height: none;
            text-align: center;
            background: transparent;
            padding: 0;
            border: 1px solid $fadeGreyline;
            border-left: none;
            font-family: $primaryFont;
            font-weight: 500;
            font-size: 0.625rem;
            text-transform: uppercase;
        }
    }

    .w-form-line:first-of-type {
        flex-direction: column;
        transition: height .3s ease-in-out;
    }

    .w-input-container + .w-input-note {
        position: static;
        margin-bottom: -8px;

        &.w-input-error {
            margin-top: 4px;
        }
    }

    .w-newsletter-form .w-form-line.w-submit .w-submit-button {
        height: 39px;
        width: 280px;
    }
}

.gamme-module {
    width: 100%;
    max-width: 1920px;

    .home-module-body {
        max-width: 100vw;
        margin: 0 auto;
    }

    .home-module-title {
        font-size: $title2;
        font-weight: $bold;
        margin-bottom: 30px;
    }

    .home-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .categ-links-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px 44px;
        width: 100%;
        max-width: 100vw;
        padding: 0 50px;
    }

    .home-module-cta {
        font-size: $subtitle;
    }
}