$initialColSize : 6;
$initialRowSize : 6;

.bgSprite {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &.bfr {
        &::before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
        }
    }

    &.aft {
        &::after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
        }
    }

    @include spriter('../svg/spritesheet.svg', $initialColSize, $initialRowSize, (1: 'rsFacebook',
            2: 'rsInstagram',
            3: 'rsPinterest',
            4: 'rsTiktok',
            5: 'search',
            6: 'arrow',
            7: 'cross',
            8: 'cart',
            9: 'cartFill',
            10: 'account',
            11: 'accountFill',
            12: 'hyphen',
            13: 'heart',
            14: 'heartFill',
            15: 'store',
            16: 'delivery',
            17: 'storelocator',
            18: 'paypal',
            19: 'google',
            20: 'sav',
            21: 'cartHover',
            22: 'accountHover',
            23: 'searchHover',
            24: 'heartHover',
            25: 'rsFacebookBlue',
        ));
}