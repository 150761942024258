.cart {
    .product_sfs_warning_txt {
        display: none;
    }
    
    .main_ariane {
        padding-top: 9px;

        .breadcrumb {
            +.breadcrumb {
                font-weight: 700;

                a::after {
                    display: none;
                }
            }
        }
    }

    &.step_1 {
        .tunnel_track_pusher {
            display: none;
        }

        #cart_total {
            .used_cp {
                order: 4;
            }

            .formPanier_container {
                padding-bottom: 20px;
            }

            .bill_line {
                &.total {
                    padding-bottom: 11px;
                }
            
                &.coupon {
                    padding-bottom: 20px;
                }
            }
        }
        
        .left_side {
            max-width: 825px;
            width: 57.3%;

            @media screen and (max-width:1024px) {
                width: 55.65%;
            }

            .basket_contents.cart_main_table {
                max-width: 800px;
            }

            .wrapper_cart_product_price {
                display: flex;
                flex-direction: row-reverse;
            }
        }
    }

    .nb_products,
    .precoSubtitle,
    .dragOverlay,
    .dragHandle,
    .cart_product_brand,
    .cart_product_subtitle {
        display: none;
    }

    .cart_main_title {
        @include fontStyle (25px, 26px, 700);
        padding: 11px 0 5px;

        &.wishlist {
            margin-bottom: 0;
        }
    }

    .dropdown_cart {
        margin-bottom: 22px;
    }

    .precoDeliveryDate {
        @include fontStyle (13px, 18px, 300);
    }

    .empty_cart {
        display: none;
    }

    .left_side {
        max-width: 800px;
        width: 55.65%;
    }

    // wishlist side
    .cart_main_table:not(.basket_contents) {
        &.wishlist {
            display: flex !important;
            align-items: flex-start;
            justify-content: flex-start;
            column-gap: 25px;
            flex-wrap: wrap;
            margin-bottom: 20px;

            .cart_product_line {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                width: calc((100% / 3) - 25px);
                max-width: 250px;
                margin-right: 0;
                margin-bottom: 15px;
                padding-bottom: 0;

                &.hasNoStock {
                    .cart_product_pic,
                    .wishlist_title_price_wrapper,
                    .cart_product_sizecol {
                        opacity: 0.5;
                    }
                }

                @media only screen and (max-width: 1580px) {
                    width: calc((100% / 3) - 17px);
                }

                @media only screen and (max-width: 1460px) {
                    width: calc((100% / 2) - 13px);
                    max-width: 100%;
                }

                @media only screen and (max-width: 1080px) {
                    width: 100%;
                }
            }

            .wishToBasket {
                span {
                    border-bottom: 1px solid $black;
                }

                &.processing {
                    span {
                        border-color: $white;
                    }
                }
            }

            .wishToBasket,
            .cart_product_modify form {
                display: inline-block;
                width: auto;
                height: auto;
                color: $black;
                background: transparent;
                line-height: 1;
                padding: 0;
                border: none;

                .report.error {
                    position: relative;
                    top: 2px;
                    display: flex;
                    max-width: 100%;
                    font-family: $primaryFont;
                    font-weight: $light;
                    font-size: $normaltext;
                    color: $txtcolor;
                    line-height: $lh_normaltext;
                    text-transform: uppercase;

                    .button {
                        display: inline-block;
                        height: auto;
                        width: auto;
                        color: #000;
                        background: transparent;
                        line-height: 1;
                        border: none;
                        padding: 0 0 2px 0;
                        margin-right: 0;
                        border-bottom: 1px solid $black;
                    }
                }

                &.processing {
                    background: $white url(../svg/three_dots.svg) no-repeat center/auto 50%;
                    color: transparent;
                    cursor: default;

                    .button {
                        border-color: $white;
                    }
                }
            }

            .cart_product_modify {
                position: relative;
                display: flex;
                justify-content: flex-start;
                width: 100%;
                margin-top: 15px;

                form {
                    width: 100%;

                    &:hover {
                        border: none;
                    }
                }
            }

            .cart_product_desc {
                align-items: flex-start;
                width: calc(100% - 100px);

                .remove_wish {
                    position: absolute;
                    top: -2px;
                    right: -2px;
                    display: block;
                    height: unset;

                    span {
                        font-size: 0;
                    }

                    &.cross::before {
                        @include maskIconCoord(7, $initialColSize, $initialRowSize);
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            .wrapper_cart_product_desc {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;

                .wrap_titre_prix {
                    display: flex;
                    flex-direction: column;
                    padding-top: 2px;
                }

                .wishlist_title_price_wrapper {
                    display: flex;
                    align-items: center;
                    margin-bottom: 3px;

                    .cart_product_title {
                        display: inline-block;
                        width: auto;
                        margin-right: 20px;
                    }
                }

                .wrapper_cart_product_price {
                    position: relative;
                }
            }

            .btnAddBasketWrapper.expressAlertStock {
                width: 100%;

                .bloc_add_alert_form {
                    position: relative;

                    .w-form-line {
                        position: relative;
                        width: 100%;
                        max-width: 256px;

                        .w-input-container {
                            input[type=text].w-input-element {
                                width: 100%;
                                height: 39px;
                                border-radius: 5px;
                                padding-right: 39px;

                                &.inputErr {
                                    border-color: $borderError;
                                }

                                &::placeholder {
                                    font-family: $primaryFont;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 1;
                                }
                            }
                        }

                        .form_submit {
                            position: absolute;
                            top: 2px;
                            right: 2px;
                            height: 32px;
                            width: 39px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: #FFF;

                            button {
                                display: block;
                                width: 100%;
                                height: 100%;
                                cursor: pointer;
                            }

                            span {
                                font-size: 11px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 1;
                                text-transform: uppercase;
                            }
                        }
                    }
                }

                .alert_return {
                    display: none;
                    position: absolute;
                    right: 0px;
                    bottom: 54px;
                    background-image: url(../svg/spritesheet.svg);
                    background-size: 600% auto;
                    width: 16px;
                    height: 16px;
                    background-position: 0 20%;

                    span {
                        display: none;
                    }
                }

                .bloc_add_alert_confirmation {
                    width: calc(100% - 18px);
                    white-space: initial;

                    p {
                        text-transform: none;
                    }
                }
            }

            &:not(:has(.cart_product_line.hasStock)) {
                .wrapper_btn_top .select-all {
                    display: none;
                }
            }

            #unselectAll {
                display: none !important;
            }
        }
    }

    .article_cta_wrapper {
        .selection_infos {
            display: none;
        }

        .loader_container {
            position: relative;
            width: 250px;
        }

        .btn_add_wishlist {
            width: 250px;
        }
    }

    .tunnel_wish_nostock {
        @include fontStyle(13px, 18px, 300);
    }

    .max_stock {
        @include fontStyle(11px, 18px, 300);
        color: $red;
    }

    #abox .button {
        margin: 10px auto;
    }

    #formPromo {
        #input_codePromo {
            border: none;
            background: $white;
        }
    }

    .w-coupon-form .w-form-line.w-submit {
        top: 0;
        transform: unset;

        .w-submit-button {
            border-radius: 0 5px 5px 0 !important;
            border: 1px solid transparent;
            background: $white;
            color: $black;

            +.w-loader {
                background: url('../svg/three_dots.svg');
                background-color: $white;
                background-position: center;
                background-repeat: no-repeat;
                background-size: auto 20%;
            }

            span {
                position: relative;
                left: 7px;
                font-size: 11px;
            }
        }
    }

    .w-coupon-form .w-form-line input[type=text].w-input-element {
        border-radius: 5px;
    }

    &.step_2_0 {
        &.in_tunnel {
            footer {
                margin: 86px auto 0;
            }

            #cart_total {
                .bill_line.discount {
                    padding-bottom: 20px;
                }

                .bill_line.total {
                    padding-bottom: 16px;
                }
            }
        } 

        .tunnel_track_pusher {
            margin-bottom: 40px;
        }

        .delivery_section_title {
            @include fontStyle(14px, 17px, 300);
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        .delivery_section_subtitle {
            display: block !important; // override inline declaration on the view in App FO
            @include fontStyle(13px, 18px, 300);
            margin-bottom: 17px;
        }

        #tunnel_right_col {
            padding-top: 0;
        }

        .formPanier_container {
            display: none;
        }
    }

    .product_association {
        margin-top: 80px;
    }
}

.sameBillAddress,
.accountCreate {
    .w-checkbox-input .w-input-element {
        +.w-input-label {
            &:before {
                width: 26px;
                height: 15px;
                background-color: $grey;
                border: 0;
                border-radius: 48px;
                box-shadow: none;
                transition: none;
            }

            &:after {
                content: '';
                width: 12px;
                height: 13px;
                background-color: $white;
                border-radius: 50%;
                position: absolute;
                top: 2px;
                left: 1px;
            }

            span {
                @include fontStyle(13px, 18px, 300);
            }
        }

        &:checked+.w-input-label {
            &:before {
                background-color: $black;
            }

            &:after {
                left: 12px;
            }
        }
    }
}

/*
Right side content
*/
#tunnel_right_col {
    max-width: 425px;
    width: 44.35%;
    padding-top: 15px;
    margin-bottom: 20px;

    @media screen and (max-width:1024px) {
        width: 41.35%;
    }

    .wrap_bottom_rightcol {
        background-color: $primaryColor;
    }

    .bloc_question {
        padding: 20px 30px;

        .title_question {
            @include fontStyle(18px, 18px, 700);
            padding: 0 0 8px;
        }

        .intitule_question span {
            @include fontStyle(14px, 17px, 300);
        }

        .texte_question {
            display: none;
            @include fontStyle(13px, 18px, 300);
            margin: 10px 0 18px;
            padding: 0 15px;

            div {
                @include fontStyle(13px, 18px, 300);
                padding: 0 15px;
            }

            .questab {
                width: 100%;

                td {
                    border: 1px solid $black;
                    padding: 4px;
                }
            }

            b {
                font-weight: 500;
            }

            a {
                color: $black !important;
            }
        }

        article {
            padding-bottom: 6px;
            cursor: pointer;

            &:last-of-type {
                padding-bottom: 20px;
            }
        }

        .questions_frequentes {
            @include fontStyle(13px, 18px, 300);
            padding-top: 25px;

            .faq_main_title {
                font-weight: 500;
                text-transform: uppercase;
            }

            ul {
                padding-top: 10px;
            }

            #other_question_1 {
                padding-top: 5px;
            }
        }
    }
}

#cart_total {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    margin-bottom: 20px;
    background-color: $primaryColor;

    .recap_cart_title {
        order: 1;
        @include fontStyle (20px, 26px, 700);
        padding-top: 22px;
        padding-bottom: 35px;
    }

    .bill_line {
        @include fontStyle (13px, 18px, 300);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pricetag,
        .price_tag {
            font-size: 14px;
            font-weight: 500;
        }

        &.total_produit {
            order: 2;
            padding-bottom: 10px;
        }

        &.discount {
            order: 3;
            padding-bottom: 18px;

            &.hide_shippingfees {
                display: none;
            }
        }

        &.total {
            order: 5;
            padding-bottom: 8px;

            label {
                text-transform: uppercase;
                font-size: 14px;
            }

            .pricetag {
                font-size: 15px;
            }
        }

        &.coupon {
            order: 4;
            padding-bottom: 15px;
        }

        &.total_produit_dropdown {
            display: none;
        }
    }

    .formPanier_container {
        order: 7;
        padding-bottom: 30px;
    }

    .total_realised_economy {
        order: 6;
        height: 30px;
        padding: 0 6px;
        color: $black;
        background-color: $upsellColor;
        margin-bottom: 19px;

        .price {
            position: relative;
            bottom: 1px;
            right: 3px;
        }

        .price_tag {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &::before {
                content: "-";
                display: block;
                width: 8px;
            }
        }

        label {
            display: flex;
            align-items: center;

            &::before {
                content: ' ';
                display: block;
                background-image: url(../svg/info.svg);
                background-size: 100% auto;
                background-repeat: no-repeat;
                width: 21px;
                height: 21px;
                margin-right: 10px;
                margin-left: 4px;
            }

            span {
                position: relative;
                top: -2px;
            }
        }
    }

    #montant_txt .price {
        @include fontStyle (15px, 26px, 500);
    }

    #popup_3xcb,
    .encart_3xcb_panier {
        display: none;
    }

    .button,
    .loader.cartLoader {
        width: 100%;
    }

    &:has(.promo) {
        .promo {
            order: 4;
            padding-bottom: 3px;
        }

        .used_cp {
            order: 5;
            margin-bottom: 4px;

            +.coupon {
                display: none;
            }
        }

        .total {
            order: 6;
            padding-bottom: 16px;
        }

        .upselling {
            order: 9;
        }

        .formPanier_container {
            order: 8;
        }

        .discount {
            padding-bottom: 14px;
        }
    }

    .used_cp {
        position: relative;
        justify-content: unset;
        padding-bottom: 30px;

        span:first-of-type {
            font-weight: 600;
        }

        label {
            padding-right: 5px;
            padding-left: 19px;
        }

        .close {
            position: absolute;
            top: 2px;
            left: -2px;

            &::before {
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                @include bgIconPath("../svg/spritesheet.svg");
                @include bgIconCoord(7, 6, 6);
            }

            span {
                font-size: 0;
            }
        }
    }
}

.push_container {
    padding: 20px 30px;
    margin-bottom: 20px;
    // if there is no color set in BO
    background-color: $primaryColor;

    &.pushBasketWrapper {
        padding: 0;
    }

    p {
        @include fontStyle(11px, 14px, 300);

        &:first-of-type {
            font-size: 18px;
            line-height: 26px;
        }
    }

    img {
        width: 100%;
        height: auto;
    }
}

.wrap_bottom_container {
    @include fontStyle(11px, 14px, 300);
    margin-top: 20px;
    padding: 20px 30px;
    background-color: $primaryColor;

    .wrap_bottom_rightcol.hide {
        display: none;
    }
}

.product_line_basket {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(:last-child) {
        border-bottom: 0.5px solid $grey;
        padding-bottom: 20px;
    }

    &:last-child {
        padding-bottom: 34px;
    }

    &:not(:first-child) {
        margin-top: 20px;
    }

    .toggle_modal_actions {
        position: relative;
        @include fontStyle(13px, 26px, 300);
        width: fit-content;
        margin-top: 11px;
        text-transform: uppercase;
        cursor: pointer;
        order: 5;

        &:before {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background: $black;
            opacity: 1;
            transform: scaleX(1);
            transform-origin: left;
            transition: all 0.3s ease-in-out;
        }

        &:hover {
            &:before {
                transform: scaleX(0);
                opacity: 0;
            }
        }
    }

    .cart_item_actions {
        display: none;
        margin-top: 6px;
        width: fit-content;
        order: 6;
    }

    .wrapper_cart_product_desc {
        position: relative;
        width: calc(100% - 256px);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        &:has(.item-tu) {
            +.cart_product_modify {
                transform: translateY(18px);
            }
        }
    }

    .productSizeFieldset {
        input[type=radio] {
            &+label {
                margin: 0;

                &::before,
                &::after {
                    display: none;
                }
            }
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            color: $black;
            background-color: $primaryColor;
        }

        .size_list {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .form_itm.size {
            position: relative;

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                opacity: 0;
                cursor: pointer;
            }

            &:not(:last-child) {
                margin-right: 10px;
            }

            &.selected {
                span {
                    color: $white;
                    background-color: $black;
                }
            }

            &.disabled {
                span {
                    color: $grey;
                }
            }
        }
    }

    .wrap_titre_prix {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 3px;
        order: 0;
    }

    .product_mod {
        margin-right: 20px;
    }
}

.upselling {
    order: 8;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 20px;

    p {
        @include fontStyle(11px, 18px, 300);
        width: 100%;
        padding-top: 2px;
    }

    progress {
        -webkit-appearance: none;
        width: 100%;
        height: 5px;
        margin-bottom: 5px;
        border-radius: 10px;
        overflow: hidden;
    }

    progress::-webkit-progress-bar {
        background-color: $upsellBgColor;
    }

    progress::-webkit-progress-value {
        background-color: $upsellColor;
        border-radius: 10px;
        overflow: hidden;
    }
}

#main_cart_wrapper {
    max-width: 1440px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

/*
Delivery
*/
.step_2_0.in_tunnel,
.step_2.in_tunnel,
.checkout {
    .bill_line {
        &.total_produit_dropdown {
            order: 2;
            flex-wrap: wrap;
        }
    }

    .product_line_basket {
        border-bottom: none;
        margin-top: 0;
        padding-bottom: 10px;
    }

    .cart_product_modify {
        display: none;
    }

    .wrapper_cart_product_desc {
        width: 100%;

        .wrapper_cart_product_price {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
        }
    }

    .toggle {
        cursor: pointer;

        .price {
            display: none;
        }

        .nb_products {
            display: flex;
        }

        label {
            display: flex;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 0.9px;
        }

        &::after {
            position: absolute;
            top: 20px;
            right: 0;
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(6, $initialColSize, $initialRowSize);
            background-color: $black;
            transform: rotate(90deg);
            cursor: pointer;
            transition: all 0.3s ease-in-out;
        }

        &.open {
            &::after {
                transform: rotate(-90deg);
            }
        }
    }

    #cart_total .recap_cart_title {
        border-bottom: 1px solid $black;
        padding-bottom: 19px;
    }

    #cart_total .bill_line.total_produit {
        position: relative;
        padding-top: 16px;
        padding-bottom: 15px;

        &:not(.open) {
            +.total_produit_dropdown {
                padding-bottom: 10px;
            }
        }

        &.open {
            +.total_produit_dropdown {
                position: relative;
                top: -6px; 
                margin-bottom: 4px;
            }
        }

        &.under_dropdown {
            display: flex !important;
            padding-top: 10px;
            padding-bottom: 13px;
            border-top: 1px solid $black;

            .nb_products {
                display: none;
            }
        }
    }

    #cart_total .bill_line.discount {
        padding-bottom: 4px;
    }
}

.checkout #cart_total .formPanier_container {
    padding-bottom: 11px;
}

.step_2.in_tunnel {
    footer {
        margin: 32px auto 0;
    }

    .modlinks_wrapper {
        width: fit-content;
        cursor: pointer;
    }

    .tunnel_track_pusher {
        margin-bottom: 40px;
    }

    #tunnel_right_col {
        display: flex;
        flex-direction: column;
        padding-top: 0;
    }

    .deconnection_tunnel {
        order: 1;
    }

    #blocs_address {
        order: 2;
    }

    .push_container {
        order: 4;
    }

    .wrap_bottom_rightcol {
        order: 5;
    }

    .fdp_offered {
        @include fontStyle(14px, 26px, 500);
    }

    #cart_total {
        order: 3;

        .bill_line.discount {
            padding-bottom: 23px;
        }

        .bill_line.total {
            padding-bottom: 21px;
        }
    }

    .wrapper_delivery_choice {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 35px;
    }

    .delivery_section_title {
        @include fontStyle(14px, 17px, 300);
        text-transform: uppercase;
        display: block;
        width: 100%;
        text-align: left;
        margin-bottom: 14px;

        &.delivery_mode {
            margin-bottom: 20px;
        }
    }

    .delivery_choice_btn {
        position: relative;
        @include fontStyle(13px, 10px, 300);
        text-transform: uppercase;
        max-width: 143px;
        width: 100%;
        height: 44px;
        margin-right: 10px;
        padding: 14px 20px;
        border-radius: 5px;
        background-color: $primaryColor;
        border: 1px solid $primaryColor;
        cursor: pointer;

        &::before {
            position: absolute;
            content: "";
            display: block;
            @include maskIconPath('../svg/spritesheet.svg');
            background-color: $black;
        }

        &.relay_delivery_btn,
        &.home_delivery_btn,
        &.store_delivery_btn {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.relay_delivery_btn .delivery_choice_btn_label {
            padding-left: 20px;
        }

        &.store_delivery_btn .delivery_choice_btn_label {
            padding-left: 23px;
        }

        &.home_delivery_btn::before {
            width: 25px;
            height: 14px;
            left: 16%;
            @include maskIconCoord(16, $initialColSize, $initialRowSize);
        }

        &.relay_delivery_btn::before {
            width: 25px;
            height: 16px;
            left: 7%;
            @include maskIconCoord(17, $initialColSize, $initialRowSize);
        }

        &.store_delivery_btn::before {
            width: 25px;
            height: 17px;
            left: 14%;
            @include maskIconCoord(15, $initialColSize, $initialRowSize);
        }

        &.selected {
            background-color: $black;
            color: $white;

            &::before {
                background-color: $white;
            }
        }
    }

    .delivery_choice_btn_label {
        padding-left: 30px;
        white-space: nowrap;
    }

    .transporteur_delay,
    .shop_name,
    .adresse {
        @include fontStyle(13px, 18px, 300);
    }

    .transporteur_delay {
        margin-bottom: 14px;
    }

    .shop_name {
        font-weight: 500;
        text-transform: uppercase;
    }

    .transporteur_type,
    .transporteur_id {
        @include fontStyle(13px, 10px, 700);
    }

    .delivery_choice {
        position: relative;
        display: flex;
        display: none;
        opacity: 0;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 27px;
        padding: 0 0 31px 40px;
        cursor: pointer;

        &:hover,
        &.active {

            .checkbox {
                box-shadow: inset 0 0 0 2px $white;
                background-color: $black;
            }
        }

        &:not(:last-child) {
            border-bottom: 1px solid $black;
        }

        &.selected {
            display: flex;
            opacity: 1;
            transition: all .3s ease-in-out;
        }

        input.choix_livraison {
            position: absolute;
            top: 0;
            left: 0 !important; // override forms general style
            opacity: 0;
            z-index: 1;
            cursor: pointer;
            width: 100%;
            height: 100%;
        }

        .checkbox {
            position: absolute;
            top: 30%;
            left: 0;
            transform: translateY(-50%);
            width: 15px;
            height: 15px;
            border: 1px solid $black;
            background-color: $primaryColor;
            box-shadow: inset 0 0 0 10px $white;
            border-radius: 50%;
        }

        .price_tag {
            @include fontStyle(14px, 26px, 500);
        }

        .warning_point_relais {
            @include fontStyle(13px, 18px, 500, $red);
        }

        .show_delivery_info {
            position: relative;
            z-index: 2;
            @include fontStyle(13px, 18px, 300);
            text-decoration: underline;
        }
    }
}

.checkCgv {
    width: 100%;
    margin-bottom: 5px;

    .w-checkbox-input .w-input-element+.w-input-label {
        align-items: flex-start;

        &::before {
            width: 15px;
            height: 15px;
            box-sizing: border-box;
            border: 1px solid $black;
        }

        span {
            @include fontStyle(11px, 14px, 300);
            width: calc(100% - 23px);
            margin-left: 8px;
        }
    }
}

#blocs_address {
    .address {
        position: relative;
        padding: 17px 0 20px;
        border-top: 1px solid $black;
        cursor: pointer;

        &.bill {
            padding: 17px 0 24px;
        }
    }

    .arrow {
        position: absolute;
        top: 50%;
        right: 2%;
        transform: translateY(-50%);

        &.bgSprite.bfr::before {
            width: 12px;
            height: 12px;
        }
    }

    .addr_label,
    .society {
        @include fontStyle(13px, 10px, 700);
        margin-bottom: 4px;
    }

    .society {
        font-weight: 300;
    }

    .modlink,
    .full_address,
    .full_name {
        @include fontStyle(13px, 18px, 300);
        text-decoration: underline;
    }

    .full_address,
    .full_name {
        text-decoration: none;
        margin-bottom: 6px;
        text-transform: capitalize;
    }

    #delivery_address_recap_other {
        display: none;
    }
}

.formPanier_container {
    .form_submit.full {
        margin-top: 0;
    }
}

.deconnection_tunnel {
    position: relative;
    top: -3px;
    display: flex;
    justify-content: space-between;
    padding: 0 30px 10px;

    .button {
        background: transparent;
        border: none;
        padding: 0;
        height: unset;
    }

    p,
    span {
        @include fontStyle(13px, 18px, 300);
    }

    span {
        text-decoration: underline;
        text-transform: initial;
    }
}

.w-newuser-form-v2 {
    .w-submit-button {
        max-width: 112px;
    }

    .w-input-group:has(.w-input.w-text-input.w-has-error) {
        .delivery_section_title {
            margin-bottom: 14px;
        }
    }
}

.notice {
    @include fontStyle(13px, 18px, 300, #ff6d6d);
}

.cart_product_pic {
    width: 80px;
    margin-right: 20px;

    a {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .cart_product_img {
        width: 100%;
        height: auto;
    }
}

.cart_product_desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 80px);
}

.cart_product_title {
    @include fontStyle(14px, 26px, 700);
}

.cart_product_sizecol {
    @include fontStyle(11px, 18px, 300);
    text-transform: uppercase;
    width: fit-content;

    &.size {
        order: 3;
    }

    &.color {
        text-transform: capitalize;
        order: 2;

        label {
            text-transform: uppercase;
        }
    }

    &.quantity {
        order: 1;

        label {
            display: flex;
        }

        // fix offered product quantity display
        span {
            display: block !important;
        }
    }
}

.cart_product_modify {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 256px;
    width: 100%;

    &:has(.tunnel_wish_nostock) {
        justify-content: flex-start;
    }

    .product_towish {
        order: 1;
        margin: 0 55px;
        position: relative;

        &.processing {
            &::before {
                background: $white url('../img/loader.gif') no-repeat center/22px 22px;
                font-size: 0;
                width: 22px;
                height: 22px;
                cursor: default;
            }
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: -30px;
            width: 1px;
            height: 100%;
            background-color: $black;
            opacity: .5;
        }

        &:not(.processing):hover {
            &::before {
                @include maskIconPath('../svg/spritesheet.svg');
                @include maskIconCoord(14, $initialColSize, $initialRowSize);
                background-color: $black;
            }
        }

        span {
            font-size: 0;
        }
    }

    .product_del,
    .remove_wish {
        order: 2;

        span {
            font-size: 0;
        }

        &.cross::before {
            @include maskIconCoord(7, $initialColSize, $initialRowSize);
            width: 16px;
            height: 16px;
        }
    }

    .remove_wish {
        position: absolute;
        top: -2px;
        right: -2px;
        display: block;
        height: unset;
    }

    .qte_selector {
        position: relative;
        display: flex;
        width: 82px;
        height: 30px;

        img.load_qte {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .product_quantity_change {
        width: 100%;
        order: 0;

        .qteWrapper {
            position: relative;
            display: flex;
            width: 82px;
            height: 30px;
            border: 0.5px solid $grey;
            box-sizing: border-box;
            border-radius: 5px;

            .selected_qte {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 33.3%;
                height: 27px;
                font-size: 13px;
                text-align: center;
                border: none;
            }

            .change_qte {
                position: absolute;
                top: 0;
                appearance: none;
                border: 0;
                background: transparent;
                width: 33.3%;
                height: 28px;
                font-size: 0;
                cursor: pointer;
            }

            .lessQte {
                left: 0;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: calc(50% - 5px);
                    width: 10px;
                    height: 1px;
                    background-color: $black;
                }
            }

            .moreQte {
                right: 0;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: calc(50% - 5px);
                    width: 11px;
                    height: 1px;
                    background: $black;
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: calc(50% - 5px);
                    left: 50%;
                    width: 1px;
                    height: 11px;
                    background: $black;
                }
            }
        }

        .qte_error_msg {
            font-size: 11px;
        }
    }

    .tunnel_wish_nostock {
        display: none;
    }

    .checkbox_wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 45px;
        height: 15px;
        margin: 0 30px;
        cursor: pointer;

        .separator_checkbox {
            position: absolute;
            left: 0;
            display: block;
            width: 1px;
            height: 15px;
            background: $black;
        }
    }

    input[type="checkbox"] {
        position: absolute;
        left: auto;
        right: 0;
        width: 15px;
        height: 15px;
        opacity: 0;
        cursor: pointer;

        & + .checkbox_btn {
            display: block;
            width: 15px;
            height: 15px;
            background-color: $white;
            margin: 0;
            border: 1px solid $black;
            border-radius: 0;
            transition: background 0.35s ease-in-out;
        }

        &:checked {
            & + .checkbox_btn {
                background-color: $black;
                box-shadow: inset 0 0 0 1px $white;
            }
        }
    }

    .bloc_add_alert  {
        position: relative;
        display: inline-flex;
        width: auto;
        height: auto;
        padding: 0;
        margin: 0;

        .loader_container {
            width: 100%;
            height: auto;
            margin: 0;
        }

        button {
            display: inline-block;
            width: auto;
            height: auto;
            @include fontStyle(13px, 1, 300);
            color: $black;
            text-transform: uppercase;
            background-color: transparent;
            padding: 0 0 2px 0 !important;
            border: none;
            border-bottom: 1px solid $primaryColor;

            span {
                white-space: nowrap;
            }
        }

        #bloc_btn_loader {
            display: none !important;
        }
    }
}

.wishlist_persis_and_cta_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    .wrapper_btn_top {
        .select-all.button {
            position: relative;
            top: -5px;
            display: inline-block;
            width: auto;
            height: auto;
            color: $black;
            background: 0 0;
            line-height: 1;
            padding: 0 22px 0 0;
            border: none;
            text-align: right;

            span {
                text-decoration: underline;
                text-underline-position: under;
                white-space: nowrap;
            }
        }
    }

    &:has(.connected) {
        align-items: flex-end;

        .wrapper_btn_top {
            padding-bottom: 22px;
        }
    }
}

.wishlist_persis_info {
    @include fontStyle(13px, 18px, 300);
    display: block;
    width: 100%;
    padding-bottom: 22px;

    a {
        text-decoration: underline;
    }
}

.tunnel_track_pusher {
    margin-bottom: 45px;
}

.cart_logo {
    display: flex;
    justify-content: center;
    margin-bottom: 34px;

    .logo_img {
        width: 125px;
        height: 31px;
        background: url(../svg/logo-suncoo.svg) no-repeat center/contain;
        filter: invert(1);
    }
}

.tunnel_track {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tunnel_step {
    @include fontStyle(14px, 21px, 300);
    text-transform: uppercase;

    &.on {
        font-weight: 700;
    }

    &.valide::after {
        display: none;
    }

    &::after {
        content: ">";
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 0 14px;
    }
}

.in_tunnel {
    &.step_2_0 {
        #cart_total:has(:not(.bill_line.used_cp)) {
            .bill_line.coupon {
                padding-bottom: 0;
            }
        }

        .bill_line {
            &.total {
                padding-bottom: 20px;
            }
        }
    }

    &.step_2 {
        #cart_total:has(:not(.bill_line.used_cp)) {
            .bill_line.coupon {
                padding-bottom: 0;
            }
        }
        
        .wrap_bottom_container {
            order: 4;
        }

        #formPanier {
            position: relative;
        }

        .checkCgv {
            margin-bottom: 39px;
        }

        #err_cgv_compulsory {
            position: absolute;
            bottom: 55px;
            @include fontStyle(11px, 14px, 300, #DE1931);
            padding-left: 23px;
        }

        #delivery_home_choice {
            .lightbox_delivery_title {
                padding-bottom: 23px;
            }
        }
    }

    &.step_2,
    &.step_2_0,
    &.checkout {
        #cart_total .bill_line.used_cp {
            order: 4;
    
            label {
                padding-left: 0;
            }
        }
    }
}

img.valid_form {
    display: none;
}

// Fix relay delivery button icon position in EN
body.en.cart .left_side .relay_delivery_btn::before {
    left: 3%;
}

/* UN RIEN C'EST TOUT */
#container_urct {
    max-width: 1440px;
    width: 90%;
    margin: 22px auto 0;
    color: $black;

    /* FIRST PART */
    .first_banner {
        padding: 40px 0 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .container_txt_asso {
            width: 100%;
        }

        p {
            font-size: $normaltext;
            line-height: $lh_normaltext;
            font-weight: $light;

            &:first-of-type {
                font-size: $title3;
                font-weight: $bold;
                font-family: $secondaryFont;
                line-height: $lh_title3;
                margin-bottom: 10px;
            }
        }
    }

    /* SECOND PART */
    .second_banner {
        display: flex;
        justify-content: center;
        text-align: center;
        gap: 20px;

        form {
            width: calc(100% / 3);
            height: initial !important;
            padding: 24px 30px;
            background-color: $primaryColor;

            &:nth-child(1) .img_don {
                background: url(../img/unrien_dons/roseup.png) no-repeat center / contain;
            }
        }

        .container_don {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        .img_don {
            width: 120px;
            height: 35px;
            display: block;
            margin: 0 auto;
            margin-bottom: 12px;
        }

        .title_don {
            margin-bottom: 5px;
            text-transform: uppercase;
            font-weight: $light;
            font-size: $subtitle;
        }

        .desc_don {
            margin-bottom: 5px;
            font-size: $normaltext;
            color: $black;
            line-height: $lh_normaltext;
            font-weight: $light;
        }

        .learn_more_don {
            margin-bottom: 20px;
            font-size: $normaltext;
            color: $black;
            line-height: $lh_normaltext;
            max-height: 30px;
            font-weight: $light;
            cursor: pointer;
            text-decoration: underline;
            text-underline-position: from-font;
        }

        .btn_container {
            width: fit-content;
            margin: 0 auto;
        }

        .cta_don {
            &.disable_click {
                pointer-events: none;
            }
        }
    }

    .lightbox_don {
        text-align: left;
        padding: 3rem;
    }

    .title_don_lightbox {
        font-size: $title2;
        font-weight: $bold;
        margin-bottom: 1rem;
    }

    .desc_don_longue {
        line-height: $lh_normaltext;
        color: $black;
        font-size: $normaltext;
        font-weight: $light;
    }
}

// Hide size, colour and wishlist button for donation products
// If adding more products, do the same for each product ID in $arrayProductDonation in bloc_dons.php
[data-i='2087'] {
    .sub_couleur,
    .sub_taille {
        display: none !important;
    }
}
[data-product-id='2087'] {
    .cart_product_sizecol.color {
        display: none !important;
    }

    .product_towish {
        display: none !important;
    }
}

// Bank thanks tooltip
.bankThanks.in_tunnel {
    .gm-style {
        .gm-style-iw-c {
            border-radius: 0;
            top: 73px;
            left: -77px;
            padding: 0;
        }

        .gm-style-iw-tc::after {
            display: none;
        }

        .gm-style-iw-d {
            padding: 11px 38px 0;

            .infoWindowContent {
                @include fontStyle(13px, 18px, 300);

                strong {
                    @include fontStyle(14px, 17px, 300);
                    text-transform: uppercase;
                }
            } 
        }
    }

    .gm-style-iw-chr {
        button {
            display: none !important;
        }

        .gm-style-iw-ch {
            position: relative;
            padding-bottom: 18px;

            &::before {
                position: absolute;
                top: 65%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: "";
                display: block;
                width: 25px;
                height: 25px;
                @include maskIconPath('../svg/spritesheet.svg');
                @include maskIconCoord(17, $initialColSize, $initialRowSize);
                background-color: $black;
            }
        }
    }
}