/*
Filters
*/
#rayon_sticky_block {
    position: fixed;
    top: 0;
    left: 100%;
    bottom: 0;
    font-family: $primaryFont;
    width: 300px;
    height: 100%;
    padding: 34px 25px 30px 25px;
    background-color: $white;
    border-top: 1px solid $black;
    transition: all .5s ease-in-out;
    z-index: 100;

    // For resolutions > 1920
    @media screen and(min-width: 1920px) {
        // initial width + rest of total available space
        width: calc(300px + calc((100% - 1920px) / 2));
    }

    &.show {
        left: calc(100% - 300px);
        transition: all 0.3s ease-in-out;

        // For resolutions > 1920
        @media screen and(min-width: 1920px) {
            // initial position - rest of total available space
            left: calc((100% - 300px) - calc((100% - 1920px) / 2));
        }
    }

    .main_wrapper_bloc {
        position: relative;
        height: 100%;
    }

    .filters_wrapper {
        width: calc(100% + 17px);
        height: 100%;
        max-height: calc(100vh - (#{#{$headerFull} + 60px}));
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 17px;
        box-sizing: content-box;
        scrollbar-color: $greyBorder transparent;
        scrollbar-width: thin;
    }

    .filter_name {
        @include fontStyle(14px, unset, 300);
        text-transform: uppercase;
        margin-bottom: 14px;
    }

    .filters_title {
        @include fontStyle(20px, 26px, 700);
        display: block;
        margin-bottom: 19px;
    }

    .toggleFilter {
        @include fontStyle(14px, 17px, 300);
        text-transform: uppercase;
        margin-bottom: 14px;
    }

    .selected_options {
        display: flex;
        flex-wrap: wrap;
        padding-left: 2px;

        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    .color_picto_count {
        display: none;
    }

    .filter_remove {
        @include fontStyle(13px, 26px, 300);
        text-transform: uppercase;
        text-decoration: underline;
        text-underline-offset: 5px;
        background-color: transparent;
        border: none;
        color: $black;

        &:hover {
            background-color: transparent !important; // override default behavior set in _buttons.scss
            color: $black !important; // override default behavior set in _buttons.scss
        }

        +.loader {
            border: none;
        }
    }

    .filters_cta {
        position: absolute;
        bottom: 7px;
        max-width: 250px;
        width: 100%;

        .nbrFilteredProd {
            padding-left: 5px;
        }

        button,
        .button {
            width: 100%;
            cursor: pointer;
        }

        .form_submit {
            +.form_submit {
                bottom: -15px;
            }
        }
    }

    .close_pop {
        position: absolute;
        top: 10px;
        right: 10px;
        display: block;
        height: 15px;
        width: 15px;
        @include maskIconPath('../svg/spritesheet.svg');
        @include maskIconCoord(7, $initialColSize, $initialRowSize);
        background-color: $black;
        z-index: 3;
        cursor: pointer;
    }
}

.category .wrapper_bandeau {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 22px auto 12px;

    h1 {
        @include fontStyle(40px, 40px, 700);
    }
}

// Trier
#filter_trier {
    .searchPriceFilter {
        margin-bottom: 8px;
    }

    .selected_options {
        gap: 6px;
        column-gap: 20px;
        margin-bottom: 23px;
    }

    .form_itm:nth-of-type(2) .tri_price {
        padding: 6px 10px;
    }

    .tri_price {
        @include fontStyle(11px, 37px, 300);
        padding: 6px;
        text-transform: uppercase;
        border: 1px solid transparent;
        border-radius: 5px;

        &.actif {
            border-color: $grey;
        }
    }

    input[type=radio]+label::before,
    input[type=radio]+label::after {
        display: none;
    }

    [type=radio]:checked+label,
    [type=radio]:not(:checked)+label {
        margin: 0;
        display: inline;
        border: 1px solid transparent;
        border-radius: 5px;
    }

    [type=radio]:checked+label {
        border-color: $grey ;
    }

}

#nbrFilteredProd,
#selectedSortFilter {
    display: none;
}

// Color
#filter_color {
    .selected_options {
        width: 100%;
        padding-left: 4px;

        .form_itm {
            margin: 0 25px 26px 0px;

            label {
                font-size: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background-size: cover;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                position: relative;
                cursor: pointer;

                &:before {
                    content: ' ';
                    display: block;
                    background: transparent;
                    width: 21px;
                    height: 21px;
                    border: solid 1px transparent;
                    border-radius: 50%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin-left: -11.5px;
                    margin-top: -11.5px;
                    transition: all 0.3s ease-in-out;
                }
            }

            [type='checkbox']:not(:checked) {

                &#color_filter_1,
                &#primary_color_filter1 {
                    &+label {
                        border: solid 1px $black;
                    }
                }
            }

            [type='checkbox']:checked,
            [type='checkbox']:not(:checked) {
                &#color_filter_1 {
                    &+label {
                        border: solid 1px $black;
                    }
                }
            }

            [type='checkbox']:checked {

                &#color_filter_1,
                &#primary_color_filter1 {
                    &+label {
                        border: solid 1px $white;
                    }
                }

                &+label {
                    &:before {
                        border: solid 1px $black;
                    }
                }
            }
        }
    }
}

#filter_sizes {
    .selected_options {
        margin-bottom: 23px;
        gap: 10px;
    }

    input[type=checkbox]:checked {
        +label {
            background-color: $black;
            color: $white;
        }
    }

    label {
        @include fontStyle(15px, 37px, 300);
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        padding: 10px;
        background-color: $primaryColor;
    }
}

#filter_categories {
    .selected_options {
        gap: 15px;
        margin-bottom: 23px;
    }

    .form_itm {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input[type=checkbox] {
        &:not(:checked) {
            +label {

                border: 1px solid transparent;
            }
        }

        &:checked {
            +label {
                border: 1px solid #BBB;
            }
        }

        +label {
            @include fontStyle(11px, normal, 300);
            padding: 6px;
            text-transform: uppercase;
            border-radius: 5px;
        }
    }
}

#filter_promo {
    .selected_options {
        margin-bottom: 23px;
        flex-direction: column;
    }

    .form_itm {
        margin-bottom: 10px;
    }

    input[type=checkbox] {
        &:not(:checked) {
            +label {


                &::before,
                &::after {
                    content: "";
                    display: block;
                    width: 14px;
                    height: 14px;
                    border: 1px solid $black;
                    background: $white;
                    margin: 0 0.5rem 0 0;
                    border-radius: 0;
                }
            }
        }

        &:checked {
            +label {
                &::before {
                    content: "";
                    display: block;
                    width: 14px;
                    height: 14px;
                    border: 1px solid $black;
                    background: $white;
                    margin: 0 0.5rem 0 0;
                    border-radius: 0;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    display: block;
                    width: 12px;
                    height: 12px;
                    background: $black;
                    border-radius: 0;
                }
            }
        }

        +label {
            @include fontStyle(11px, normal, 300);
            text-transform: uppercase;
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }
}

#trigger_filtre {
    max-width: 1822px;
    width: 98%;
    margin: 0 auto 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include fontStyle(13px, 26px, 300);
    text-transform: uppercase;
    text-decoration: underline;
    text-underline-offset: 6px;
    cursor: pointer;
}

/*
Pagination
*/
.wrap_flitre_pager {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 21px auto 80px;

    .pagerNav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        &.appear {
            opacity: 1;
        }
    }

    .nbProdDisplayed {
        @include fontStyle(13px, 18px, 300);
        margin-bottom: 10px;
    }

    .loadedGauge {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        width: 265px;
        height: 3px;
        margin: 0 auto 30px;
        border-radius: 2px;
        background: rgba(238, 238, 238, 0.93);

        span {
            display: block;
            background: $black;
            margin: 0;
            border-radius: 2px;
        }
    }

    .loader_scroll {
        position: relative;
        max-width: 185px;
        width: 100%;
        height: 39px;

        &.loading {
            .loader {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                opacity: 1;
                display: block;
                height: 39px;

                &.button.primary {
                    background: $black url(../svg/three_dots_w.svg) no-repeat center;
                    background-size: auto 25%;
                }
            }
        }

        img {
            width: 100%;
            height: auto;
        }

        .button {
            width: 100%;
        }
    }
}

/*
SEO BLOC
*/
.seo_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 800px;
    width: 100%;
    margin: 0 auto 50px;

    &:nth-child(odd) {
        flex-direction: row-reverse;
        margin: 0 auto -8px;

        .title {
            width: 100%;
        }

        .seo_div {
            margin: 0 auto -8px;
        }
    }

    .img_seo {
        width: 50%;
    }

    .txt_seo {
        position: relative;
        top: -10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 43.75%;
    }

    .title {
        position: relative;
        top: 9px;
        @include fontStyle(25px, 26px, 700);
        margin-bottom: 20px;
        width: 70%; // must be handled in BO directly with a <br> tag to manage long title
    }

    .txt {
        @include fontStyle(13px, 18px, 300);
    }

    img {
        width: 100%;
        height: auto;
    }
}

// Special case for search page
#searchComponent #filter_sticky {
    .filters_title {
        margin-bottom: 18px;
    }

    #filter_categories .selected_options {
        margin-bottom: 15px;

        &:last-of-type {
            margin-bottom: 23px;
        }
    }

    #filter_trier .searchPriceFilter {
        margin-bottom: 6px;
    }
} 